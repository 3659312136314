<div class="userprofile">
    
    <div class="userprofile__left">
        <img class="userprofile__photo" src="../../../assets/img/userprofile_temp.jpg" alt="">
    </div>
    <div class="userprofile__right">
        <h3>{{userdetail.user_first_name}} {{userdetail.user_last_name}}</h3>
        <h6>{{userdetail.user_relation_to_chemrobotics}}</h6>
        <div class="userprofile__right__aboutlst">
            <div class="userprofile__right__aboutlst__left">
                <ul>
                    <li>
                        <label>App Role</label>
                        <p>{{userdetail.user_role}}</p>
                    </li>
                    <li>
                        <label>Mobile no</label>
                        <p>{{userdetail.user_mobile_no}}</p>
                    </li>
                    <li>
                        <label>Personal Email</label>
                        <p>{{userdetail.user_personal_email}}</p>
                    </li>
                    <li>
                        <label>Education</label>
                        <p>{{userdetail.user_education}}</p>
                    </li>
                    <li>
                        <label>Gender</label>
                        <p>{{userdetail.user_gender}}</p>
                    </li>
                    <li>
                        <label>Industry</label>
                        <p>{{userdetail.user_Industry}}</p>
                    </li>

                    <li>
                        <label>Company Name</label>
                        <p>{{userdetail.user_company_name}}</p>
                    </li>                   
                    <li>
                        <label>Pin Code</label>
                        <p>{{userdetail.user_pin_code}}</p>
                    </li>
                    
                </ul>
            </div>
            <div class="userprofile__right__aboutlst__right">
                <ul>
                    <li>
                        <label>Departmnet</label>
                        <p>{{userdetail.user_department}}</p>
                    </li>
                    <li>
                        <label>Permanent Address</label>
                        <p>{{userdetail.user_perm_address}}</p>
                    </li>
                    <li>
                        <label>Company Email</label>
                        <p>{{userdetail.user_company_email}}</p>
                    </li>
                    <li>
                        <label>Communication Address</label>
                        <p>{{userdetail.user_communication_address}}</p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    
</div>
