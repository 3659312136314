import { Component, OnInit } from '@angular/core';
import { userprofileparams } from 'src/app/_model/userprofileparams';
import { DatasearchService } from 'src/app/_services/datasearch.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  userid:number;
  userdetail:userprofileparams;
  constructor(private datasearchService: DatasearchService) { }

  ngOnInit(): void {
    this.userid=JSON.parse(
      localStorage.getItem('currentUser')
    ).userid;

    this.getUserDetail();
  }

  getUserDetail(){
      let postdata={
        mode: "GET_TABLE_DYNDATA",
        userid: this.userid,
        table_id: 2, // tanle for users
        filter_condition: ` WHERE   (id=${this.userid})`,
        sortby: "",
        rowscount: 1,
        pagenumber: 1,
        table_action: ""
      };

    this.datasearchService.GetDynTableData(postdata).subscribe(res=>{
      this.userdetail=res[0][0];
      //Get Role Name
      postdata.table_id=1;
      postdata.filter_condition= ` WHERE   (id=${this.userdetail.user_role})`;
      this.datasearchService.GetDynTableData(postdata).subscribe(res=>{
        this.userdetail.user_role=res[0][0].role_name;
      });
    });
  }

}
