import { Component, OnInit } from '@angular/core';
import { Subject,BehaviorSubject } from "rxjs";
import { FormGroup } from '@angular/forms';
import { dyndataobject_model } from 'src/app/_model/dyndataobject_model';
import { ConstantsService } from 'src/app/_services';
import { DatasearchService } from 'src/app/_services/datasearch.service';
import { ToastserviceService } from 'src/app/_services/toastservice.service';

@Component({
  selector: 'app-role-master',
  templateUrl: './role-master.component.html',
  styleUrls: ['./role-master.component.scss'],
})
export class RoleMasterComponent implements OnInit {  

  userid:number=0;
  role_id:number=0;
  current_user_role_id:number=0;
  table_id:number=1;
  refreshTableSubject$: Subject<boolean> = new Subject<boolean>();
  tableSettingsSubject$:BehaviorSubject<any>=new BehaviorSubject<any>({menu_is_mstr_glbsearch:false,menu_is_mstr_downloadcsv:false,menu_is_mstr_downloadexcel:false,menu_is_mstr_downloadpdf:false});
  postdata: dyndataobject_model = {
    mode: 'FORM_SUBMIT',
    userid: this.userid,
    role_id:this.current_user_role_id,
    table_id: this.table_id, //Role master id
    form_action: 'ADD',
    form_data: {},
  };
  pageTitle: string = '';
  currentMenuDtls:any;
  isShowAddEditForm: boolean = false;
  fieldDtls: any[];
  fieldData: any;
  dataEditMode: string = 'ADD'; // ADD,EDIT,VIEW
  allMenuDtls:any[]=[];
  temp_allMenuDtls:any[]=[];
  pendingApprovalCnt: number = 0;
  

  constructor(
    private constantService: ConstantsService,
    private toastserviceService: ToastserviceService,
    private datasearchService: DatasearchService
  ) {}

  

  ngOnInit(): void {
    
    setTimeout(() => {
      
      this.currentMenuDtls = JSON.parse(
        sessionStorage.getItem('currentMenuDtls')
      );
      this.tableSettingsSubject$.next(this.currentMenuDtls);
      this.pageTitle = this.currentMenuDtls.menu_title;
    }, 1000);

    this.userid=JSON.parse(
      localStorage.getItem('currentUser')
    ).userid;
    this.current_user_role_id=JSON.parse(
      localStorage.getItem('currentUser')
    ).role_id;


    this.postdata = {
      mode: 'FORM_SUBMIT',
      userid: this.userid,
      role_id:this.current_user_role_id,
      table_id: this.table_id, //Role master id
      form_action: 'ADD',
      form_data: {},
    };

    this.getTotalPendingApproval();
  }

  onAddNew(form_data) {
    this.showForm('ADD', form_data);
  }
  viewForm(form_data) {
    this.showForm('VIEW', form_data);
    
  }
  editForm(form_data) {
    this.showForm('EDIT', form_data);
  }
  deleteRecord(form_data) {
    this.toastserviceService.showConfirm(
      'Confirm',
      'Are you sure want to delete this record ?',
      (confirmed) => {
        if(confirmed){
          this.dataEditMode = 'DELETE';
          this.saveRecord(form_data);
        }
      }
    );
    
  }

  formSubmit(event: FormGroup) {
    if (event.valid) {
      let isFormModifiedByUser = (event.pristine == false ? true : false) || JSON.stringify(this.allMenuDtls)!=JSON.stringify(this.temp_allMenuDtls) ;
      if (isFormModifiedByUser) {
        //Confirmation
        this.toastserviceService.showConfirm(
          'Confirm',
          'Do you want to save the record?',
           (confirmed) => {
            if (confirmed) {
              //console.log(event.value);
              this.postdata.form_action=this.dataEditMode;
              this.saveRecord(event.value);
            }
          }
        );
      } else {
        this.toastserviceService.showWarning(
          'No Changes Made!',
          'Edit the record and save.'
        );
      }
    } else {
      this.toastserviceService.showError(
        'Invalid Submission!',
        'Please enter required field(s) to save the record.'
      );
    }
  }
  formCancel(event: FormGroup) {
    //Check Form changes happen
    if(this.dataEditMode=="ADD" || this.dataEditMode=="EDIT"){
      let isFormModifiedByUser = event.pristine == false ? true : false || JSON.stringify(this.allMenuDtls)!=JSON.stringify(this.temp_allMenuDtls);
      if (isFormModifiedByUser) {
        this.toastserviceService.showConfirm(
          'Confirm',
          'you have unsaved chnages. Are you sure want to leave?',
          (confirmed) => {
            this.isShowAddEditForm = !confirmed;
          }
        );
      } else {
        this.isShowAddEditForm = false;
      }
    }else{
      this.isShowAddEditForm = false;
    }
    
  }

  /////// PAGE LEVEL HELPER METHODS //////
  showForm(mode, form_data) {
    // debugger;
    this.dataEditMode = mode;
    this.isShowAddEditForm = true;
    this.fieldDtls = form_data.form_fielddata;
    this.fieldData = form_data.row_data;
    // this.role_id= this.fieldData.id ==null ? 0 : this.fieldData.id;
    // if(this.fieldData.hasOwnProperty('need_approval')==true ){
    //   this.role_id=this.fieldData.edit_id ==null || this.fieldData.edit_id==0 ? this.fieldData.id : this.fieldData.edit_id;
    // }
    this.getRoleAccess();
  }

  saveRecord(form_data){
    this.postdata.form_action=this.dataEditMode;
    this.postdata.form_data=form_data;
    this.datasearchService.GetDynTableData(this.postdata).subscribe((res) => {
      //console.log(res);
      // debugger;
      //this.role_id= this.dataEditMode  == "EDIT" ? res[0][0].edit_id : res[0][0].id;
      this.refreshTableSubject$.next(true); //refresh the table
      this.saveRoleAccess(res[0][0].role_name);
      this.getTotalPendingApproval();
      this.toastserviceService.showSuccess("Success!",`Record ${this.dataEditMode=='ADD' ? 'Saved' : ''}${this.dataEditMode=='EDIT' ? 'Updated' : ''}${this.dataEditMode=='DELETE' ? 'Deleted' : ''}.Please approve changes in approval section.`);
      
    },(error)=>{
      this.toastserviceService.showError("Error!","Something went wrong. Please refresh the page and try again!");
    },()=>{
      this.isShowAddEditForm=false;
    });
  }

  approveRecord(row_data) {
    // debugger;
    this.postdata.mode = 'FORM_APPROVE';
    this.postdata.approveid = row_data.id;
    this.fieldData=row_data;
    this.datasearchService.GetDynTableData(this.postdata).subscribe(
      (res) => {
        
        this.saveRoleAccess(res[0][0].role_name);
        //console.log(res);
        this.refreshTableSubject$.next(true); //refresh the table
        this.toastserviceService.showSuccess('Success!', `Record Approved.`);
        this.getTotalPendingApproval();
      },
      (error) => {
        this.toastserviceService.showError(
          'Error!',
          'Something went wrong. Please refresh the page and try again!'
        );
      },
      () => {
        this.isShowAddEditForm = false;
      }
    );
  }

  deleteApproveRecord(row_data) {
    // debugger;
    this.toastserviceService.showConfirm("Confirm","Are sure want to delete the record?",(isconfiremed)=>{
      if(isconfiremed){
        this.postdata.mode = 'FORM_APPROVE_DELETE';
        this.postdata.approveid = row_data.id;
        this.fieldData=row_data;
        this.datasearchService.GetDynTableData(this.postdata).subscribe(
          (res) => {
          
            //console.log(res);
            this.refreshTableSubject$.next(true); //refresh the table
            this.toastserviceService.showSuccess('Success!', `Approval Record Deleted/Rejected.`);
            this.getTotalPendingApproval();
          },
          (error) => {
            this.toastserviceService.showError(
              'Error!',
              'Something went wrong. Please refresh the page and try again!'
            );
          },
          () => {
            this.isShowAddEditForm = false;
          }
        );
      }
    });
    
  }

  getTotalPendingApproval() {
    this.postdata.mode = 'GET_TOTAL_PENDING';
    this.datasearchService.GetDynTableData(this.postdata).subscribe((res) => {
      if (res[0].length == 1) {
        this.pendingApprovalCnt = res[0][0].count;
      } else {
        this.pendingApprovalCnt = 0;
      }

      this.postdata.mode = 'FORM_SUBMIT';
    });
  }

  //Get Role Access
  getRoleAccess(){
    let role_name=this.fieldData.role_name==null ? '': this.fieldData.role_name;
    let needapprovalcheck=0;
    if (this.fieldData.hasOwnProperty('need_approval')) {
      needapprovalcheck = this.fieldData.need_approval;
    }
    let postdata={
      mode:"GET_NEW_ROLE_ACCESS",role_name:role_name,userid:this.userid,access_data:{},form_mode:this.dataEditMode,is_need_approval:needapprovalcheck
     };
   this.datasearchService.manageRoleAccess(postdata).subscribe(res=>{
    this.allMenuDtls=res[0];
    this.temp_allMenuDtls=JSON.parse(JSON.stringify(res[0]));
   }); 
  }


  //Save Role Access
  saveRoleAccess(role_name){
    
    // debugger;
    let needapprovalcheck=0;
    let savemode="ADD_ROLE_ACCESS";
    if (this.fieldData.hasOwnProperty('need_approval')) {
      needapprovalcheck = this.fieldData.need_approval;
      savemode=needapprovalcheck==1 ? "APPROVE_ROLE_ACCESS":savemode;
    }
    let postdata={
      mode:savemode,role_name:role_name,userid:this.userid,access_data:this.allMenuDtls,form_mode:this.dataEditMode,is_need_approval:needapprovalcheck
     };
     this.datasearchService.manageRoleAccess(postdata).subscribe(res=>{
      this.allMenuDtls=res[0];
      this.temp_allMenuDtls=JSON.parse(JSON.stringify(res[0]));
     },(error)=>{
       console.log(error);
     }); 
  }

  
}
