<div class="search">
  <input
    type="text"
    class="search__input"
    placeholder="Search Upload(s)"
    (keyup)="SearchUploads($event)"
  />
  <button class="search__button">
    <svg class="search__icon">
      <use xlink:href="./assets/img/sprite.svg#icon-search"></use>
    </svg>
  </button>
</div>
<div class="container">
  <!-- dynamic tables -->
  <div class="row">
    <app-fileupload
      *ngFor="let table of filtertables"
      class="fileuploadbox"
      [Title]="table.TableName"
      [Templatetable]="table.TemplateName"
      [Table]="table.TableName"
      [Tablename]="table.Tabledetails"
      [StoredProcedure]="table.Stored_Procedure"
      [IsMongoDBTable]="table.is_mongo ?? false"
    ></app-fileupload>
  </div>

  <!-- <div class="row">
        <app-fileupload class="fileuploadbox" [Title]="'basic_product_details'"
            [Templatetable]="'template_basic_product_details'" [Tablename]="'basic_product_details'"></app-fileupload>
        <app-fileupload class="fileuploadbox" [Title]="'OB_PRODUCTS'" [Templatetable]="'TEMPLATE_OB_PRODUCTS'"
            [Tablename]="'OB_PRODUCTS'"></app-fileupload>
        <app-fileupload class="fileuploadbox" [Title]="'OB_PATENTS'" [Templatetable]="'TEMPLATE_OB_PATENTS'"
            [Tablename]="'OB_PATENTS'"></app-fileupload>


        <app-fileupload class="fileuploadbox" [Title]="'OB_EXCLUSIVITY'" [Templatetable]="'TEMPLATE_OB_EXCLUSIVITY'"
            [Tablename]="'OB_EXCLUSIVITY'"></app-fileupload>
        <app-fileupload class="fileuploadbox" [Title]="'PHARM_JARVIS'" [Templatetable]="'TEMPLATE_PHARM_JARVIS'"
            [Tablename]="'PHARM_JARVIS'"></app-fileupload>
        <app-fileupload class="fileuploadbox" [Title]="'CHEM_JARVIS'" [Templatetable]="'TEMPLATE_CHEM_JARVIS'"
            [Tablename]="'CHEM_JARVIS'"></app-fileupload>


        <app-fileupload class="fileuploadbox" [Title]="'AGRO_JARVIS'" [Templatetable]="'TEMPLATE_AGRO_JARVIS'"
            [Tablename]="'AGRO_JARVIS'"></app-fileupload>
        <app-fileupload class="fileuploadbox" [Title]="'PHARM_NON_PAT_REFERENCE'"
            [Templatetable]="'TEMPLATE_PHARM_NON_PAT_REFERENCE'" [Tablename]="'PHARM_NON_PAT_REFERENCE'">
        </app-fileupload>
        <app-fileupload class="fileuploadbox" [Title]="'CHEM_NON_PAT_REFERENCE'"
            [Templatetable]="'TEMPLATE_CHEM_NON_PAT_REFERENCE'" [Tablename]="'CHEM_NON_PAT_REFERENCE'"></app-fileupload>


        <app-fileupload class="fileuploadbox" [Title]="'AGRO_NON_PAT_REFERENCE'"
            [Templatetable]="'TEMPLATE_AGRO_NON_PAT_REFERENCE'" [Tablename]="'AGRO_NON_PAT_REFERENCE'"></app-fileupload>
        <app-fileupload class="fileuploadbox" [Title]="'INDIAN_PESTICIDE_DATABASE'"
            [Templatetable]="'TEMPLATE_INDIAN_PESTICIDE_DATABASE'" [Tablename]="'INDIAN_PESTICIDE_DATABASE'">
        </app-fileupload>
        <app-fileupload class="fileuploadbox" [Title]="'CHEMICAL_DIRECTORY'"
            [Templatetable]="'TEMPLATE_CHEMICAL_DIRECTORY'" [Tablename]="'CHEMICAL_DIRECTORY'"></app-fileupload>

    </div> -->
</div>
