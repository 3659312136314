import { Injectable } from '@angular/core';
import { ConstantsService } from '../_services';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FileuploadinterfaceService {

  ApiUrl:string="";

  constructor(private _constant: ConstantsService,private http: HttpClient) { 

    this.ApiUrl=_constant.ApiUrl;
  }

  // GET Template Table Details
  Get_Template_Table_details()
  {
    return this.http.post(`${this.ApiUrl}/get_tables`,"test");
  }

  // GET Template Table Details MongoDB
  Get_Template_Table_details_mongo()
  {
    return this.http.post(`${this.ApiUrl}/get_tables_mongo`,"test");
  }
}
