<div class="home__main__content__overview">
    <h1>Idea2Reality Admin Panel Overview</h1>
    <p>All of your administrative work will be done in your Admin panel, which you can access by clicking on the side menu in the navigation bar of your documents page.</p>
    <h2>Features</h2>
    <div class="home__main__content__overview__feature">
        <a class="home__main__content__overview__feature__item">
            <h3>Employee/User Managemnet</h3>
            <div class="home__main__content__overview__feature__item__img">
                <svg><use xlink:href="./assets/img/sprite.svg#icon-users"></use></svg>
            </div>
            
            <span>Employee info, Professional, Company, Education</span>
        </a>
        <a class="home__main__content__overview__feature__item">
            <h3>Role Managemnet</h3>
            <div class="home__main__content__overview__feature__item__img">
                <svg><use xlink:href="./assets/img/sprite.svg#icon-user-check"></use></svg>
            </div>
            <span>Employee Role, Page wise edit,view and delete access</span>
        </a>
        <a class="home__main__content__overview__feature__item">
            <h3>Client Managemnet</h3>
            <div class="home__main__content__overview__feature__item__img">
                <svg><use xlink:href="./assets/img/sprite.svg#icon-smile"></use></svg>
            </div>
            <span>Client Info, Company Name, Comapny Logo</span>
        </a>
        <a class="home__main__content__overview__feature__item">
            <h3>Dynamic Table Management</h3>
            <div class="home__main__content__overview__feature__item__img">
                <svg><use xlink:href="./assets/img/sprite.svg#icon-database"></use></svg>
            </div>
            <span>Creation of dynamic table, edit, view ,delete and assign to specific people</span>
        </a>
        <a class="home__main__content__overview__feature__item">
            <h3>Invoice Generation</h3>
            <div class="home__main__content__overview__feature__item__img">
                <svg><use xlink:href="./assets/img/sprite.svg#icon-file-text"></use></svg>
            </div>
            <span>Client Invoice template, download and send to client</span>
        </a>
        <a class="home__main__content__overview__feature__item">
            <h3>Database Column Manage</h3>
            <div class="home__main__content__overview__feature__item__img">
                <svg><use xlink:href="./assets/img/sprite.svg#icon-edit-3"></use></svg>
            </div>
            <span>Configuring column hide/show and column name change</span>
        </a>
        <a class="home__main__content__overview__feature__item">
            <h3>Dynamic Table Data</h3>
            <div class="home__main__content__overview__feature__item__img">
                <svg><use xlink:href="./assets/img/sprite.svg#icon-database"></use></svg>
            </div>
            <span>Accessing dynamic table data</span>
        </a>
        <a class="home__main__content__overview__feature__item">
            <h3>Page Maintanance</h3>
            <div class="home__main__content__overview__feature__item__img">
                <svg><use xlink:href="./assets/img/sprite.svg#icon-eye-off"></use></svg>
            </div>
            <span>Configuring Database Maintanance </span>
        </a>
        <a class="home__main__content__overview__feature__item">
            <h3>User Profile</h3>
            <div class="home__main__content__overview__feature__item__img">
                <svg><use xlink:href="./assets/img/sprite.svg#icon-user"></use></svg>
            </div>
            <span>Manageing profile view</span>
        </a>
    </div>
</div>

