import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConstantsService } from './constants.service';
import { Router } from '@angular/router';
import { CommonService } from './common.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;
    private apiurl:string;
    constructor(private http: HttpClient,private _constant: ConstantsService,private router: Router) {
        this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
        this.apiurl=this._constant.ApiUrl;
    }

    public get currentUserValue() {
        return this.currentUserSubject.value;
    }
    
    login(username, password):Observable<any> {
        return this.http.post<any>(`${this.apiurl}/signin`, { username, password })
            .pipe(map(user => {
                //console.log(user);
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user));
                this.currentUserSubject.next(user);
                this._constant.isLoggedOut.next(false);
                this.startRefreshTokenTimer();
                return user;
            }));
    }

    logout() {
        // debugger;
        // remove user from local storage and set current user to null
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
        this._constant.isLoggedOut.next(true);
        this.stopRefreshTokenTimer();
        
        
    }


    refreshToken() {
        //console.log("token refreshing");
        return this.http.post<any>(`${this.apiurl}/refresh-token`, {})
            .pipe(map((user) => {
                this.currentUserSubject.next(user);
                this.startRefreshTokenTimer();
                return user;
            }));
    }

    // helper methods

    refreshTokenTimeout;

    private startRefreshTokenTimer() {
        // parse json object from base64 encoded jwt token
        const jwtToken = JSON.parse(atob(this.currentUserValue.token.split('.')[1]));

        // set a timeout to refresh the token a minute before it expires
        const expires = new Date(jwtToken.exp * 1000);
        const timeout = expires.getTime() - Date.now() - (60 * 1000);
        //console.log("token timeout",timeout);
        this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }
}