import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { LazyLoadEvent } from 'primeng/api';
import { Table } from 'primeng/table';
import { BehaviorSubject, Subject } from 'rxjs';
import { dyndataobject_model } from 'src/app/_model/dyndataobject_model';
import { AuthenticationService, CommonService, ConstantsService } from 'src/app/_services';
import { DatasearchService } from 'src/app/_services/datasearch.service';
import { ExportDataService } from 'src/app/_services/export-data.service';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { ToastserviceService } from 'src/app/_services/toastservice.service';
import { CryptoService } from 'src/app/_services/crypto.service';

@Component({
  selector: 'app-dyntable-data',
  templateUrl: './dyntable-data.component.html',
  styleUrls: ['./dyntable-data.component.scss'],
})
export class DyntableDataComponent implements OnInit {
  @Input() TableID: number = 2;
  @Input() TableReportTitle: string;
  @Input() tableSettingsSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>({
      menu_is_mstr_glbsearch: true,
      menu_is_mstr_downloadcsv: true,
      menu_is_mstr_downloadexcel: true,
      menu_is_mstr_downloadpdf: true,
      roleacc_is_view: false,
      roleacc_is_edit: false,
      roleacc_is_delete: false,
    });
  @Output() onAddNew = new EventEmitter<any>();
  @Output() onEdit = new EventEmitter<any>();
  @Output() onView = new EventEmitter<any>();
  @Output() onDelete = new EventEmitter<any>();
  @Output() onApprove = new EventEmitter<any>();
  @Input() refreshTableSubject: Subject<boolean> = new Subject<boolean>();
  @Input() approvalcount: number = 0;

  userid: number = 0;
  postdata: dyndataobject_model;
  TableSettings: any;
  showApproveView: boolean = false;
  showData: boolean = false;
  row_id: number = 0;
  isDataAccess: boolean = true;

  constructor(
    private _constant: ConstantsService,
    private exportdataService: ExportDataService,
    private datasearchService: DatasearchService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private toastService: ToastserviceService,
    private constantService: ConstantsService,
    private commonService: CommonService,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.userid = JSON.parse(localStorage.getItem('currentUser')).userid;
    if (this.userid != null || this.userid != 0) {
      let CurrentMenuAccess = JSON.parse(
        sessionStorage.getItem('currentMenuDtls')
      );
      this.tableSettingsSubject.next(CurrentMenuAccess);
    }

    this.tableSettingsSubject.subscribe((x) => {
      this.TableSettings = x;
      this.TableSettings.menu_is_mstr_glbsearch =
        x.menu_is_mstr_glbsearch == 1 || x.menu_is_mstr_glbsearch == true
          ? true
          : false;
      this.TableSettings.menu_is_mstr_downloadcsv =
        x.menu_is_mstr_downloadcsv == 1 || x.menu_is_mstr_downloadcsv == true
          ? true
          : false;
      this.TableSettings.menu_is_mstr_downloadexcel =
        x.menu_is_mstr_downloadexcel == 1 ||
        x.menu_is_mstr_downloadexcel == true
          ? true
          : false;
      this.TableSettings.menu_is_mstr_downloadpdf =
        x.menu_is_mstr_downloadpdf == 1 || x.menu_is_mstr_downloadpdf == true
          ? true
          : false;
      this.TableSettings.roleacc_is_view =
        x.roleacc_is_view == 1 || x.roleacc_is_view == true ? true : false;
      this.TableSettings.roleacc_is_edit =
        x.roleacc_is_edit == 1 || x.roleacc_is_edit == true ? true : false;
      this.TableSettings.roleacc_is_delete =
        x.roleacc_is_delete == 1 || x.roleacc_is_delete == true ? true : false;
    });

    this.route.params.subscribe(async (params) => {
      this.TableID = +params['id'];

      //Get User Data Access there or not
      let acctbl_dlts = await this.datasearchService.manageDynTablemgmt_sync({
        mode: 'CHECK_USER_ACCESS',
        table_id: this.TableID,
        userid: this.userid,
      });
      this.isDataAccess = acctbl_dlts[0].length > 0;
      if (this.isDataAccess == false) {
        return;
      }

      // Get Details From Database Based on table ID
      this.postdata = {
        mode: 'GET_TABLE_DYNDATA',
        userid: this.userid,
        table_id: this.TableID,
        filter_condition: '',
        sortby: '',
        rowscount: 150,
        pagenumber: 1,
        table_action: '',
      };
      // let testenc=this.cryptoService.encrtyptData(this.postdata);
      // console.log(`enc data : ${testenc}`);
      // let testdec=this.cryptoService.decryptData(testenc);
      // console.log(`dec data :`,testdec);
      this.getData(this.postdata);

      this.refreshTableSubject.subscribe((response) => {
        if (response) {
          this.getData(this.postdata);
        }
      });
    });
  }

  //Table Variables
  TableName: string = '';
  rows: number = 150;
  totalRecords: number;
  loading_table: boolean;
  products_current_datasource: any[] = [];
  cols: any[];
  cols_form: any[];
  current_pagenumber: number = 1;
  IsShowFullPageData: boolean = false;
  IsNoRecordsFound: boolean = true;
  IsFilterAplied: boolean = false;
  globalfilterFields: string[] = [];
  currentFilterCondition: string = '';
  globalFilterCondition: string = '';
  currentSortBy: string = '';
  rptDataForExcel = [];
  tablefilter: any = {};
  first = 0;
  @ViewChild('dt') dataTable: Table;
  isLastPage_var: boolean = false;
  isFirstPage_var: boolean = false;
  selectedRecords: any[] = [];
  selectAll: boolean = false;

  next() {
    //this.first = this.first + this.rows + 1;
    this.setCurrentPage(this.current_pagenumber + 1);
  }
  prev() {
    //this.first = this.first + this.rows + 1;
    this.setCurrentPage(this.current_pagenumber - 1);
  }
  isLastPage() {
    this.isLastPage_var = this.products_current_datasource
      ? this.first ===
        (this.totalRecords - this.rows < 0 ? 0 : this.totalRecords - this.rows)
      : true;
  }
  isFirstPage() {
    this.isFirstPage_var = this.products_current_datasource
      ? this.first === 0
      : true;
  }

  setCurrentPage(n: number) {
    let paging = {
      first: (n - 1) * this.dataTable.rows,
      rows: this.dataTable.rows,
    };
    this.dataTable.onPageChange(paging);
  }
  paginate(event) {
    //event.first: Index of first record being displayed
    //event.rows: Number of rows to display in new page
    //event.page: Index of the new page
    //event.pageCount: Total number of pages
    let pageIndex = event.first / event.rows + 1; // Index of the new page if event.page not defined.
  }

  onSelectionChange(event) {
    this.selectedRecords = this.products_current_datasource.filter(
      (x) => x.row_select == true
    );
    if (this.selectedRecords) {
      this.selectAll = this.selectedRecords.length === this.totalRecords;
    }
  }

  onSelectAllChange(event) {
    const checked = event.target.checked;
    this.products_current_datasource.forEach((x) => {
      x.row_select = checked;
    });
    this.selectedRecords=this.products_current_datasource.filter(x=>x.row_select==true);
  }

  // LAZY LOAD EVENT TABLE
  loadLazyData(event: LazyLoadEvent) {
    this.loading_table = true;

    this.current_pagenumber = event.first / event.rows + 1;
    this.tablefilter = event.filters;
    //
    let tblConditions = this._constant.getTablesqlFilterCondition(
      this.tablefilter
    );
    if (this.globalFilterCondition != '' || tblConditions != '') {
      let firstcondition =
        ' WHERE ' +
        this.globalFilterCondition +
        ((tblConditions != '' && this.globalFilterCondition != '') == true
          ? ' AND '
          : ' ');
      this.currentFilterCondition = firstcondition + tblConditions;
    } else {
      this.currentFilterCondition = '';
    }

    //SORT BY CONDITION
    if (event.sortField) {
      this.currentSortBy = ` ORDER BY ${event.sortField} ${
        event.sortOrder == 1 ? ' ASC' : ' DESC'
      }`;
    } else {
      this.currentSortBy = '';
    }
    setTimeout(() => {
      this.postdata.rowscount = event.rows;
      this.postdata.pagenumber = this.current_pagenumber;
      this.postdata.filter_condition = this.currentFilterCondition;
      this.postdata.sortby = this.currentSortBy;

      this.getData(this.postdata);
    }, 1000);
  }

  //On Add New Record
  onAddNewEvent() {
    //let form_data = { row_data: [], form_fielddata: this.cols_form };
    //this.onAddNew.emit(form_data);
    this.formEditMode = 'ADD';
    this.showForm();
  }

  //On View Record
  onViewEvent(row_data) {
    //let form_data = { row_data: row_data, form_fielddata: this.cols_form };
    this.edit_data = row_data;
    this.field_data = row_data;
    this.formEditMode = 'VIEW';
    this.showForm();
    //this.onView.emit(form_data);
  }

  //On Edit Record
  onEditEvent(row_data) {
    //let form_data = { row_data: row_data, form_fielddata: this.cols_form };
    //this.onEdit.emit(form_data);
    this.edit_data = row_data;
    this.field_data = row_data;
    this.formEditMode = 'EDIT';
    this.showForm();
  }

  //On Delete Record
  onDeleteEvent(row_data) {
    //this.onDelete.emit(row_data);
    this.edit_data = row_data;
    this.formEditMode = 'DELETE';
    this.toastService.showConfirm(
      'Confirm',
      'Are sure want to delete the record?',
      (isconfiremed) => {
        if (isconfiremed) {
          this.ondynFormSubmit();
        }
      }
    );
  }

  //On Approve Record
  onApproveEvent(row_data) {
    this.onApprove.emit(row_data);
  }

  onApproveViewTblEvent(apview) {
    this.showApproveView = apview;
    this.postdata.table_action =
      this.showApproveView == true ? 'FOR APPROVAL' : '';
    this.first = 0;
    this.products_current_datasource = [];
    this.totalRecords = 0;
    this.getData(this.postdata);
  }

  onGlobalSearch(val) {
    this.globalFilterCondition = '';
    let searchvalue = val.trim();
    let gConditions = [];
    this.cols.forEach((item) => {
      let makcondition = `${item.field_db_col_name} LIKE ''%${searchvalue}%''`;
      gConditions.push(makcondition);
    });
    this.globalFilterCondition = gConditions.join(' OR ');
    let tblConditions = this._constant.getTablesqlFilterCondition(
      this.tablefilter
    );
    if (this.globalFilterCondition != '' || tblConditions != '') {
      let firstcondition =
        ' WHERE ' +
        this.globalFilterCondition +
        ((tblConditions != '' && this.globalFilterCondition != '') == true
          ? ' AND '
          : ' ');
      this.currentFilterCondition = firstcondition + tblConditions;
    } else {
      this.currentFilterCondition = '';
    }

    this.postdata.pagenumber = 1;
    this.postdata.filter_condition = this.currentFilterCondition;

    this.getData(this.postdata);
  }

  //Export Data Excel/Csv
  exportExcel(extention) {
    // if (this.currentFilterCondition == '') {
    //   return;
    // }

    this.postdata.rowscount = this.totalRecords;
    this.datasearchService.manageDynTablemgmt(this.postdata).subscribe(
      (response) => {
        //console.log(response);

        let DownloadData = response[0];
        this.rptDataForExcel = [];
        DownloadData.forEach((row: any) => {
          this.rptDataForExcel.push(Object.values(row));
        });
        let header_temp = [];
        //Add id field as ref no
        header_temp.push('Ref.No');

        this.cols.map((col) => {
          header_temp.push(col.field_ui_display_name);
        });
        let reportData = {
          title: `${this.TableReportTitle} Data Report`,
          data: this.rptDataForExcel,
          headers: header_temp,
          sheetname: 'data',
          file_extention: extention,
        };

        this.exportdataService.exportExcel(reportData);
      },
      (error) => {
        console.log(error);
      },
      () => {
        this.loading_table = false;
      }
    );
  }

  //Export Pdf
  exportPdf() {
    let filterconditions_data = this.currentFilterCondition;
    // if (filterconditions_data == '') {
    //   return;
    // }

    this.postdata.rowscount = this.totalRecords;
    //this.postdata.rowscount=10;

    this.datasearchService.manageDynTablemgmt(this.postdata).subscribe(
      (response) => {
        //console.log(response);
        let DownloadData = response[0];
        this.rptDataForExcel = [];
        DownloadData.forEach((row: any) => {
          this.rptDataForExcel.push(Object.values(row));
        });

        let columns = this.cols.map((col) => ({
          title: col.field_ui_display_name,
          dataKey: col.field_db_col_name,
        }));
        columns.splice(0, 0, { title: 'Ref.No', dataKey: 'id' });
        let reportData = {
          title: `${this.TableReportTitle} Data Report`,
          data: this.rptDataForExcel,
          headers: columns,
          sheetname: 'data',
        };

        this.exportdataService.exportPdf(reportData);
      },
      (error) => {
        console.log(error);
      },
      () => {
        this.loading_table = false;
      }
    );
  }

  //Attachment
  isShowAttachmentPopup: boolean = false;
  showAttachmentPopup() {
    this.documentsDtls = [
      {
        doc_name: '',
        doc_path: '',
        doc_path_temp: '',
        splitfiles: [],
      },
    ];
    if (this.selectedRecords.length > 0) {
      this.isShowAttachmentPopup = true;
    } else {
      this.toastService.showWarning(
        'Select Record(s) to Attach File(s)',
        'Please select table checkbox to select the record to attach the file(s)'
      );
    }
  }

  saveAttachements() {
    
    let attachment_files = this.documentsDtls[0].doc_path;
    let row_idarray=this.products_current_datasource
    .filter((x) => x.row_select == true)
    .map((x) => {
      return x.ID;
    });
    let row_ids = 
    row_idarray.join(',');
    let postdata = {
      mode: 'UPDATE_TABLE_ATTACHMENTS',
      userid: this.userid,
      table_id: this.TableID,
      attachment_files: attachment_files,
      row_ids: row_ids,
    };

    this.datasearchService.manageDynTablemgmt(postdata).subscribe(
      (res) => {
        this.UploadMultiDocs(this.TableID,row_idarray);
      },
      (error) => {
        console.log(error);
        this.toastService.showError(
          'Error!',
          'Something went wrong while getting table details. Please refresh the page and try again!'
        );
      },
      () => {}
    );
  }

  UploadMultiDocs(tableid, row_ids) {
    // debugger;
    //loop each rowid for upload file
    row_ids.forEach(  async (rowid) => {
      // check any form upload exists
      let IsExistsFiles = this.documentsDtls.find(
        (x) => x.hasOwnProperty('fileobject') == true
      );
      if (IsExistsFiles) {
        this.documentsDtls.forEach( async (item) => {
          if (item.doc_path != '' && item.doc_path != null) {
            let alreadyuploadedcnt = 0;
            if (item.doc_path_temp.trim() != '' && item.doc_path_temp != null) {
              alreadyuploadedcnt = item.doc_path_temp.split(',').length;
            }
            let formdata = new FormData();
            //If file object exists
            if (item.hasOwnProperty('fileobject') == true) {
              if (
                item.fileobject != null &&
                item.fileobject != undefined &&
                item.fileobject.length > 0
              ) {
                item.fileobject.forEach((file, i) => {
                  let ext = file.name.split('.')[1].trim();
                  formdata.append(
                    'myfile[]',
                    file,
                    //`${item.doc_name}_${alreadyuploadedcnt + i + 1}.${ext}`
                    file.name
                  );
                });
                let newpath = './dyntblfiles/' + tableid + '/' + rowid;
                console.log(newpath);
                formdata.append('topath', newpath);
                formdata.append('remove_oldfiles_directory', "YES");
                formdata.append('rowid', rowid);
                formdata.append('tableid', tableid);

                await this.datasearchService.uploadFiles_sync(formdata);
              }
            }
          }
        });
      }
    });

    this.isShowAttachmentPopup=false;

    this.getData(this.postdata);

    this.toastService.showSuccess(
      'Attachement Uploaded Sccessfully!',
      ` Selected Records : ${row_ids.length} , File(s) Count : ${this.documentsDtls[0].fileobject.length}`
    );
  }

  //#region  FORM
  dynForm: FormGroup;
  formEditMode: string = 'ADD'; //ADD,EDIT,DELETE,VIEW
  field_details: any[];
  field_data: any;
  edit_data: any;

  createForm() {
    this.dynForm = null;
    // CREATE DYNAMIC FORM
    let fb_builder = {};
    this.field_details.forEach((item) => {
      let temp_validator = [];
      if (item.field_ui_mandatory == true) {
        temp_validator.push(Validators.required);
      }
      if (item.field_ui_regex != '' && item.field_ui_regex != null) {
        temp_validator.push(Validators.pattern(`${item.field_ui_regex}`));
      }
      if (item.field_db_size != '' && item.field_db_size != null) {
        temp_validator.push(Validators.maxLength(item.field_db_size));
      }

      fb_builder[item.field_db_col_name] = [
        //Assign Actual/Default value
        this.formEditMode == 'ADD'
          ? item.field_ui_defalutvalue == ''
            ? null
            : item.field_ui_defalutvalue
          : this.field_data[item.field_db_col_name],
        //Assigning Validation
        Validators.compose(temp_validator),
      ];
    });
    this.dynForm = this.formBuilder.group(fb_builder);

    // //Disable the form in View mode
    // if(this.formEditMode=="VIEW"){
    //   this.dynForm.disable();
    // }
    // else{
    //   this.dynForm.enable();
    // }
  }

  showForm() {
    this.createForm();
    this.showData = true;
    this.isShowAddEditForm = true;
    this.documentsDtls = [
      {
        doc_name: '',
        doc_path: '',
        doc_path_temp: '',
        splitfiles: [],
      },
    ];

    if (this.formEditMode != 'ADD') {
      let filenames = this.edit_data.attachment_files;
      if (filenames != null && filenames != '') {
        this.documentsDtls[0].doc_path_temp = filenames;
        this.documentsDtls[0].doc_path = filenames;
        this.documentsDtls[0].splitfiles = filenames.split(',');
      }
    }
  }
  ondynFormSubmit() {
    let editvalue = this.formEditMode == 'DELETE' ? {} : this.dynForm?.value;
    if (this.edit_data) {
      editvalue.id = this.edit_data.ID;
    }

    console.log(editvalue);

    let modesql = '';
    let delete_data_id = '';
    let message = '';
    switch (this.formEditMode) {
      case 'ADD':
        modesql = 'UPLOAD_TABLE_DATA';
        //message="Data Added Successfully.";
        break;
      case 'EDIT':
        modesql = 'UPDATE_TABLE_DATA';
        //message="Data Updated Successfully.";
        break;
      case 'DELETE':
        modesql = 'DELETE_TABLE_DATA';
        delete_data_id = editvalue.id;
        //message="Data Deleted Successfully.";
        break;
      default:
        break;
    }

    //Add Attachment filenames as comma separated string
    editvalue.attachment_files = this.documentsDtls[0].doc_path;

    let Templatedata = [];
    Templatedata.push(editvalue);
    const regex_formesc = /\\/gm;
    let formescapestring = JSON.stringify(Templatedata);
    formescapestring = formescapestring.replace(regex_formesc, '\\\\');
    let uploaddata = JSON.parse(formescapestring);
    let postdata = {
      mode: modesql,
      userid: this.userid,
      table_id: this.TableID,
      table_data: uploaddata,
      emptytable: 0,
      data_id: delete_data_id,
    };

    this.datasearchService.manageDynTablemgmt(postdata).subscribe(
      (res) => {
        this.showData = false;

        //get current row id for Attachment Upload
        let currentrow_id = 0;
        if (this.formEditMode == 'ADD') {
          currentrow_id = res[0][0].ID;
        } else {
          currentrow_id = editvalue.id;
        }
        this.UploadDocs(this.TableID, currentrow_id);
        this.getData(this.postdata);
        // this.toastService.showSuccess(
        //   'Success',
        //   message
        // );
      },
      (error) => {
        console.log(error);
        this.toastService.showError(
          'Error!',
          'Something went wrong while getting table details. Please refresh the page and try again!'
        );
      },
      () => {}
    );
  }
  //#endregion

  //#region Attachment Option
  apidocPath: string = this.constantService.ApiUrl;
  isShowAddEditForm: boolean = false;
  documentsDtls: any[] = [
    {
      doc_name: '',
      doc_path: '',
      doc_path_temp: '',
      splitfiles: [],
    },
  ];

  fileUploader(event, item) {
    let files = event.files;
    let alreadyUploadCnt = 0;
    if (item.doc_path_temp.trim() != '' && item.doc_path_temp != null) {
      alreadyUploadCnt = item.doc_path_temp.split(',').length;
    }

    //need to set files object
    item.fileobject = files;
    let filescommseprated = item.fileobject
      .map(
        (x, index) =>
          // `${item.doc_name}_${index + 1 + alreadyUploadCnt}.${
          //   x.name.split('.')[1]
          // }`
          x.name
      )
      .join(',');
    if (item.doc_path_temp != '') {
      item.doc_path = item.doc_path_temp + ',' + filescommseprated;
    } else {
      item.doc_path = filescommseprated;
    }
  }

  UploadDocs(tableid, rowid) {
    // check any form upload exists
    let IsExistsFiles = this.documentsDtls.find(
      (x) => x.hasOwnProperty('fileobject') == true
    );
    if (IsExistsFiles) {
      this.documentsDtls.forEach((item) => {
        if (item.doc_path != '' && item.doc_path != null) {
          let alreadyuploadedcnt = 0;
          if (item.doc_path_temp.trim() != '' && item.doc_path_temp != null) {
            alreadyuploadedcnt = item.doc_path_temp.split(',').length;
          }
          let formdata = new FormData();
          //If file object exists
          if (item.hasOwnProperty('fileobject') == true) {
            if (
              item.fileobject != null &&
              item.fileobject != undefined &&
              item.fileobject.length > 0
            ) {
              item.fileobject.forEach((file, i) => {
                let ext = file.name.split('.')[1].trim();
                formdata.append(
                  'myfile[]',
                  file,
                  //`${item.doc_name}_${alreadyuploadedcnt + i + 1}.${ext}`
                  file.name
                );
              });
              let newpath = './dyntblfiles/' + tableid + '/' + rowid;
              console.log(newpath);
              formdata.append('topath', newpath);
              formdata.append('rowid', rowid);
              formdata.append('tableid', tableid);

              this.datasearchService.uploadFiles(formdata).subscribe(
                (res) => {
                  console.log(res);
                  this.toastService.showSuccess(
                    'Sccess!',
                    `Record ${this.formEditMode == 'ADD' ? 'Saved' : ''}${
                      this.formEditMode == 'EDIT' ? 'Updated' : ''
                    }${this.formEditMode == 'DELETE' ? 'Deleted' : ''}`
                  );
                },
                (error) => {
                  this.toastService.showError(
                    'Error!',
                    'Something went wrong. Please refresh the page and try again!'
                  );
                },
                () => {
                  this.isShowAddEditForm = false;
                }
              );
            }
          }
        }
      });
    } else {
      this.toastService.showSuccess(
        'Sccess!',
        `Record ${
          this.formEditMode == 'ADD' ? 'Data Added Successfully.' : ''
        }${this.formEditMode == 'EDIT' ? 'Data Updated Successfully.' : ''}${
          this.formEditMode == 'DELETE' ? 'Data Deleted Successfully.' : ''
        }`
      );
      this.isShowAddEditForm = false;
    }
  }

  removeEmpDocs(doc_name, filename) {
    this.toastService.showConfirm(
      'Confirm',
      'Are you sure want to remove this file?',
      (confirmed) => {
        if (confirmed) {
          let indexdoc = this.documentsDtls.findIndex(
            (x) => x.doc_name == doc_name
          );
          if (indexdoc > -1) {
            let currentfiles = this.documentsDtls[indexdoc].splitfiles.slice(); //copy by value
            currentfiles.splice(currentfiles.indexOf(filename), 1);
            this.documentsDtls[indexdoc].splitfiles = currentfiles;
            this.documentsDtls[indexdoc].doc_path = currentfiles.join(',');
            //mark for as dirty
            this.dynForm.markAsDirty();
          }
        }
      }
    );
  }

  //#endregion

  ////////// PAGE LEVEL METHODS /////
  getData(postdata: dyndataobject_model) {
    this.datasearchService
      .manageDynTablemgmt(this.postdata)
      .subscribe((res) => {
        console.log(res);
        let dyndata: any = res;
        //Table Raw data
        this.products_current_datasource = dyndata[0];
        //Column Details
        this.cols = dyndata[1];
        this.field_details = dyndata[1];

        this.cols.forEach((x) => {
          if (
            x.field_json_dropdownvalues != '' &&
            x.field_json_dropdownvalues != null
          ) {
            x.field_sql_data = JSON.parse(x.field_json_dropdownvalues);
          }
        });
        //this.cols_form = dyndata[2];
        this.totalRecords = dyndata[2][0].countval;
        this.TableReportTitle = dyndata[2][0].table_display_name;
        this.globalfilterFields = this.cols.map((item) => item.field);
        if (this.totalRecords == 0) {
          this.IsNoRecordsFound = true;
          //this.toastr.info('No record(s) found for current filter criteria!');
        } else {
          this.IsNoRecordsFound = false;
        }
        this.isFirstPage();
        this.isLastPage();
        this.loading_table = false;
      });
  }

  findNamefrmID(rowData, field_db_col_name) {
    let data = this.cols.find(
      (x) => x.field_db_col_name == field_db_col_name
    ).field_sql_data;
    let finddata = data.find((x) => x.id == rowData[field_db_col_name]);
    if (finddata) {
      return data.find((x) => x.id == rowData[field_db_col_name]).name;
    }
    return rowData[field_db_col_name];
  }

  navigateToHome() {
    //this.router.navigateByUrl("/");
    window.location.href = '/';
  }
}
