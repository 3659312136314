<div class="dbcoltable">
    <h1 class="master__pageheading">{{ this.pageTitle }}</h1>
    <div class="dbcoltable__box">
      <div class="dbcoltable__box__header">
        <div class="">
          <h3>Platform urls</h3>          
        </div>        
      </div>
      <p-table *ngIf="platforms && platforms.length" [value]="platforms" [columns]="cols" [reorderableColumns]="false" responsiveLayout="scroll" scrollHeight="500px">
        <ng-template pTemplate="header" let-columns>
            <tr>
                <ng-container *ngFor="let col of columns">
                    <th pReorderableColumn *ngIf="col.field === 'Platform'">                      
                        {{col.header}}                      
                    </th>              
                    <th pReorderableColumn *ngIf="col.field === 'Url'" style="min-width:60%; word-wrap: break-word;">                                            
                        {{col.header}}                      
                    </th>              
                </ng-container>                
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
            <tr>
                <td *ngFor="let col of columns">
                    <p *ngIf="col.field === 'Platform'">{{rowData[col.field]}}</p>                 
                    <a
                      *ngIf="col.field === 'Url'"
                      [href]="rowData[col.field]"
                      target="_blank"
                      >{{rowData[col.field]}}</a
                    >
                </td>
            </tr>
        </ng-template>
      </p-table>
    </div>  
  </div>