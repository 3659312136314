<div class="popup {{ ShowUploadResult==true ? 'showpopup':'' }}" id="popup ">
    <div class="popup__content">
        <div class="popup__right">
            <a herf="#test" class="popup__close" (click)="ShowUploadResult=false">&times;</a>
            <h2 class="heading-secondary u-margin-bottom-small">
                Status Report - {{Title}}
            </h2>

            <div *ngFor="let result of UploadedStatusData;let i = index">

                <div class="fileupload__statusbar u-margin-top-small">
                    <div class="fileupload__statustext">
                        <span class="fileupload__statustext--head">
                            <svg class="fileupload__icon">
                                <use xlink:href="./assets/img/sprite.svg#icon-file"></use>
                            </svg>

                            <span class="fileupload__statustext--file">{{result.filename}}</span>
                        </span>
                    </div>

                    <!--Upload Status - Mismatch Column-->
                    <div class="fileupload__statustext u-margin-top-vsmall u-margin-left-medium_1" *ngIf="result.MismatchColums.length!=0">
                        <span class="fileupload__statustext--head">
                            <svg class="fileupload__icon fileupload__icon--error">
                                <use xlink:href="./assets/img/sprite.svg#icon-alert-circle"></use>
                            </svg>

                            <span class="fileupload__statustext--head">Plese make sure valid file/Columns name(s) are
                                correct</span>
                        </span>
                    </div>
                    <!--Upload Status - Error-->
                    <div class="fileupload__statustext u-margin-top-vsmall u-margin-left-medium_1" *ngIf="result.ErroRecords!=0 && result.MismatchColums.length==0">
                        <span class="fileupload__statustext--head">
                            <svg class="fileupload__icon fileupload__icon--error">
                                <use xlink:href="./assets/img/sprite.svg#icon-x-circle"></use>
                            </svg>

                            <span class="fileupload__statustext--head">{{result.StatusMessage}}</span>
                        </span>
                    </div>
                    <!--Upload Status - Success-->
                    <div class="fileupload__statustext u-margin-top-vsmall u-margin-left-medium_1" *ngIf="result.ErroRecords==0 && result.MismatchColums.length==0">
                        <svg class="fileupload__icon fileupload__icon--success">
                            <use xlink:href="./assets/img/sprite.svg#icon-check-circle"></use>
                        </svg>
                        <span class="fileupload__statustext--head">{{result.StatusMessage}}</span>
                        <span class="fileupload__statustext--val  u-color-primarydark">{{result.TotalRecords}}</span>
                    </div>
                </div>

                <ngx-datatable class="material" [rows]="result.MismatchColums" [columns]="columns" [summaryRow]="true"
                    *ngIf="result.MismatchColums.length>0" [summaryPosition]="'top'">

                    <ngx-datatable-column prop="ActualColumnName" name="Pre-defined column(s)"
                        [summaryTemplate]="Template_ActualColumnName">
                    </ngx-datatable-column>
                    <ngx-datatable-column prop="InputColumnName" name="Input column(s)"
                        [summaryTemplate]="Template_InputColumnName"></ngx-datatable-column>

                </ngx-datatable>

                <ng-template #Template_ActualColumnName>
                    <div class="super-custom-style">
                        <b>Pre-defined Column Count :</b> {{result.TemplatefieldsCount}}

                    </div>
                </ng-template>
                <ng-template #Template_InputColumnName>
                    <div class="super-custom-style">
                        <b>Input Column Count :</b> {{ result.FileFieldsCount }}
                    </div>
                </ng-template>
                <ng-template #Template_snotemp>
                    <div class="super-custom-style">
                    </div>
                </ng-template>

            </div>
        </div>
    </div>
</div>

<div class="fileupload">
    <div class="fileupload__headerbox">
        <h2 class="fileupload__heading">
            <svg class="fileupload__icon">
                <use xlink:href="./assets/img/sprite.svg#icon-database"></use>
            </svg>
            {{Title}} <span *ngIf="IsMongoDBTable"> (MongoDB)</span>
        </h2>
        
        <a  class="fileupload__showstatus" (click)="ShowUploadResult=!ShowUploadResult" *ngIf="UploadedStatusData.length>0">
            <svg class="fileupload__icon">
                <use xlink:href="./assets/img/sprite.svg#icon-file-text"></use>
            </svg>
            Check Status
        </a>
        <a  class="fileupload__showstatus" title="Download Template" (click)="Download_Template()">
            <svg class="fileupload__icon">
                <use xlink:href="./assets/img/sprite.svg#icon-download"></use>
            </svg>
        </a>

    </div>
    <!-- <div class="dlt-sec" *ngIf="Title === 'AGROPHARM_XMIM_MONGO' || Title === 'CHEMI_TRACKER_MONGO' || Title === 'GLOBAL_PATENT_WATCH' || Title === 'QUIK_PATENT_MONGO'"> -->
    <div class="dlt-sec">
        <p-confirmDialog class="del-dialog" [style]="{width: '30vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text"></p-confirmDialog>
        <p-button (click)="confirm()" styleClass="p-button-rounded p-button-danger p-button-sm" label="Clear Data"></p-button>
        <p-toast></p-toast>
    </div>
    
    <ngx-file-drop dropZoneLabel="Drop files here" dropZoneClassName="fileupload__dropzone" multiple="false"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        contentClassName="fileupload__content" (onFileDrop)="dropped($event)" (onFileOver)="fileOver($event)"
        (onFileLeave)="fileLeave($event)">
        <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
            <span class="fileupload__msg">Drag and Drop the file(s) to upload</span>
            <span class="fileupload__msg">Or</span>
            <button type="button" class="fileupload__button btn btn--green" (click)="openFileSelector()">Click Here to
                Upload</button>
        </ng-template>
    </ngx-file-drop>
    <div class="fileupload__detailbox">
        <span class="fileupload__filename">
            <svg class="fileupload__icon">
                <use xlink:href="./assets/img/sprite.svg#icon-file"></use>
            </svg>
            {{Filename}}
        </span>
        <span [ngClass]="{'u-show-flex':IsUploading==true,'u-hide':IsUploading==false }" class="fileupload__loading">
            <svg class="fileupload__icon fileupload__icon--loading">
                <use xlink:href="./assets/img/sprite.svg#icon-loader"></use>
            </svg>
            uploading {{UploadedFiles}}/{{Totalfiles}}
        </span>
    </div>
</div>