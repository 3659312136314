import { Injectable } from '@angular/core';
import { ConstantsService } from '../_services';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FileuploadService {

  ApiUrl:string="";
  betaApiUrl:string="";

  constructor(private _constant: ConstantsService,private http: HttpClient) { 

    this.ApiUrl=_constant.ApiUrl;
    this.betaApiUrl=_constant.betaApiUrl;
  }

  // GET Template details for basic_product_details
  Get_Template_basic_product_details()
  {
    return this.http.get(`${this.ApiUrl}/get_template_basic_product_details`);
  }

  // Upload to basic_product_details
  upload_to_basic_product_details(postdata)
  {
    let promise =new Promise((resolve,reject)=>{
      this.http.post(`${this.ApiUrl}/upload_to_basic_product_details`,postdata).toPromise().then(
        res=>{
          //Return Result
          resolve(res);
        },
        msg=>{ //Error
          reject(msg);
        }
      );
    });

    return promise ;
  }


  // Get Template Fields
  async GetTemplateFields(Tablename)
  {
    let promise =new Promise<any>((resolve,reject)=>{
      this.http.post(`${this.ApiUrl}/get_template`,Tablename).toPromise().then(
        res=>{
          //Return Result
          resolve(res);
        },
        msg=>{ //Error
          reject(msg);
        }
      );
    });
    return await promise;
  }

  // Get Template Fields
  async GetTemplateFields_Mongo(Tablename)
  {
    let promise =new Promise<any>((resolve,reject)=>{
      this.http.post(`${this.betaApiUrl}/get_template_mongo`,Tablename).toPromise().then(
        res=>{
          //Return Result
          resolve(res);
        },
        msg=>{ //Error
          reject(msg);
        }
      );
    });
    return await promise;
  }

  
  // Empty Table Data
  async EmptyTableData(Tablename)
  {
    let promise =new Promise<any>((resolve,reject)=>{
      this.http.post(`${this.ApiUrl}/table_empty`,{tablename: Tablename}).toPromise().then(
        res=>{
          //Return Result
          resolve(res);
        },
        msg=>{ //Error
          reject(msg);
        }
      );
    });
    return await promise;
  }

  // Clear Table Data
  async ClearTableData(Tablename){
    let promise =new Promise<any>((resolve,reject)=>{
      this.http.post(`${this.betaApiUrl}/empty_table_data`,{tablename: Tablename}).toPromise().then(
        res=>{
          //Return Result
          resolve(res);
        },
        msg=>{ //Error
          reject(msg);
        }
      );
    });
    return await promise;
  }

  // Empty Mongo Table Data
  async EmptyMongoTableData(Tablename)
  {
    let promise =new Promise<any>((resolve,reject)=>{
      this.http.post(`${this.betaApiUrl}/table_empty_mongo`,{tablename: Tablename}).toPromise().then(
        res=>{
          //Return Result
          resolve(res);
        },
        msg=>{ //Error
          reject(msg);
        }
      );
    });
    return await promise;
  }

  // Run Fileupload Stored Procedure
  async RunFileUploadProcedure(postdata)
  {
    let promise =new Promise<any>((resolve,reject)=>{
      this.http.post(`${this.ApiUrl}/run_fileupload_procedure`,postdata).toPromise().then(
        res=>{
          //Return Result
          resolve(res);
        },
        msg=>{ //Error
          reject(msg);
        }
      );
    });
    return await promise;
  }

  // Upload to Data
  async UploadData(postdata)
  {
    let resultdata:any;
    let promise =new Promise<any>((resolve,reject)=>{
      this.http.post(`${this.ApiUrl}/fileupload`,postdata,{params:{ishideloader:true}}).toPromise().then(
        res=>{
          //Return Result
          resultdata=res;
          resolve(res);
        },
        msg=>{ //Error
          reject(msg);
        }
      );
    });

    return await promise ;
  }

  // Upload to Data Mongo
  async UploadData_Mongo(postdata)
  {
    let resultdata:any;
    let promise =new Promise<any>((resolve,reject)=>{
      this.http.post(`${this.betaApiUrl}/fileupload_mongo`,postdata,{params:{ishideloader:true}}).toPromise().then(
        res=>{
          //Return Result
          resultdata=res;
          resolve(res);
        },
        msg=>{ //Error
          reject(msg);
        }
      );
    });

    return await promise ;
  }

}
