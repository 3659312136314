<div class="master">
  <h1 class="master__pageheading">{{ this.pageTitle }}</h1>
  <div class="master__content" style="overflow-y: hidden;">
    <div class="tabs tabs_2">
      <input type="radio" id="tab1" name="tab-control" checked />
      <input type="radio" id="tab2" name="tab-control" />
      <ul>
        <li title="Table Configuration">
          <label for="tab1" role="button">
            <svg>
              <use xlink:href="./assets/img/sprite.svg#icon-settings"></use>
            </svg>
            <br /><span>Table Config</span></label
          >
        </li>
        <li title="Table Data Upoad">
          <label for="tab2" role="button">
            <svg>
              <use xlink:href="./assets/img/sprite.svg#icon-database"></use>
            </svg>
            <br /><span>Table Data Upoad</span></label
          >
        </li>
      </ul>

      <div class="slider">
        <div class="indicator"></div>
      </div>
      <div class="content">
        <!-- Table Config -->
        <section>
          <h2>Table Config</h2>

          <div class="dyntblmgmt__config">
            <!-- Tables List -->
            <div class="dyntblmgmt__config__tbllist">
              <div class="dyntblmgmt__config__tbllist__searchbox">
                <input
                  type="text"
                  class="form__input border-light"
                  autocomplete="on"
                  placeholder="Search Table"
                  [(ngModel)]="tblKeyword"
                />
                <button (click)="onSearchtable()">
                  <svg class="btn__icon">
                    <use xlink:href="./assets/img/sprite.svg#icon-search"></use>
                  </svg>
                </button>
              </div>

              <virtual-scroller
                [items]="tblDatas"
                (vsEnd)="onVsEnd($event, tblDatas.length)"
              >
                <p-table
                  #tbl_search
                  [value]="tblDatas"
                  [scrollable]="false"
                  scrollHeight="100px"
                  [rows]="SearhBox_RowCount"
                  selectionMode="single"
                  dataKey="value"
                  class="dyntblmgmt__config__tbllist__ptable"
                  (onRowSelect)="onRowSelect($event)"
                >
                  <ng-template pTemplate="body" let-tbldata>
                    <tr style="height: 5px">
                      <td>
                        <a class="dyntblmgmt__config__tbllist__ptable__item" [title]="tbldata.table_desc">
                          <span> {{ tbldata.table_display_name }} <label for="label">{{ tbldata.table_label }}</label></span>
                          <!-- view -->
                          <a [routerLink]="['/table_data',tbldata.table_id]" target="_blank" *ngIf="this.currentMenuDtls?.roleacc_is_view=='1'"
                            class="btn--link u-margin-left-vvsmall"
                            title="View data"
                          >
                            <svg>
                              <use
                                xlink:href="./assets/img/sprite.svg#icon-eye"
                              ></use>
                            </svg>
                          </a>
                          <!-- edit -->
                          <a
                            class="btn--link u-margin-left-vvsmall"
                            title="Edit"
                            (click)="editTable(tbldata)"
                            *ngIf="this.currentMenuDtls?.roleacc_is_edit=='1'"
                          >
                            <svg>
                              <use
                                xlink:href="./assets/img/sprite.svg#icon-edit"
                              ></use>
                            </svg>
                          </a>
                          <!-- delete -->
                          <a
                            class="btn--link u-margin-left-vvsmall"
                            title="Delete"
                            (click)="deleteTable(tbldata)"
                            *ngIf="this.currentMenuDtls?.roleacc_is_delete=='1'"
                          >
                            <svg>
                              <use
                                xlink:href="./assets/img/sprite.svg#icon-trash-2"
                              ></use>
                            </svg>
                          </a>
                        </a>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </virtual-scroller>
            </div>
            <!-- Table Add -->
            <div class="dyntblmgmt__config__tbladd">
              <!-- Form -->
              <form
                class="dyntblmgmt__config__tbladd__form"
                [formGroup]="tblForm"
                (submit)="submit_Form_tbl()"
              >
                <h3 class="master__form__subheading">
                  <svg class="btn__icon">
                    <use
                      xlink:href="./assets/img/sprite.svg#icon-file-plus"
                    ></use>
                  </svg>
                  &nbsp;Add a new table
                </h3>
                <div class="form__group">
                  <label
                    for="tabledisplayname"
                    class="form__label form__label--master"
                    >Table Name<span class="form__group__mandatory"
                      >&lowast;</span
                    ></label
                  >
                  <input
                    type="text"
                    id="tabledisplayname"
                    class="form__input--master"
                    autocomplete="off"
                    formControlName="table_display_name"
                  />
                  <div
                    class="alert alert-danger"
                    *ngIf="
                      tblForm.controls.table_display_name.invalid &&
                      (tblForm.controls.table_display_name.dirty ||
                        tblForm.controls.table_display_name.touched)
                    "
                  >
                    <div
                      *ngIf="
                        tblForm.controls.table_display_name.errors.required
                      "
                    >
                      Table Display Name is required.
                    </div>
                  </div>
                </div>
                <div class="form__group">
                  <label
                    for="tablelabel"
                    class="form__label form__label--master"
                    >Table Label<span class="form__group__mandatory"
                      >&lowast;</span
                    ></label
                  >
                  <input
                    type="text"
                    id="tablelabel"
                    class="form__input--master"
                    autocomplete="off"
                    formControlName="table_label"
                  />
                  <div
                    class="alert alert-danger"
                    *ngIf="
                      tblForm.controls.table_label.invalid &&
                      (tblForm.controls.table_label.dirty ||
                        tblForm.controls.table_label.touched)
                    "
                  >
                    <div *ngIf="tblForm.controls.table_label.errors.required">
                      Table Label is required.
                    </div>
                  </div>
                </div>
                <!-- Table Users -->
                <div class="form__group">
                  <label for="invoice name" class="form__label form__label--master">
                      Select User to access table
                      <span  class="form__group__mandatory">&lowast;</span></label>
                      <p-autoComplete 
                          panelStyleClass="form__input--master"
                          placeholder="Search and select user"
                          formControlName="table_users"
                          field="user_username"
                          [suggestions]="userList" 
                          (completeMethod)="searchUsers($event)"
                          [multiple]="true"
                          >
                          <ng-template let-value pTemplate="selectedItem">
                            <span>{{value.user_username}}</span>
                        </ng-template>    
                      </p-autoComplete>
                  <div
                      *ngIf="
                      tblForm.controls['table_users'].invalid &&
                      (tblForm.controls['table_users'].dirty ||
                          tblForm.controls['table_users'].touched)
                      "
                      class="alert alert-danger"
                  >
                      <div *ngIf="tblForm.controls['table_users'].errors.required">
                      User(s) is required.
                      </div>
                  </div>  
              </div>
                <div class="form__group">
                  <label for="tabledesc" class="form__label form__label--master"
                    >Table Description</label
                  >
                  <textarea
                    type="text"
                    id="tabledesc"
                    class="form__input--master"
                    autocomplete="off"
                    formControlName="table_desc"
                  ></textarea>
                </div>
                <div class="u-show-flex">
                  <button
                    type="submit"
                    class="btn btn--green"
                    [disabled]="!tblForm.valid"
                  >
                    <svg class="btn__icon">
                      <use
                        xlink:href="./assets/img/sprite.svg#icon-check-square"
                      ></use>
                    </svg>
                    &nbsp;Save
                  </button>
                  <button
                    type="button"
                    class="btn btn--white"
                    (click)="cleartblForm()"
                  >
                    <svg class="btn__icon">
                      <use xlink:href="./assets/img/sprite.svg#icon-x"></use>
                    </svg>
                    &nbsp;Clear
                  </button>
                </div>
              </form>
              <!-- colums -->
              <div class="dyntblmgmt__config__tbladd__columnform">
                <div class="dyntblmgmt__config__tbladd__columnform__headbox">
                  <h3 class="master__form__subheading">
                    <svg class="btn__icon">
                      <use
                        xlink:href="./assets/img/sprite.svg#icon-columns"
                      ></use>
                    </svg>
                    &nbsp;Add new columns
                  </h3>

                  <div
                    class="dyntblmgmt__config__tbladd__columnform__headbox__action"
                  >
                    <a
                      class="btn--link u-margin-left-vvsmall"
                      title="Add New Column"
                      (click)="addNewColumn()"
                    >
                      <svg>
                        <use
                          xlink:href="./assets/img/sprite.svg#icon-plus-circle"
                        ></use>
                      </svg>
                    </a>
                    <a
                      class="btn--link u-margin-left-vvsmall"
                      title="Download Template"
                      (click)="Download_ColumnTemplate()"
                    >
                      <svg>
                        <use
                          xlink:href="./assets/img/sprite.svg#icon-download"
                        ></use>
                      </svg>
                    </a>
                    <ngx-file-drop
                      (onFileDrop)="dropped_columns($event)"
                      [multiple]="false"
                      useDragEnter="false"
                      accept=".xlsx"
                    >
                      <ng-template
                        ngx-file-drop-content-tmp
                        let-openFileSelector="openFileSelector"
                      >
                        <a
                          class="btn--link u-margin-left-vvsmall"
                          title="Upload Column Details"
                          (click)="openFileSelector()"
                        >
                          <svg>
                            <use
                              xlink:href="./assets/img/sprite.svg#icon-upload"
                            ></use>
                          </svg>
                        </a>
                      </ng-template>
                    </ngx-file-drop>
                    <a
                      class="btn--link u-margin-left-vvsmall"
                      title="Remove All Columns"
                      (click)="removeAllColumns()"
                    >
                      <svg>
                        <use
                          xlink:href="./assets/img/sprite.svg#icon-trash"
                        ></use>
                      </svg>
                    </a>
                  </div>
                </div>

                <div
                  id="columnform__box"
                  class="dyntblmgmt__config__tbladd__columnform__box"
                  dragula="columns"
                  [dragulaModel]="tblColumnsDtls"
                >
                  <ng-container *ngFor="let tblcol of tblColumnsDtls">
                    <div class="dyntblmgmt__config__tbladd__columnform__item">
                      <span>{{ tblcol.column_order }}</span>
                      <input
                        type="text"
                        placeholder="Enter New Column Name"
                        [(ngModel)]="tblcol.column_header_name"
                      />
                      <a
                        class="btn--link u-margin-left-vvsmall"
                        title="Delete"
                        (click)="deleteColumn(tblcol)"
                      >
                        <svg>
                          <use
                            xlink:href="./assets/img/sprite.svg#icon-trash-2"
                          ></use>
                        </svg>
                      </a>
                      <a
                        class="btn--link u-margin-left-vvsmall"
                        title="Press clicking drag and drop to change order"
                      >
                        <svg>
                          <use
                            xlink:href="./assets/img/sprite.svg#icon-align-justify"
                          ></use>
                        </svg>
                      </a>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- Table Data Upload -->
        <section *ngIf="this.currentMenuDtls?.roleacc_is_edit=='1'">
          <h2>Table Data Upoad</h2>
          <div class="dyntblmgmt__upload">
            <!-- Tables List -->
            <div class="dyntblmgmt__upload__tbllist">
              <div class="dyntblmgmt__upload__tbllist__searchbox">
                <input
                  type="text"
                  class="form__input border-light"
                  autocomplete="on"
                  placeholder="Search Table"
                  [(ngModel)]="tblKeyword_upload"
                />
                <button (click)="onSearchtable_upload()"> 
                  <svg class="btn__icon">
                    <use xlink:href="./assets/img/sprite.svg#icon-search"></use>
                  </svg>
                </button>
              </div>

              <virtual-scroller
                [items]="tblDatas_upload"
                (vsEnd)="onVsEnd_upload($event, tblDatas_upload.length)"
              >
                <p-table
                  #tbl_search
                  [value]="tblDatas_upload"
                  [scrollable]="false"
                  scrollHeight="100px"
                  [rows]="SearhBox_RowCount"
                  class="dyntblmgmt__upload__tbllist__ptable"
                >
                  <ng-template pTemplate="body" let-rowData>
                    <tr style="height: 5px" >
                      <td style="padding: 0 !important;">
                        <a [title]="rowData.table_desc" [ngClass]="rowData.selected==false ? 'dyntblmgmt__upload__tbllist__ptable__item':'dyntblmgmt__upload__tbllist__ptable__item dyntblmgmt__upload__tbllist__ptable__item__selected'" (click)="onRowSelect_tblupload(rowData)">
                          <span> {{ rowData.table_display_name }} <label for="label">{{ rowData.table_label }}</label></span>
                           <!-- view -->
                           <a [routerLink]="['/table_data',rowData.table_id]" target="_blank" *ngIf="this.currentMenuDtls?.roleacc_is_view=='1'"
                           class="btn--link u-margin-left-vvsmall"
                           title="View data"
                         >
                           <svg>
                             <use
                               xlink:href="./assets/img/sprite.svg#icon-eye"
                             ></use>
                           </svg>
                         </a>
                        </a>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </virtual-scroller>
            </div>
            <div class="dyntblmgmt__upload__tblbox" *ngIf="selectedUploadTable!=null">
              <div class="dyntblmgmt__upload__tblbox__item">
                <div class="dyntblmgmt__upload__tblbox__item__header">
                  <div class="dyntblmgmt__upload__tblbox__item__header__box">
                    <h3>{{selectedUploadTable.table_display_name}}</h3>
                    <label for="table label">{{selectedUploadTable.table_label}}</label>
                  </div>
                  <div class="form__checkboxgroup">
                    <input 
                        type="checkbox" 
                        id="emptytable" [(ngModel)]="IsEmptyTable" >
                        <label for="emptytable">Empty the table</label>
                  </div>
                  <a
                    class="btn--link u-margin-left-vvsmall"
                    title="Download Template"
                    (click)="Download_Template()"
                  >
                    <svg>
                      <use
                        xlink:href="./assets/img/sprite.svg#icon-download"
                      ></use>
                    </svg>
                  </a>
                </div>
              </div>

              <ngx-file-drop
                dropZoneLabel="Drop files here"
                dropZoneClassName="dyntblmgmt__upload__tblbox__uploadsec__dropzone"
                multiple="false"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                contentClassName="dyntblmgmt__upload__tblbox__uploadsec"
                (onFileDrop)="dropped($event)"
              >
                <ng-template
                  ngx-file-drop-content-tmp
                  let-openFileSelector="openFileSelector"
                >
                  <span class="dyntblmgmt__upload__tblbox__uploadsec__msg"
                    >Drag and Drop the file(s) to upload</span
                  >
                  <span class="dyntblmgmt__upload__tblbox__uploadsec__msg"
                    >Or</span
                  >
                  <button
                    type="button"
                    class="fileupload__button btn btn--green"
                    (click)="openFileSelector()"
                  >
                    Click Here to Upload
                  </button>
                </ng-template>
              </ngx-file-drop>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</div>

<div class="popup {{ ShowUploadResult==true ? 'showpopup':'' }}" id="popup ">
  <div class="popup__content">
      <div class="popup__right">
          <a herf="#test" class="popup__close" (click)="ShowUploadResult=false">&times;</a>
          <h2 class="heading-secondary u-margin-bottom-small">
              Upload Status Report - {{selectedUploadTable?.table_display_name}}
          </h2>

          <div *ngFor="let result of UploadedStatusData;let i = index">

              <div class="fileupload__statusbar u-margin-top-small">
                  <div class="fileupload__statustext">
                      <span class="fileupload__statustext--head">
                          <svg class="fileupload__icon">
                              <use xlink:href="./assets/img/sprite.svg#icon-file"></use>
                          </svg>

                          <span class="fileupload__statustext--file">{{result.filename}}</span>
                      </span>
                  </div>

                  <!--Upload Status - Mismatch Column-->
                  <div class="fileupload__statustext u-margin-top-vsmall u-margin-left-medium_1" *ngIf="result.MismatchColums.length!=0">
                      <span class="fileupload__statustext--head">
                          <svg class="fileupload__icon fileupload__icon--error">
                              <use xlink:href="./assets/img/sprite.svg#icon-alert-circle"></use>
                          </svg>

                          <span class="fileupload__statustext--head">Plese make sure valid file/Columns name(s) are
                              correct</span>
                      </span>
                  </div>
                  <!--Upload Status - Error-->
                  <div class="fileupload__statustext u-margin-top-vsmall u-margin-left-medium_1" *ngIf="result.ErroRecords!=0 && result.MismatchColums.length==0">
                      <span class="fileupload__statustext--head">
                          <svg class="fileupload__icon fileupload__icon--error">
                              <use xlink:href="./assets/img/sprite.svg#icon-x-circle"></use>
                          </svg>

                          <span class="fileupload__statustext--head">{{result.StatusMessage}}</span>
                      </span>
                  </div>
                  <!--Upload Status - Success-->
                  <div class="fileupload__statustext u-margin-top-vsmall u-margin-left-medium_1" *ngIf="result.ErroRecords==0 && result.MismatchColums.length==0">
                      <svg class="fileupload__icon fileupload__icon--success">
                          <use xlink:href="./assets/img/sprite.svg#icon-check-circle"></use>
                      </svg>
                      <span class="fileupload__statustext--head">{{result.StatusMessage}}</span>
                      <span class="fileupload__statustext--val  u-color-primarydark">{{result.TotalRecords}}</span>
                  </div>
              </div>

              <ngx-datatable class="material" [rows]="result.MismatchColums" [columns]="columns" [summaryRow]="true"
                  *ngIf="result.MismatchColums.length>0" [summaryPosition]="'top'">

                  <ngx-datatable-column prop="ActualColumnName" name="Pre-defined column(s)"
                      [summaryTemplate]="Template_ActualColumnName">
                  </ngx-datatable-column>
                  <ngx-datatable-column prop="InputColumnName" name="Input column(s)"
                      [summaryTemplate]="Template_InputColumnName"></ngx-datatable-column>

              </ngx-datatable>

              <ng-template #Template_ActualColumnName>
                  <div class="super-custom-style">
                      <b>Pre-defined Column Count :</b> {{result.TemplatefieldsCount}}

                  </div>
              </ng-template>
              <ng-template #Template_InputColumnName>
                  <div class="super-custom-style">
                      <b>Input Column Count :</b> {{ result.FileFieldsCount }}
                  </div>
              </ng-template>
              <ng-template #Template_snotemp>
                  <div class="super-custom-style">
                  </div>
              </ng-template>

          </div>





      </div>
  </div>
</div>
