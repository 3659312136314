import { Injectable, Component } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import * as logo from '../_helpers/logo';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

@Injectable({
  providedIn: 'root',
})
export class ExportDataService {
  constructor() {}

  exportExcel(rptData) {
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    //Title, Header & Data
    const title = rptData.title;
    const header = rptData.headers;
    const data = rptData.data;
    const sheetname = rptData.sheetname;
    const file_extention = rptData.file_extention; // xlsx,xls,csv

    //Create a workbook with a worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(sheetname);

    //Add Row and formatting
    worksheet.mergeCells('C1', 'F4');
    let titleRow = worksheet.getCell('C1');
    titleRow.value = title;
    titleRow.font = {
      name: 'Calibri',
      size: 16,
      underline: 'single',
      bold: true,
      color: { argb: '0085A3' },
    };
    titleRow.alignment = { vertical: 'middle', horizontal: 'center' };

    // Date
    worksheet.mergeCells('G1:H4');
    let d = new Date();
    let date =
      d.getDate() + '-' + monthNames[d.getMonth()] + '-' + d.getFullYear();
    let dateCell = worksheet.getCell('G1');
    dateCell.value = date;
    dateCell.font = {
      name: 'Calibri',
      size: 12,
      bold: true,
    };
    dateCell.alignment = { vertical: 'middle', horizontal: 'center' };

    //Add Image
    let PropertyLogoImg = workbook.addImage({
      base64: logo.imgBase64,
      extension: 'png',
    });
    worksheet.mergeCells('A1:B4');
    worksheet.addImage(PropertyLogoImg, 'A1:B4');

    //Blank Row
    worksheet.addRow([]);

    //Adding Header Row
    let headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4167B8' },
        bgColor: { argb: '' },
      };
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12,
      };
    });

    // Adding Data with Conditional Formatting
    data.forEach((d) => {
      let row = worksheet.addRow(d);

      //let Products = row.getCell(6);
      //let color = 'FF99FF99';
      // if (+Products.value < 200000) {
      //   color = 'FF9999';
      // }

      // Products.fill = {
      //   type: 'pattern',
      //   pattern: 'solid',
      //   fgColor: { argb: color },
      // };
    });

    worksheet.getColumn(3).width = 20;
    worksheet.addRow([]);

    //Footer Row
    let footerRow = worksheet.addRow([
      'Report Generated from www.chemrobotics.com at ' + date,
    ]);
    footerRow.getCell(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFB050' },
    };

    //Merge Cells
    worksheet.mergeCells(`A${footerRow.number}:F${footerRow.number}`);

    //Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      fs.saveAs(blob, title + '.' + file_extention.replace('.', ''));
    });
  }
  
  exportPdf(rptData) {
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    //Title, Header & Data
    const title = rptData.title;
    const header = rptData.headers;
    const data = rptData.data;
    const file_extention = rptData.file_extention; // xlsx,xls,csv

    var doc = new jsPDF('p', 'in', [612, 792]);
    // var doc = new jsPDF({
    //   orientation: 'p',
    //   unit: 'in',
    //   format: 'a4',
    //   putOnlyUsedFonts:true
    //  });
    doc.addImage(logo.imgBase64, 'PNG', 0.2, 0, 0.5, 0.5);
    //doc.addImage(logo.imgBase64, 'PNG',0.1, 0.1, 0.5, 0.5);
    //autoTable(doc,{columns:header, body:data,margin: { top: 0.5 }});

    let columnstyledata={};
    for (let i = 0; i < header.length; i++) {
      columnstyledata[i]={cellWidth:3}
    }
    // debugger;


    autoTable(
        doc, 
        { 
            columns: header, 
            body: data, 
            margin: { top: 0.5 },
            theme:'grid',
            columnStyles:columnstyledata
            
        }
    );

    doc.save(title + '.pdf');
  }

  downloadPdf(rptData, columColor=[]) {

    //Title, Header & Data
    const title = rptData.title;
    const header = rptData.headers;
    const data = rptData.data;    

    let columnstyle={};
    header.forEach((element,i) => {
      columnstyle[i]={cellWidth: 3};
    });

    var doc = new jsPDF('p', 'in', [612, 792]);
    doc.addImage(logo.imgBase64, 'PNG', 0.2, 0, 0.5, 0.5);    
    doc.text(rptData.title, 20, 20);      
    // autoTable(doc,{columns:header, body:data,margin: { top: 0.5 }});
    autoTable(doc,{columns:header, body:data,
      tableWidth:'wrap',
      margin: { top: 0.5 },
      columnStyles: columnstyle ,
      willDrawCell: (HookData) => {
        // console.log(HookData);
        let x=columColor.find(x=>x.dataKey==HookData.column.dataKey);
        // console.log(x);
        if(x){
          doc.setFillColor(x.r,x.g,x.b);
          //for header default black
          if(HookData.cell.text.join("")==x.key){
            doc.setTextColor(0,0,0);
          }
          
        }
        // if (HookData.column.dataKey == "extension_expiry_date") {
        //   doc.setFillColor(255,183,183);
        //   doc.setTextColor(0,0,0);
        // }
      },
      tableLineWidth:0,
      styles: {
          overflow: 'linebreak'
        
      },});    
  
    doc.save(title+'.pdf');
  }
}