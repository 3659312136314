<div class="footer">
    <div class="container">
        <div class="footer__row">
          <div class="col-md-4">
              <img src="assets/img/logo.png" alt="chemrobotics" title="chemrobotics">
              <h6 class="mt-3">
                <b>ChemRobotics (AgroPat® / PharmVetPat®) provides information on new or existing active ingredient of Agro, Pharma and other allied sectors including Ayurvedic and Homeopathic area.</b>
              </h6>
              <div class="footer__icons">
              <a href="https://www.facebook.com/ChemRobotics-542500982914217/" target="_blank"> <i class="fa fa-facebook-f mr-3"></i> </a> 
              <a href="https://twitter.com/RoboticsChem" target="_blank"> <i class="fa fa-twitter mr-3"></i> </a>   
              <a href="https://www.linkedin.com/company/chemrobotics" target="_blank"> <i class="fa fa-linkedin mr-3"></i> </a>  
              <a href="https://www.instagram.com/chemrobotics/" target="_blank"> <i class="fa fa-instagram mr-3"></i> </a>
              <a href="https://youtube.com/channel/UCl21pDcr00u_asN6gCJ6hfQ" target="_blank"> <i class="fa fa-youtube mr-3"></i> </a> 
              </div>
          </div>
          <div class="col-md-2">
            <h4 class="footer-title">Quick Link</h4>
            <ul class="mt-4 footer-menu">
              <li><a href="/" target="_blank">Home</a></li>
              <li><a href="/#about-us">About Us</a></li>
              <li><a href="https://chemrobotics.in/" target="_blank">Blog</a></li>
              <li><a href="/clients" target="_blank">Clients</a></li>
              <li><a href="/career" target="_blank">Career</a></li>
              <li><a href="/agrochem/contact.php" target="_blank">Contact Us</a></li>
            </ul>
          </div>
          <div class="col-md-4">
            <h4 class="footer-title">Contact Us</h4>
              <h6 class="mt-4">
                <b>ChemRobotics Private Limited</b><br>
                <b><fa-icon [icon]="['fas', 'map-marked']"></fa-icon> C 130 SITE C UPSIDC SURAJPUR,<br> GREATER NOIDA 201311, INDIA</b><br>
                <b><fa-icon [icon]="['fas', 'phone-alt']"></fa-icon> +91 6266117944 , +91- 9407360810</b><br>
                <b><fa-icon [icon]="['far', 'envelope']"></fa-icon> support@chemrobotics.com,sales@chemrobotics.com</b>
              </h6>
              <span class="linked-btn-bottom">
                <script src="//platform.linkedin.com/in.js" type="text/javascript">
                 lang: en_US
                </script>
                <span class="IN-widget" data-lnkd-debug="<script type=&quot;IN/FollowCompany+init&quot; data-id=&quot;14407987&quot; data-counter=&quot;right&quot;></script>" style="display: inline-block; line-height: 1; vertical-align: bottom; padding: 0px; margin: 0px; text-indent: 0px;"><iframe id="{&quot;xdOrigin&quot;:&quot;https://www.chemrobotics.com&quot;,&quot;xdChannel&quot;:&quot;9114e1f7-f44e-4de1-b0e0-655089022ea3&quot;,&quot;framework&quot;:&quot;@linkedin/xdoor-sdk&quot;,&quot;version&quot;:&quot;0.1.156&quot;,&quot;debug&quot;:false}" name="{&quot;xdOrigin&quot;:&quot;https://www.chemrobotics.com&quot;,&quot;xdChannel&quot;:&quot;9114e1f7-f44e-4de1-b0e0-655089022ea3&quot;,&quot;framework&quot;:&quot;@linkedin/xdoor-sdk&quot;,&quot;version&quot;:&quot;0.1.156&quot;,&quot;debug&quot;:false}" src="https://www.linkedin.com/pages-extensions/FollowCompany?id=14407987&amp;counter=right&amp;xdOrigin=https%3A%2F%2Fwww.chemrobotics.com&amp;xdChannel=9114e1f7-f44e-4de1-b0e0-655089022ea3&amp;xd_origin_host=https%3A%2F%2Fwww.chemrobotics.com" class="IN-widget IN-widget--iframe" scrolling="no" allowtransparency="true" frameborder="0" border="0" width="1" height="1" style="vertical-align: bottom; width: 121px; height: 24px;"></iframe></span>
              </span>
          </div>
        </div>
      </div>
</div>
<footer class="footer-copyright">
    Copyright @ ChemRobotics 2022 | All Rights Reserved
</footer>