<ngx-spinner>
    <h3>Loading...</h3>
</ngx-spinner>
<!-- nav -->
<!-- <nav class="navbar navbar-expand navbar-dark bg-dark" *ngIf="currentUser">
  <div class="navbar-nav">
      <a class="nav-item nav-link" routerLink="/">Home</a>
      <a class="nav-item nav-link" (click)="logout()">Logout</a>
  </div>
</nav> -->
<ng-container *ngIf="currentUser==null && (this.current_app_status=='app_adminpanel' || this.current_app_status=='app_table_data')">
    <router-outlet></router-outlet>
</ng-container>

<ng-container *ngIf="this.current_app_status=='app_vendor_registration'">
    <router-outlet></router-outlet>
</ng-container>


<!-- main content container -->
<ng-container *ngIf="currentUser!=null && this.current_app_status=='app_adminpanel'">
    <div class="home">

        <main class="home__main">

            <div class="home__main__navbox">
                <div class="side_nav">
                    <div class="side_nav__logobox">
                        <img src="../assets/img/logo.png" alt="" class="side_nav__logo">
                        <a class="side_nav__appname" href="/">
                            Idea2Reality Admin Panel
                        </a>
                    </div>
                    <ul class="side_nav__menu">
                        <!-- <li class="side_nav__list">
                            <a [routerLink]="['/']"  routerLinkActive="side_nav__active" [routerLinkActiveOptions]="{exact:
                                true}" class="side_nav__link">
                                <svg class="side_nav__icon">
                                    <use xlink:href="./assets/img/sprite.svg#icon-upload-cloud"></use>
                                </svg>
                                <span class="side_nav__menuname">File Upload</span>
                            </a>
                        </li> -->
                        <li class="side_nav__list" *ngFor="let menuitem of menuDetails; let i=index;">
                            <a [routerLink]="[menuitem.routeurl]"  routerLinkActive="side_nav__active" [routerLinkActiveOptions]="{exact:true}" class="side_nav__link">
                                <svg class="side_nav__icon">
                                    <use [attr.xlink:href]="menuitem.menu_icon"></use>
                                </svg>
                                <span class="side_nav__menuname">{{menuitem.menu_title}}</span>
                            </a>
                        </li>                        
                         <li class="side_nav__list">
                            <a [routerLink]="['/user_profile']"  routerLinkActive="side_nav__active" [routerLinkActiveOptions]="{exact:
                                true}" class="side_nav__link">
                                <svg class="side_nav__icon">
                                    <use xlink:href="./assets/img/sprite.svg#icon-user"></use>
                                </svg>
                                <span class="side_nav__menuname">My Profile</span>
                            </a>
                        </li>                 
                        <li class="side_nav__list">
                            <a (click)="logout()" class="side_nav__link">
                                <svg class="side_nav__icon">
                                    <use xlink:href="./assets/img/sprite.svg#icon-log-out"></use>
                                </svg>
                                <span class="side_nav__menuname">Logout</span>
                            </a>
                        </li>

                    </ul>
                </div>
            </div>
            <!--Main Content Box-->
            <div class="home__main__contentbox">
                <!--Top Navigation-->
                <div class="header_nav">
                    <nav class="header_nav__user">
                        <img src="../assets/img/userprofile_temp.jpg" alt="" class="header_nav__userimg">
                        <a [routerLink]="['/user_profile']" class="header_nav__username" title="Click to view profile detail">
                            {{currentUser.username}}
                        </a>
                        <a class="header_nav__userlink" title="logout" (click)="logout()">
                            <svg class="header_nav__userlogout">
                                <use xlink:href="./assets/img/sprite.svg#icon-log-out"></use>
                            </svg>
                        </a>

                    </nav>
                </div>
                <!--Main Content-->
                <div class="home__main__content">
                    <router-outlet></router-outlet>
                </div>
            </div>

        </main>

    </div>
</ng-container>

<!-- table data page -->
<ng-container *ngIf="currentUser!=null && this.current_app_status=='app_table_data'">
    <div class="home">

        <main class="home__main">
            <!--Main Content Box-->
            <div class="home__main__contentbox">
                <!--Main Content-->
                <div class="home__main__content">
                    <router-outlet></router-outlet>
                </div>
            </div>

        </main>

    </div>
</ng-container>

<!-- table data public page -->
<ng-container *ngIf="this.current_app_status=='app_table_data_public'">
    <div class="home">

        <main class="home__main">
            <!--Main Content Box-->
            <div class="home__main__contentbox">
                <!--Main Content-->
                <div class="home__main__content">
                    <router-outlet></router-outlet>
                </div>
            </div>

        </main>

    </div>
</ng-container>
