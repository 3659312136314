<div class="dyntable dyntable__dyntabledata">
    <!-- if user have access only visible -->
    <ng-container *ngIf="this.isDataAccess">
      <div class="dyntable__dyntabledata__header">
        <h2 class="dyntable__dyntabledata__header__title">{{TableReportTitle}}</h2>
        <img src="../assets/img/logo.png" alt="logo" class="dyntable__dyntabledata__header__logo">
    </div>
    <!-- Table Header -->
    <div class="dyntable__header u-margin-top-small">
      <input
        *ngIf="this.TableSettings.menu_is_mstr_glbsearch"
        type="text"
        class="form__input border-light"
        autocomplete="on"
        placeholder="Search"
        (input)="onGlobalSearch($event.target.value)"
      />
      <button
        class="btn btn--white btn--round"
        type="button"
        title="Previous Page"
        (click)="prev()"
        [disabled]="isFirstPage_var"
      >
        <i class="pi pi-chevron-left"></i>
      </button>
      <button
        class="btn btn--white btn--round"
        type="button"
        title="Next Page"
        (click)="next()"
        [disabled]="isLastPage_var"
      >
        <i class="pi pi-chevron-right"></i>
      </button>
 
      <button *ngIf="this.TableSettings.roleacc_is_edit==1"
        class="btn btn--green"
        type="button"
        title="Add New Record"
        (click)="onAddNewEvent()"
      >
        <svg class="btn__icon">
          <use xlink:href="./assets/img/sprite.svg#icon-plus"></use>
        </svg>
        &nbsp;Add New
      </button>
      <button
        *ngIf="this.TableSettings.menu_is_mstr_downloadcsv"
        class="btn btn--white btn--round"
        type="button"
        title="Download CSV"
        (click)="exportExcel('csv')"
      >
        <i class="pi pi-file"></i>
      </button>
      <button
        *ngIf="this.TableSettings.menu_is_mstr_downloadexcel"
        class="btn btn--white btn--round"
        type="button"
        title="Download Excel"
        (click)="exportExcel('xlsx')"
      >
        <i class="pi pi-file-excel u-color-greendark"></i>
      </button>
      <button
        *ngIf="this.TableSettings.menu_is_mstr_downloadpdf"
        class="btn btn--white btn--round"
        type="button"
        title="Download Pdf"
        (click)="exportPdf()"
      >
        <i class="pi pi-file-pdf u-color-red"></i>
      </button>
      <button
        class="btn btn--white btn--round"
        type="button"
        title="Attach file for selected row"
        (click)="showAttachmentPopup()"
      >
        <i class="pi pi-paperclip u-color-primarydark"></i>
      </button>
    </div>
    <!-- Table Content : View Records-->
    <div class="dyntable__content">
      <p-table
        #dt
        [columns]="cols"
        [value]="products_current_datasource"
        [style]="{ width: '100%' }"
        [resizableColumns]="true"
        columnResizeMode="expand"
        styleClass="p-datatable-gridlines"
        responsiveLayout="auto"
        scrollDirection="horizontal"
        [scrollable]="true"
        scrollHeight="720px"
        [lazy]="true"
        (onLazyLoad)="loadLazyData($event)"
        [lazyLoadOnInit]="false"
        [paginator]="true"
        [(first)]="first"
        (onPage)="paginate($event)"
        [rows]="rows"
        [totalRecords]="totalRecords"
        [loading]="loading_table"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [rowsPerPageOptions]="[5, 10, 15, 20, 25, 50, 100, 150]"
        [globalFilterFields]="globalfilterFields"
        
        

      >
        <!-- <ng-template pTemplate="colgroup" let-columns>
          <colgroup>
            <col
              *ngFor="let col of columns"
              [ngStyle]="{ width: col.field_ui_tblcolwidth }"
            />
          </colgroup>
        </ng-template> -->
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th style="width: 1rem;flex: 0;flex-basis: 2%;justify-content: center;">
              <div class="dyntable__content__form__checkboxgroup">
                <input 
                    type="checkbox" 
                    id="headerchk" 
                    [readonly]="this.totalRecords==0"
                    [(ngModel)]="selectAll"
                    (change)="onSelectAllChange($event)" 
                    >
                    <label for="headerchk"></label>
              </div>
          </th>
            <th style=" justify-content: center;flex: 0;flex-basis: 6%;"></th>
            <th
              [ngStyle]="{ width: col.field_ui_tblcolwidth }"
              [style]="{ 'flex-basis': col.field_ui_tblcolwidth }"
              *ngFor="let col of columns"
              [pSortableColumn]="col.field_db_col_name"
              pResizableColumn
            >
              {{ col.field_ui_display_name }}
              <p-sortIcon [field]="col.field_db_col_name"></p-sortIcon>
            </th>
          </tr>
          <tr>
            <th style="width: 1rem;flex: 0;flex-basis: 2%;">
            </th>
            <th style=" justify-content: center;flex: 0;flex-basis: 6%;">
              
            </th>
            <th
              *ngFor="let col of columns"
              [ngStyle]="{ width: col.field_ui_tblcolwidth }"
              [style]="{ 'flex-basis': col.field_ui_tblcolwidth }"
            >
              <p-columnFilter
                [type]="
                  col.field_ui_type == 'textbox' ? 'text' : col.field_ui_type
                "
                [field]="col.field_db_col_name"
                matchMode="contains"
                [showOperator]="false"
                [showAddButton]="false"
                [display]="col.field_ui_type == 'date' ? 'menu' : 'row'"
              ></p-columnFilter>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr>
            <td style="width: 1rem;flex: 0;flex-basis: 2%;justify-content: center;">
              <div class="dyntable__content__form__checkboxgroup">
                <input 
                    type="checkbox" 
                    [id]="rowData.ID"
                    [(ngModel)]="rowData.row_select"
                    (change)="onSelectionChange($event)"  
                    >
                    <label [for]="rowData.ID"></label>
              </div>
            </td>
            <td style=" justify-content: center;flex: 0;flex-basis: 6%;; justify-content: center;" class="dyntable__content__actionbox">
              <!-- View Button -->
              <a
                class="btn--link u-margin-left-vvsmall u-margin-right-vvsmall"
                title="View"
                (click)="onViewEvent(rowData)"
                *ngIf="this.TableSettings.roleacc_is_view"
              >
                <svg>
                  <use xlink:href="./assets/img/sprite.svg#icon-eye"></use>
                </svg>
              </a>
              <!-- Edit Button -->
              <a
                *ngIf="this.TableSettings.roleacc_is_edit && showApproveView == false"
                class="btn--link u-margin-left-vvsmall u-margin-right-vvsmall"
                title="Edit"
                (click)="onEditEvent(rowData)"
              >
                <svg>
                  <use xlink:href="./assets/img/sprite.svg#icon-edit"></use>
                </svg>
              </a>
              <!-- Delete Button -->
              <a
                *ngIf="this.TableSettings.roleacc_is_delete && showApproveView == false"
                class="btn--link u-margin-left-vvsmall"
                title="Delete"
                (click)="onDeleteEvent(rowData)"
              >
                <svg>
                  <use xlink:href="./assets/img/sprite.svg#icon-trash-2"></use>
                </svg>
              </a>
              <!-- Approve Button -->
              <a
                *ngIf="this.TableSettings.roleacc_is_edit && showApproveView == true"
                class="btn--link u-margin-left-vvsmall"
                title="Approve"
                (click)="onApproveEvent(rowData)"
              >
                <svg>
                  <use
                    xlink:href="./assets/img/sprite.svg#icon-check-circle"
                  ></use>
                </svg>
              </a>
            </td>
            <td
              *ngFor="let col of columns"
              [ngStyle]="{ width: col.field_ui_tblcolwidth }"
              [style]="{ 'flex-basis': col.field_ui_tblcolwidth }"
              class="dyntable__content__td"
            >
              <!-- if not file type fileupload and checkbox -->
              <ng-container *ngIf="col.field_ui_type != 'checkbox'">
                {{
                  col.field_ui_type != "dropdown"
                    ? rowData[col.field_db_col_name]
                    : findNamefrmID(rowData, col.field_db_col_name)
                }}
              </ng-container>
              
              <!-- if not file type fileupload and checkbox -->
              <ng-container *ngIf="col.field_ui_type == 'checkbox'">
                  {{
                    rowData[col.field_db_col_name] == 1
                      ? 'Yes'
                      : 'No'
                  }}
              </ng-container>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    </ng-container>
    <!-- if user don't have access only visible -->
    <ng-container *ngIf="this.isDataAccess==false">
      <div class="dyntable__error">
        <div class="dyntable__error__box">
          <svg>
            <use
              xlink:href="./assets/img/sprite.svg#icon-x-octagon"
            ></use>
          </svg>
          <div class="dyntable__error__box__info">
            <h2>Access Denied</h2>
            <p>you do not have appropriate access rights. please contact admin.</p>
            
          </div>
          <button
                    type="submit"
                    class="btn btn--green"
                    (click)="navigateToHome()"
                  >
                    <svg class="btn__icon">
                      <use
                        xlink:href="./assets/img/sprite.svg#icon-home"
                      ></use>
                    </svg>
                    &nbsp;Home
                  </button>
        </div>
       
      </div>
    </ng-container>
</div>
  
<!-- Form View/Edit Popup  -->
<div class="popup {{ showData==true ? 'showpopup':'' }}" id="popup " style="backdrop-filter:none">
    <div class="popup__content" style="max-height: 80rem; width:70%;">
        <div class="popup__right" >
            <a herf="#test" class="popup__close" (click)="showData=false">&times;</a>
            <h2 class="heading-secondary u-margin-bottom-small u-color-primarydark">
                {{TableReportTitle}}
            </h2>
            <form *ngIf="dynForm!=null"
            class="form master__form"
            [formGroup]="dynForm"
            (ngSubmit)="ondynFormSubmit()"  
            >
            <!-- HEADING FOR ADD -->
            <h2 class="master__form__subheading" *ngIf="this.formEditMode=='ADD'">
                <svg class="btn__icon" >
                <use xlink:href="./assets/img/sprite.svg#icon-file-plus"></use>
                </svg>&nbsp;Add a new record
            </h2>
            <!-- HEADING FOR VIEW -->
            <h2 class="master__form__subheading" *ngIf="this.formEditMode=='VIEW'">
                <svg class="btn__icon" >
                <use xlink:href="./assets/img/sprite.svg#icon-file-text"></use>
            </svg>&nbsp;View the record
            </h2>
            <!-- HEADING FOR EDIT -->
            <h2 class="master__form__subheading" *ngIf="this.formEditMode=='EDIT'">
                <svg class="btn__icon" >
                <use xlink:href="./assets/img/sprite.svg#icon-edit"></use>
                </svg>&nbsp;Edit the record
            </h2>

            <div class="master__form__actions">
                <button 
                  class="btn btn--green" 
                  type="submit" 
                  [disabled]="dynForm.invalid && dynForm.pristine==true" 
                  *ngIf="this.formEditMode!='VIEW'" >
                  <svg class="btn__icon">
                    <use xlink:href="./assets/img/sprite.svg#icon-check-square"></use>
                  </svg>
                  &nbsp;Save
                </button>
              </div>
              <ng-container *ngFor="let item of field_details">

                <div class="form__group" *ngIf="item.field_db_col_name!='id' && item.field_ui_type!='fileupload'">
                    <label [for]="item.field_ui_display_name" class="form__label form__label--master">{{
                        item.field_ui_display_name
                      }}</label>
                        <textarea
                          type="text"
                          class="form__input--master"
                          [ngClass]="{
                          'form__input--master--invalid':
                              (dynForm.controls[item.field_db_col_name].invalid &&
                              (dynForm.controls[item.field_db_col_name].dirty ||
                                  dynForm.controls[item.field_db_col_name].touched)) === true
                          }"
                          autocomplete="off"
                          [placeholder]="'Enter ' + item.field_ui_display_name"
                          [formControlName]="item.field_db_col_name"
                          [id]="item.field_ui_display_name"
                          [readonly]="this.formEditMode=='VIEW' ? true : false"
                        ></textarea>
                    
                <div
                    *ngIf="
                    dynForm.controls[item.field_db_col_name].invalid &&
                    (dynForm.controls[item.field_db_col_name].dirty ||
                        dynForm.controls[item.field_db_col_name].touched)
                    "
                    class="alert alert-danger"
                >
                    <div *ngIf="dynForm.controls[item.field_db_col_name].errors.required">
                    {{ item.field_ui_display_name }} is required.
                    </div>
                    <div *ngIf="dynForm.controls[item.field_db_col_name].errors.pattern">
                    {{ item.field_ui_display_name }} must be a valid.
                    </div>
                    <div *ngIf="dynForm.controls[item.field_db_col_name].errors.maxlength">
                    {{ item.field_ui_display_name }} must be less than {{item.field_db_size}} characters.
                    </div>
                </div>
                </div>

                
              </ng-container>
              
        </form>
        <!-- Attachement -->
        <div class="uploaddoc" *ngIf="isShowAddEditForm == true">
          <ng-container *ngFor="let item of documentsDtls">
            <div class="uploaddoc__row">
              <div class="uploaddoc__row__head">
                <label for="Name of Document"></label>
                <span class="uploaddoc__row__head__docname">Attachment(s)</span>
              </div>
              <div class="uploaddoc__row__file">
                <div class="uploaddoc__row__file__uploading" *ngIf="this.formEditMode=='EDIT'">
                  <label for="files"
                    >Select file(s) (images/documnets) Maximum file size- 15MB
                    Maximum File Selection- 8</label
                  >
                  <p-fileUpload
                    name="myfile[]"
                    accept="application/msword, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf, image/*"
                    multiple="multiple"
                    [maxFileSize]="15000000"
                    [fileLimit]="8"
                    [customUpload]="true"
                    [showUploadButton]="false"
                    [auto]="true"
                    [disabled]="dataEditMode == 'VIEW'"
                    (uploadHandler)="fileUploader($event, item)"
                  >
                  </p-fileUpload>
                </div>
                <div class="uploaddoc__row__file__uploaded">
                  <label for="files">Uploaded file(s)</label>
                  <span
                    class="uploaddoc__row__file__uploaded__norec"
                    *ngIf="item.splitfiles.length == 0"
                    ><i class="bx bxs-file"></i> No File(s) uploaded yet!</span
                  >
                  <ul *ngIf="item.splitfiles.length > 0">
                    <ng-container *ngFor="let item_files of item.splitfiles">
                      <li>
                        <img
                          *ngIf="
                            this.commonService.getFileType(item_files) == 'IMAGE'
                          "
                          [attr.src]="
                            apidocPath +
                            '/dyntblfiles/' +
                            TableID +
                            '/' +
                            edit_data.ID +
                            '/' +
                            item_files
                          "
                          alt="documnet"
                        />
                        <img
                          *ngIf="
                            this.commonService.getFileType(item_files) == 'EXCEL'
                          "
                          src="../../../assets/img/EXCEL.png"
                          alt="Excel documnet"
                        />
                        <img
                          *ngIf="
                            this.commonService.getFileType(item_files) == 'DOC'
                          "
                          src="../../../assets/img/DOC.png"
                          alt="documnet"
                        />
                        <img
                          *ngIf="
                            this.commonService.getFileType(item_files) == 'PDF'
                          "
                          src="../../../assets/img/PDF.png"
                          alt="Pdf documnet"
                        />
                        <span>{{ item_files }}</span>
                        <div class="uploaddoc__row__file__uploaded__docaction">
                          <a
                            [attr.href]="
                              apidocPath +
                              '/dyntblfiles/' +
                              TableID +
                              '/' +
                              edit_data.ID +
                              '/' +
                              item_files
                            "
                            target="_blank"
                            class="btn--link u-margin-left-vvsmall"
                            title="View/Download"
                          >
                            <svg>
                              <use
                                xlink:href="./assets/img/sprite.svg#icon-download"
                              ></use>
                            </svg>
                          </a>
                          <a
                            class="btn--link u-margin-left-vvsmall"
                            title="Delete"
                            *ngIf="dataEditMode != 'VIEW'"
                            (click)="removeEmpDocs(item.doc_name, item_files)"
                          >
                            <svg>
                              <use
                                xlink:href="./assets/img/sprite.svg#icon-trash-2"
                              ></use>
                            </svg>
                          </a>
                        </div>
                      </li>
                    </ng-container>
                  </ul>
                </div>
              </div>
            </div>
          </ng-container>
          </div>
        <div class="master__form__actions" *ngIf="dynForm!=null" style="display: flex;justify-content: center;">
            <button 
              class="btn btn--green" 
              type="button" 
              (click)="ondynFormSubmit()"
              [disabled]="dynForm.invalid && dynForm.pristine==true" 
              *ngIf="this.formEditMode!='VIEW'" >
              <svg class="btn__icon">
                <use xlink:href="./assets/img/sprite.svg#icon-check-square"></use>
              </svg>
              &nbsp;Save
            </button>
        </div>
        </div>
    </div>
</div>

<!-- Form View/Edit Popup  -->
<div class="popup {{ isShowAttachmentPopup==true ? 'showpopup':'' }}" id="popup " style="backdrop-filter:none">
  <div class="popup__content" style="max-height: 80rem; width:70%;">
      <div class="popup__right" >
          <a herf="#test" class="popup__close" (click)="isShowAttachmentPopup=false">&times;</a>
          <h2 class="heading-secondary u-margin-bottom-small u-color-primarydark">
              Add Attachment(s) for Selected Record(s)
          </h2>

      <!-- Attachement -->
      <div class="uploaddoc">
        <ng-container *ngFor="let item of documentsDtls">
          <div class="uploaddoc__row">
            <div class="uploaddoc__row__head">
              <label for="Name of Document"></label>
              <span class="uploaddoc__row__head__docname">Attachment(s)</span>
            </div>
            <div class="uploaddoc__row__file">
              <div class="uploaddoc__row__file__uploading" style="grid-column: 1/-1;">
                <label for="files"
                  >Select file(s) (images/documnets) Maximum file size- 15MB
                  Maximum File Selection- 8</label
                >
                <p-fileUpload
                  name="myfile[]"
                  accept="application/msword, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf, image/*"
                  multiple="multiple"
                  [maxFileSize]="15000000"
                  [fileLimit]="8"
                  [customUpload]="true"
                  [showUploadButton]="false"
                  [auto]="true"
                  [disabled]="dataEditMode == 'VIEW'"
                  (uploadHandler)="fileUploader($event, item)"
                >
                </p-fileUpload>
              </div>
            </div>
          </div>
        </ng-container>
        </div>
      <div class="master__form__actions" style="display: flex;justify-content: center;">
          <button 
            class="btn btn--green" 
            type="button" 
            (click)="saveAttachements()"
            >
            <svg class="btn__icon">
              <use xlink:href="./assets/img/sprite.svg#icon-check-square"></use>
            </svg>
            &nbsp;Save
          </button>
      </div>
      </div>
  </div>
</div>