import { Component, OnInit } from '@angular/core';
import  { FileuploadComponent } from '../fileupload/fileupload.component';
import { FileuploadinterfaceService } from './fileuploadinterface.service';

@Component({
  selector: 'app-fileuploadinterface',
  templateUrl: './fileuploadinterface.component.html',
  styleUrls: ['./fileuploadinterface.component.scss']
})
export class FileuploadinterfaceComponent implements OnInit {

  tables:any=[];
  filtertables:any=[];

  constructor(private service: FileuploadinterfaceService) { }

  ngOnInit(): void {

    this.service.Get_Template_Table_details().subscribe(
      response=>{
        this.tables=response;
        this.filtertables=response;

        this.service.Get_Template_Table_details_mongo().subscribe({
          next:(res)=>{
            console.log(res);
            this.tables=this.tables.concat(res);
            this.filtertables=this.filtertables.concat(res);            
          }
        })
      }
    );
  }

  SearchUploads(event){
    let searchvalue=event.target.value;
    if(searchvalue!="")
    {
      
     let filtertables=  this.tables.filter(item=>{
        return item.TableName.trim().toLowerCase().includes(searchvalue.trim().toLowerCase());
      });
      this.filtertables=filtertables;      
    }
    else{
      this.filtertables=this.tables;
    }
  }

}
