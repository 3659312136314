import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DragulaService } from 'ng2-dragula';
import { MessageService } from 'primeng/api';
import { ToastserviceService } from 'src/app/_services/toastservice.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatasearchService } from 'src/app/_services/datasearch.service';
import { CommonService } from 'src/app/_services';
import { ExportDataService } from 'src/app/_services/export-data.service';

@Component({
  selector: 'app-manage-tables-data',
  templateUrl: './manage-tables-data.component.html',
  styleUrls: ['./manage-tables-data.component.scss']
})
export class ManageTablesDataComponent implements OnInit {
  userid: number = 0;
  current_user_role_id: number = 0;
  pageTitle: string = '';
  currentMenuDtls: any;
  templateList = [
    // "AGROPHARM_XMIM",
    // "AGRO_JARVIS",
    // "combination",
    // "INDIAN_PESTICIDE_DATABASE",
    // "PHARM_JARVIS",
    // "SPC_DATABASE",
    // "GLOBAL_PATENT_WATCH",
    // "AGROPHARM_XMIM_MONGO",
    "TEMPLATE_QUIK_PATENT_MONGO",
    "TEMPLATE_CHEMI_TRACKER_MONGO",
    "TEMPLATE_QUIK_PATENT_MONGO_ADMIN",
    "TEMPLATE_CHEMI_TRACKER_MONGO_ADMIN",
  ];
  dropdownManage: boolean = false;
  cols: any[] = [
      { field: 'checked', header: '', type: 'boolean' },
      { field: 'ExcelColumnName', header: 'Field', type: 'text' },      
    ];
  color1: string = '#ffffff';
  ShowUploadResult = false;
  uploadMessage = '';

  // RxJS Subscription is an excellent API for managing many unsubscribe calls.
  // See note below about unsubscribing.
  subs = new Subscription();

  constructor(private formBuilder: FormBuilder,
    private dragulaService: DragulaService,
    private toastService: ToastserviceService,
    private datasearchService: DatasearchService,
    private commonService: CommonService,
    private exportdataService: ExportDataService) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.currentMenuDtls = JSON.parse(
        sessionStorage.getItem('currentMenuDtls')
      );
      this.pageTitle = this.currentMenuDtls.menu_title;
    }, 1000);    
    this.userid = JSON.parse(localStorage.getItem('currentUser')).userid;
    this.current_user_role_id = JSON.parse(
      localStorage.getItem('currentUser')
    ).role_id;

    //Get the column detail when initialize
    this.getTables();
    this.actList = [
                      {activity: 'Download'},
                      {activity: 'Replace'},
                      {activity: 'Append'},
                    ];
  }

  tblList: any[] = [];
  selectedTable: any;
  filteredTables: any[];
  actList: any[] = [];
  selectedActivity: any;
  filteredActivity: any[];
  tblColumnsDtls: any[] = [];
  tblColumnsDtls_Temp: any[] = [];
  scrollInterval: any;
  IsDisplayNewExists: boolean = true;

  Default_Grid_RowsCount:number;
  Default_DownloadRec_Limit:number;
  Default_Grid_RowsCount_Temp:number;
  Default_DownloadRec_Limit_Temp:number;
  Batch_Size:number;
  Download_Size:number;
  totalRecords:number;
  Search_Val:string;
  Update_Val:string;
  Delimiter:string;
  checkAll:boolean=false;



  //Get Table Detail
  getTables() {
    let tempList = [
      // "AGROPHARM_XMIM",
      // "AGRO_JARVIS",
      // "combination",
      // "INDIAN_PESTICIDE_DATABASE",
      // "PHARM_JARVIS",
      // "SPC_DATABASE",
      // "GLOBAL_PATENT_WATCH",
      "GLOBAL_PHARMACEUTICAL_PRODUCT_DIRECTORY",
      // "AGROPHARM_XMIM_MONGO",
      // "QUIK_PATENT_MONGO",
      // "CHEMI_TRACKER_MONGO"
    ];
    this.datasearchService.Get_Template_Tables().subscribe(
      (res) => {
        this.tblList =[];
        let data: any = res;
        data.forEach(x=>{
          if(tempList.includes(x.TableName)){
            if(x.TableName=="AGROPHARM_XMIM"){
             x['TableDisplayName']="AGROPHARM_XMIM (1.0 & 2.0)";
            }
            else{
              x['TableDisplayName']=x.TableName;
            }
            this.tblList.push(x);
          }
        });

        //Get MongoDB Server Tables
        this.datasearchService.Get_Template_Tables_mongo().subscribe(
          (res)=>{
            let data: any = res;
            data.forEach(x=>{
              if(tempList.includes(x.TableName)){
                if(x.TableName=="AGROPHARM_XMIM_MONGO"){
                x['TableDisplayName']="AGROPHARM_XMIM (1.0 & 2.0) (MongoDB)";
                }
                else{
                  if(x.AdminTemplateName){
                    let tableDetail = JSON.parse(JSON.stringify(x));
                    tableDetail.TemplateName = tableDetail.AdminTemplateName;
                    tableDetail['TableDisplayName']=`${tableDetail.TableName}_ADMIN (MongoDB)`;
                    tableDetail['TableName']=`${tableDetail.TableName}_ADMIN`;
                    delete tableDetail.AdminTemplateName;
                    delete x.AdminTemplateName;
                    this.tblList.push(tableDetail);
                  }
                  x['TableDisplayName']=`${x.TableName} (MongoDB)`;
                }                
                this.tblList.push(x);
              }              
            });
          }
        );
      },
      (error) => {
        console.log(error);
      },
      () => {}
    );    
  }

  filterTable(event) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < this.tblList.length; i++) {
      let table = this.tblList[i];
      if (table.TableName.toLowerCase().includes(query.toLowerCase())) {
        filtered.push(table);
      }
    }
    this.filteredTables = filtered;    
  }

  onSelectTable(event) {    
    // debugger;
    this.uploadMessage = ''
    this.dropdownManage = false;
    this.tblColumnsDtls = null;
    this.IsDisplayNewExists = false;
    this.Default_DownloadRec_Limit=this.selectedTable.Default_DownloadRec_Limit;
    this.Default_Grid_RowsCount=this.selectedTable.Default_Grid_RowsCount;
    this.Default_DownloadRec_Limit_Temp=this.selectedTable.Default_DownloadRec_Limit;
    this.Default_Grid_RowsCount_Temp=this.selectedTable.Default_Grid_RowsCount;
    this.getColumnDetails();    
    this.totalRecordCount(event);
    for(let i=0; i<this.templateList.length; i++){
      if(this.selectedTable && this.templateList[i] === this.selectedTable.TemplateName){
        this.dropdownManage = true;        
        break;
      }
    }
  }

  //Get the column detail
  getColumnDetails() {
    let postdata = {
      mode: 'GET_COLUMNS',
      table_name: this.selectedTable.TableName,
    };
    let isMongoDbTable=this.selectedTable.TableDisplayName.includes("(MongoDB)");
    let manageTemplateColumn = isMongoDbTable==true ? this.datasearchService.manageTemplateColumn_mongo(postdata) : this.datasearchService.manageTemplateColumn(postdata);
    manageTemplateColumn.subscribe(
      (res:any[]) => {        
        let data = res.length==2?res[0]:res;
        data.forEach((x) => {
          if (!x.hasOwnProperty('Is_display_new')) {
            this.IsDisplayNewExists = false;
            x['Is_display_new'] = x.Is_display;
          } else {
            this.IsDisplayNewExists = true;
          }

          x.ui_width_temp=x.ui_width.replace("px","").trim();
        });
        this.tblColumnsDtls = data;        
        this.tblColumnsDtls_Temp = JSON.parse(JSON.stringify(data));
      },
      (error) => {
        console.log(error);
      },
      () => {}
    );
  }

  // Get total record count in table
  totalRecordCount(tableDetails){
    this.datasearchService.getTableDataCount({tablename: tableDetails.TableName}).subscribe((res)=>{      
      this.totalRecords = res[0].count;
    },
      (error) => {
        console.log(error);
      },
      () => {}
    );
  } 

  filterActivity(event) {
    this.uploadMessage = '';
    let filtered: any[] = [];
    let query = event.query;    
    for (let i = 0; i < this.actList.length; i++) {
      let activity = this.actList[i];
      if (activity.activity.toLowerCase().includes(query.toLowerCase())) {
        filtered.push(activity);
      }
    }
    this.filteredActivity = filtered;    
  }  

  onBlurUiWidth(tblcol){
    tblcol.ui_width=tblcol.ui_width_temp.toString()+"px";
  }

  changeBooltoint(tblcol) {
    if (this.IsDisplayNewExists) {
      tblcol.Is_display_new = tblcol.Is_display_new == true ? 1 : 0;
    } else {
      tblcol.Is_display = tblcol.Is_display_new == true ? 1 : 0;
    }
  }

  dropdownField(tblcol){
    console.log(tblcol);
  }

  resetColumdetail() {
    this.tblColumnsDtls = JSON.parse(JSON.stringify(this.tblColumnsDtls_Temp));
  }

  //Update Table Details
  updateTableDtls(){
    // debugger;
    let postdata={
      tablename:this.selectedTable.TableName,
      Default_Grid_RowsCount: this.Default_Grid_RowsCount,
      Default_DownloadRec_Limit: this.Default_DownloadRec_Limit
    };
    let isMongoDbTable=this.selectedTable.TableDisplayName.includes("(MongoDB)");

    let Update_TableDtls= isMongoDbTable==true ? this.datasearchService.Update_TableDtls_mongo(postdata) : this.datasearchService.Update_TableDtls(postdata);
    Update_TableDtls.subscribe(
      (res) => {
        // debugger;
        this.getTables();
      },
      (error) => {
        console.log(error);
      },
      () => {}
    );
    
  }

  //submit the updated details
  updateColumnDetail() {
    this.tblColumnsDtls.forEach((item, i) => {
      item.display_order = i + 1;
      item.Is_display = item.Is_display===true || item.Is_display===1?1:0;
      item.Is_display_new = item.Is_display;
      item.ui_width = item.ui_width_temp+'px';
      if(this.selectedTable.Tabledetails==='AGROPHARM_XMIM_MONGO'){
        // item.column_color = this.color1;
        console.log(item.column_color);
      }
    });
    
    let IsModificationHappen =
      ((JSON.stringify(this.tblColumnsDtls) == JSON.stringify(this.tblColumnsDtls_Temp)) == true && 
       (this.Default_DownloadRec_Limit==this.Default_DownloadRec_Limit_Temp) == true &&
       (this.Default_Grid_RowsCount==this.Default_Grid_RowsCount_Temp) ==true
      )
        ? false
        : true;

    if (IsModificationHappen == true) {

      //Update Table Details
      this.updateTableDtls();

      //for slash escape
      let regex_formesc = /\\/gm;
      let formescapestring = JSON.stringify(this.tblColumnsDtls);
      formescapestring = formescapestring.replace(regex_formesc, '\\\\');

      //for single quote esacpe
      regex_formesc = /'/gm;
      formescapestring = formescapestring.replace(regex_formesc, "''");

      let uploaddata = JSON.parse(formescapestring);
      uploaddata.forEach((row)=>{
        row.ExcelColumnName = row.DisplayColumnName;
      })

      let postdata = {
        mode: 'UPDATE_COLUMNS_DETAILS',
        table_name: this.selectedTable.TableName,
        table_data: uploaddata,
      }; 

      let isMongoDbTable=this.selectedTable.TableDisplayName.includes("(MongoDB)");

      let manageTemplateColumn= isMongoDbTable==true ? this.datasearchService.manageTemplateColumn_mongo(postdata) : this.datasearchService.manageTemplateColumn(postdata);

      manageTemplateColumn.subscribe(
        (res) => {
          this.getColumnDetails();
          // debugger;
          this.toastService.showSuccess(
            'Success',
            `Column Changes Saved Successfully.`
          );
        },
        (error) => {
          console.log(error);
        },
        () => {}
      );
    } else {
      this.toastService.showWarning(
        'No Changes Made!',
        `Please edit the data to continue.`
      );
    }
  }   
  
  selectAllField(){    
    this.checkAll = !this.checkAll;    
    this.tblColumnsDtls.forEach((col)=>{
      col.checked = this.checkAll;
    });
  }

  selectField(col){
    this.checkAll = this.tblColumnsDtls.filter((col)=>{
      return !col.checked;
    }).length?false:true;
  }

  disableDownload(){
    return this.tblColumnsDtls.filter((col)=>{
      return col.checked;
    }).length>0 && this.Batch_Size>0 && this.Download_Size>0?false:true;
  }

  downloadData(ext?){
    let selectedCol = this.tblColumnsDtls.filter((col)=>{
      return col.checked;
    }).map((field)=>{
      return field.TableColumnName;
    });
    this.Batch_Size = this.totalRecords>=this.Batch_Size?this.Batch_Size:this.totalRecords;
    this.Download_Size = this.Download_Size>=this.Batch_Size?this.Download_Size:this.Batch_Size;
    this.Download_Size = this.totalRecords>=this.Download_Size?this.Download_Size:this.totalRecords;
    for(let i=0; i<Math.ceil(this.Download_Size/this.Batch_Size); i++){
      let postdata = {
        tablename: this.selectedTable.TableName,
        fields: selectedCol.join(),
        offset: i*this.Batch_Size,
        limit: (i+1===Math.ceil(this.Download_Size/this.Batch_Size) && this.Download_Size%this.Batch_Size !==0)?this.Download_Size%this.Batch_Size:this.Batch_Size,
      }      
      
      this.datasearchService.getTableData(postdata).subscribe(async (res:any)=>{
        let DownloadData = res.rows;
        let rptData = [];
        let header_temp = [];
        DownloadData.forEach((row, index)=>{
          row.S_NO = index+1+(this.Batch_Size*i);
        });
        
        header_temp.push('S_NO');        
        header_temp.push(...(await Promise.all(this.tblColumnsDtls.filter((col)=>{
          return col.checked;
        }).map((field)=>{
          return field.ExcelColumnName;
        }))));
        await Promise.all(DownloadData.map(async (row: any) => { 
          let rowValues = [];              
          header_temp.forEach((col)=>{
            rowValues.push(row[col]);
          });          
          rptData.push(rowValues);          
        }));        

        let reportData = {
          title: `${this.selectedTable.TableName} Data Report`,
          data: rptData,
          headers: header_temp,
          sheetname: 'data',
          file_extention: ext,          
        };        
        ext?this.exportdataService.exportExcel(reportData):this.exportdataService.downloadPdf(reportData);;
      },
        (error) => {
          console.log(error);
        },
        () => {}
      );
    }    
  }  
  
  updateData(){    
    let selectedCol = this.tblColumnsDtls.filter((col)=>{
      return col.checked;
    }).map((field)=>{
      return field.TableColumnName;
    });    
    
    let postdata = {
      mode: this.selectedActivity.activity,
      tablename: this.selectedTable.TableName,
      fields: selectedCol.join(),
      searchValue: this.Search_Val,
      updateValue: this.Update_Val
    }
    if(this.selectedActivity.activity === 'Append'){
      postdata['Delimiter'] = this.Delimiter;
    }
    this.datasearchService.updateTableData(postdata).subscribe((res:any)=>{      
      console.log(res);
      this.uploadMessage = `${res.count} rows updated.`
    },
      (error) => {
        console.log(error);
      },
      () => {}
    );
  }

}
