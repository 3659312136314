<div class="master">
  <h1 class="master__pageheading">{{ this.pageTitle }}</h1>
  <div class="master__content">
    <!-- Dynamic Table -->
    <app-dynamic-table
      *ngIf="isShowAddEditForm == false"
      [TableID]="this.table_id"
      [tableSettingsSubject]="tableSettingsSubject$.asObservable()"
      TableReportTitle="Employee Master"
      (onAddNew)="onAddNew($event)"
      (onView)="viewForm($event)"
      (onEdit)="editForm($event)"
      (onDelete)="deleteRecord($event)"
      (onApprove)="approveRecord($event)"
      (onDeleteApprove)="deleteApproveRecord($event)"
      [refreshTableSubject]="refreshTableSubject$.asObservable()"
      [approvalcount]="pendingApprovalCnt"
    ></app-dynamic-table>
    <!-- Dynamic Form -->
    <app-dynamic-form
      *ngIf="isShowAddEditForm == true"
      menu_uniq_name="employee"
      [field_details]="fieldDtls"
      [field_data]="fieldData"
      [formEditMode]="dataEditMode"
      [markFormChanged]="markFormChanged$.asObservable()"
      (onFormSubmitEvent)="formSubmit($event)"
      (onFormCancelEvent)="formCancel($event)"
      (onApprove)="approveRecord($event)"
      (onDeleteApprove)="deleteApproveRecord($event)"
    ></app-dynamic-form>
    <!-- Documents -->
    <div class="uploaddoc" *ngIf="isShowAddEditForm == true">
      <h1 class="uploaddoc__title">Documents</h1>
      <ng-container *ngFor="let item of documentsDtls">
        <div class="uploaddoc__row">
          <div class="uploaddoc__row__head">
            <label for="Name of Document"></label>
            <span class="uploaddoc__row__head__docname">{{
              item.doc_name
            }}</span>
          </div>
          <div class="uploaddoc__row__file">
            <div class="uploaddoc__row__file__uploading">
              <label for="files"
                >Select file(s) (images/documnets) Maximum file size- 15MB
                Maximum File Selection- 8</label
              >
              <p-fileUpload
                name="myfile[]"
                accept="application/msword, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf, image/*"
                multiple="multiple"
                [maxFileSize]="15000000"
                [fileLimit]="8"
                [customUpload]="true"
                [showUploadButton]="false"
                [auto]="true"
                [disabled]="dataEditMode == 'VIEW'"
                (uploadHandler)="fileUploader($event, item)"
              >
              </p-fileUpload>
            </div>
            <div class="uploaddoc__row__file__uploaded">
              <label for="files">Uploaded file(s)</label>
              <span
                class="uploaddoc__row__file__uploaded__norec"
                *ngIf="item.splitfiles.length == 0"
                ><i class="bx bxs-file"></i> No File(s) uploaded yet!</span
              >
              <ul *ngIf="item.splitfiles.length > 0">
                <ng-container *ngFor="let item_files of item.splitfiles">
                  <li>
                    <img
                      *ngIf="
                        this.commonService.getFileType(item_files) == 'IMAGE'
                      "
                      [attr.src]="
                        apidocPath +
                        '/empdocs/' +
                        item.doc_user_name +
                        '/' +
                        item.doc_name +
                        '/' +
                        item_files
                      "
                      alt="documnet"
                    />
                    <img
                      *ngIf="
                        this.commonService.getFileType(item_files) == 'EXCEL'
                      "
                      src="../../../assets/img/EXCEL.png"
                      alt="Excel documnet"
                    />
                    <img
                      *ngIf="
                        this.commonService.getFileType(item_files) == 'DOC'
                      "
                      src="../../../assets/img/DOC.png"
                      alt="documnet"
                    />
                    <img
                      *ngIf="
                        this.commonService.getFileType(item_files) == 'PDF'
                      "
                      src="../../../assets/img/PDF.png"
                      alt="Pdf documnet"
                    />
                    <span>{{ item_files }}</span>
                    <div class="uploaddoc__row__file__uploaded__docaction">
                      <a
                        [attr.href]="
                          apidocPath +
                          '/empdocs/' +
                          item.doc_user_name +
                          '/' +
                          item.doc_name +
                          '/' +
                          item_files
                        "
                        target="_blank"
                        class="btn--link u-margin-left-vvsmall"
                        title="View/Download"
                      >
                        <svg>
                          <use
                            xlink:href="./assets/img/sprite.svg#icon-eye"
                          ></use>
                        </svg>
                      </a>
                      <a
                        class="btn--link u-margin-left-vvsmall"
                        title="Delete"
                        *ngIf="dataEditMode != 'VIEW'"
                        (click)="removeEmpDocs(item.doc_name, item_files)"
                      >
                        <svg>
                          <use
                            xlink:href="./assets/img/sprite.svg#icon-trash-2"
                          ></use>
                        </svg>
                      </a>
                    </div>
                  </li>
                </ng-container>
              </ul>
            </div>
          </div>
          <div class="uploaddoc__row__desc">
            <label for="Description">Description</label>
            <textarea
              type="text"
              class="form__input"
              [(ngModel)]="item.doc_description"
              [disabled]="dataEditMode == 'VIEW'"
            ></textarea>
          </div>
          <div class="uploaddoc__row__notes">
            <label for="Notes">Notes</label>
            <textarea
              type="text"
              class="form__input"
              [(ngModel)]="item.doc_otherdtls"
              [disabled]="dataEditMode == 'VIEW'"
            ></textarea>
          </div>
        </div>
      </ng-container>
      <div class="uploaddoc__addnewsection">
        <div class="form__input_group">
          <input type="text" placeholder="Enter Documnet Name" [(ngModel)]="tempDocName" />
          <button [disabled]="tempDocName.trim()==''" (click)="addTempDocument()" type="button"><i class='bx bx-plus'></i></button>
        </div>
      </div>
    </div>
  </div>
</div>
