import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { CommonService } from './common.service';

@Injectable()   
export class ConstantsService{
    //readonly ApiUrl: string = 'http://api.chemrobotics.com/';
    readonly ApiUrl: string = environment.appUrl;
    readonly betaApiUrl: string = environment.betaAppUrl;
    public  menuDetails= new BehaviorSubject(JSON.parse(localStorage.getItem('menudtls')));
    public isLoggedOut=new BehaviorSubject(true);
    public currentAppStatus="";

    constructor(private datePipe: DatePipe,private commonService:CommonService){
        this.commonService.cast_current_app_statuse.subscribe((val) => {
            this.currentAppStatus=val;
        }); 
    }

    

    /////// GET SQL QUERY FROM P-TABLE FILTER //////
    getTablesqlFilterCondition(filterdata:any):string{
        let filtercondition_sql="";
        if(filterdata){
            let filterconditions:string[]=[];        
            Object.keys(filterdata).forEach(item=>{
               let columnname=item;
               let filterconditions_temp=[];
               let filterconditionvalue= null;
               if(Array.isArray(filterdata[item])){
                   let arrayconditions=filterdata[item];
                   arrayconditions.forEach(element => {
                    if(element.value){
                        filterconditions_temp.push({filterconditionvalue:element.value,matchMode: element.matchMode});
                    }
                   });                
               }
               else{
                filterconditions_temp.push({filterconditionvalue:filterdata[item].value,matchMode: filterdata[item].matchMode});
               }

               filterconditions_temp.forEach(item=>{
                let makcondition="";
                if(item.filterconditionvalue){
                    switch (item.matchMode) {
                        case "startsWith":
                         makcondition=`${columnname} LIKE ''${item.filterconditionvalue}%''`;
                         break;
                         case "endsWith":
                             makcondition=`${columnname} LIKE ''%${item.filterconditionvalue}''`;
                         break;
                         case "contains":
                             if((item.filterconditionvalue instanceof Date)){
                                item.filterconditionvalue=this.datePipe.transform(item.filterconditionvalue,"yyyy-MM-dd")
                             }
                             makcondition=`${columnname} LIKE ''%${item.filterconditionvalue}%''`;
                         break;
                         case "notContains":
                             makcondition=`${columnname} NOT LIKE ''%${item.filterconditionvalue}%''`;
                         break;
                         case "equals":
                             makcondition=`${columnname} = ''${item.filterconditionvalue}''`;
                         break;
                         case "notEquals":
                             makcondition=`${columnname} != ''${item.filterconditionvalue}''`;
                         break;
                         case "dateAfter":
                             makcondition=`${columnname} > ''${this.datePipe.transform(item.filterconditionvalue,"yyyy-MM-dd")}''`;
                         break;
                         case "dateBefore":
                             makcondition=`${columnname} < ''${this.datePipe.transform(item.filterconditionvalue,"yyyy-MM-dd")}''`;
                         break;
                         case "dateIsNot":
                             makcondition=`${columnname} != ''${this.datePipe.transform(item.filterconditionvalue,"yyyy-MM-dd")}''`;
                         break;
                         case "dateIs":
                             makcondition=`${columnname} = ''${this.datePipe.transform(item.filterconditionvalue,"yyyy-MM-dd")}''`;
                         break;
                        default:
                         makcondition=`1=1`
                            break;
                    }
     
                    filterconditions.push(makcondition);
                }
               });
               
            });
    
            filtercondition_sql=filterconditions.length>0 ? ' ('+filterconditions.join(" AND ")+')' : "";
        }
        

        return filtercondition_sql;
    }
}