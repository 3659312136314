import { Component, OnInit } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { dyndataobject_model } from 'src/app/_model/dyndataobject_model';
import { CommonService, ConstantsService } from 'src/app/_services';
import { DatasearchService } from 'src/app/_services/datasearch.service';
import { ToastserviceService } from 'src/app/_services/toastservice.service';

var generator = require('generate-password');
var bcrypt = require('bcryptjs');

const saltRounds = 10;

@Component({
  selector: 'app-employee-master',
  templateUrl: './employee-master.component.html',
  styleUrls: ['./employee-master.component.scss'],
})
export class EmployeeMasterComponent implements OnInit {
  userid: number = 0;
  current_user_role_id: number = 0;
  table_id: number = 2;
  refreshTableSubject$: Subject<boolean> = new Subject<boolean>();
  tableSettingsSubject$: BehaviorSubject<any> = new BehaviorSubject<any>({
    menu_is_mstr_glbsearch: false,
    menu_is_mstr_downloadcsv: false,
    menu_is_mstr_downloadexcel: false,
    menu_is_mstr_downloadpdf: false,
  });
  markFormChanged$: Subject<boolean> = new Subject<boolean>();
  postdata: dyndataobject_model = {
    mode: 'FORM_SUBMIT',
    userid: this.userid,
    role_id: this.current_user_role_id,
    table_id: this.table_id, //Role master id
    form_action: 'ADD',
    form_data: {},
    approveid: 0,
  };
  pageTitle: string = '';
  currentMenuDtls: any;
  isShowAddEditForm: boolean = false;
  fieldDtls: any[];
  fieldData: any;
  dataEditMode: string = 'ADD'; // ADD,EDIT,VIEW
  allMenuDtls: any[] = [];
  temp_allMenuDtls: any[] = [];
  temp_password: string;
  pendingApprovalCnt: number = 0;

  constructor(
    private constantService: ConstantsService,
    private toastserviceService: ToastserviceService,
    private datasearchService: DatasearchService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.currentMenuDtls = JSON.parse(
        sessionStorage.getItem('currentMenuDtls')
      );
      this.tableSettingsSubject$.next(this.currentMenuDtls);
      this.pageTitle = this.currentMenuDtls.menu_title;
    }, 1000);

    this.userid = JSON.parse(localStorage.getItem('currentUser')).userid;
    this.current_user_role_id = JSON.parse(
      localStorage.getItem('currentUser')
    ).role_id;

    this.postdata = {
      mode: 'FORM_SUBMIT',
      userid: this.userid,
      role_id: this.current_user_role_id,
      table_id: this.table_id, //Role master id
      form_action: 'ADD',
      form_data: {},
    };

    this.getTotalPendingApproval();
  }

  onAddNew(form_data) {
    this.showForm('ADD', form_data);
  }
  viewForm(form_data) {
    this.showForm('VIEW', form_data);
  }
  editForm(form_data) {
    this.showForm('EDIT', form_data);
  }
  deleteRecord(form_data) {
    this.toastserviceService.showConfirm(
      'Confirm',
      'Are you sure want to delete this record ?',
      (confirmed) => {
        if (confirmed) {
          this.dataEditMode = 'DELETE';
          this.saveRecord(form_data);
        }
      }
    );
  }
  deleteApproveRecord(row_data) {
    this.toastserviceService.showConfirm("Confirm","Are sure want to delete the record?",(isconfiremed)=>{
      if(isconfiremed){
        this.postdata.mode = 'FORM_APPROVE_DELETE';
        this.postdata.approveid = row_data.id;
        this.fieldData=row_data;
        this.datasearchService.GetDynTableData(this.postdata).subscribe(
          (res) => {
          
            //console.log(res);
            this.refreshTableSubject$.next(true); //refresh the table
            this.toastserviceService.showSuccess('Success!', `Approval Record Deleted/Rejected.`);
            this.getTotalPendingApproval();
          },
          (error) => {
            this.toastserviceService.showError(
              'Error!',
              'Something went wrong. Please refresh the page and try again!'
            );
          },
          () => {
            this.isShowAddEditForm = false;
          }
        );
      }
    });
    
  }
  formSubmit(event: FormGroup) {
    if (event.valid) {
      let isFormModifiedByUser = event.pristine == false ? true : false;
      if (isFormModifiedByUser) {
        //Confirmation
        this.toastserviceService.showConfirm(
          'Confirm',
          'Do you want to save the record?',
          (confirmed) => {
            if (confirmed) {
              //console.log(event.value);
              this.postdata.form_action = this.dataEditMode;

              // if (this.postdata.form_action == 'ADD') {
              //   //Set Random Password
              //   this.temp_password = generator.generate({
              //     length: 10,
              //     numbers: true,
              //   });
              //   bcrypt.genSalt(saltRounds, (err, salt) => {
              //     bcrypt.hash(this.temp_password, salt, (err, hash) => {
              //       event.controls['user_password'].setValue(hash);
              //       this.saveRecord(event.value);
              //     });
              //   });
              // } else {
              //   this.saveRecord(event.value);
              // }

                bcrypt.genSalt(saltRounds, (err, salt) => {
                  bcrypt.hash( event.controls['user_password'].value, salt, (err, hash) => {
                    event.controls['user_password'].setValue(hash);
                    this.saveRecord(event.value);
                  });
                });

              

              //this.saveRecord(event.value);
            }
          }
        );
      } else {
        this.toastserviceService.showWarning(
          'No Changes Made!',
          'Edit the record and save.'
        );
      }
    } else {
      this.toastserviceService.showError(
        'Invalid Submission!',
        'Please enter required field(s) to save the record.'
      );
    }
  }
  formCancel(event: FormGroup) {
    //Check Form changes happen
    if (this.dataEditMode == 'ADD' || this.dataEditMode == 'EDIT') {
      let isFormModifiedByUser =
        event.pristine == false
          ? true
          : false ||
            JSON.stringify(this.allMenuDtls) !=
              JSON.stringify(this.temp_allMenuDtls);
      if (isFormModifiedByUser) {
        this.toastserviceService.showConfirm(
          'Confirm',
          'you have unsaved chnages. Are you sure want to leave?',
          (confirmed) => {
            this.isShowAddEditForm = !confirmed;
          }
        );
      } else {
        this.isShowAddEditForm = false;
      }
    } else {
      this.isShowAddEditForm = false;
    }
  }

  //#region DOCUMNTES FUNCTIONALITIES //////
  apidocPath: string = this.constantService.ApiUrl;
  postdata_empdoc = {
    mode: 'DEFAULT_DOCS',
    form_mode: 'ADD',
    empusername: '',
    doc_data: [],
    createdby: this.userid,
    userid:this.userid,
    is_need_approval: 0,
  };
  documentsDtls: any[] = [];
  tempDocName:string="";

  addTempDocument(){
    if(this.tempDocName!=""){

      //Check Already Documnet Name Exists
      let indexdoc = this.documentsDtls.findIndex(
        (x) => x.doc_name == this.tempDocName.trim()
      );
      if(indexdoc>-1){
        this.toastserviceService.showWarning(`${this.tempDocName} - Already Exists!`,`Documnet already added. please add documnet in different name.`)
        return;
      }
      this.documentsDtls.push(
        {
          doc_user_name:'',
          doc_name:this.tempDocName,
          doc_description:'',
          doc_otherdtls:'',
          doc_path:'',
          doc_path_temp:'',
          splitfiles:[]
        }
      );

    }
  }

  getDefaultDocs() {
    this.postdata_empdoc.mode = 'DEFAULT_DOCS';
    this.datasearchService
      .GetDynEmpDocManage(this.postdata_empdoc)
      .subscribe((res) => {
        console.log(res);
        this.documentsDtls = res[0];

        this.documentsDtls.forEach((item) => {
          //Split the files for Show to user
          item.splitfiles = [];
          if (item.doc_path != '') {
            item.splitfiles = item.doc_path.split(',');
          }
        });
      });
  }

  removeEmpDocs(doc_name, filename) {
    this.toastserviceService.showConfirm(
      'Confirm',
      'Are you sure want to remove this file?',
      (confirmed) => {
        if (confirmed) {
          let indexdoc = this.documentsDtls.findIndex(
            (x) => x.doc_name == doc_name
          );
          if (indexdoc > -1) {
            let currentfiles = this.documentsDtls[indexdoc].splitfiles.slice(); //copy by value
            currentfiles.splice(currentfiles.indexOf(filename), 1);
            this.documentsDtls[indexdoc].splitfiles = currentfiles;
            this.documentsDtls[indexdoc].doc_path = currentfiles.join(',');
            //mark for as dirty
            this.markFormChanged$.next(true);
          }
        }
      }
    );
  }

  SaveEmpDocs(empusername) {
    // debugger;
    this.postdata_empdoc.userid=this.userid;
    this.postdata_empdoc.is_need_approval = 0;
    if (this.fieldData.hasOwnProperty('need_approval')) {
      this.postdata_empdoc.is_need_approval = this.fieldData.need_approval;
    }
    this.postdata_empdoc.mode =
      this.postdata_empdoc.is_need_approval == 1 ? 'APPROVE_DOC' : 'SAVE_DOC';
    this.postdata_empdoc.empusername = empusername;
    this.postdata_empdoc.doc_data = this.documentsDtls;

    console.log(this.postdata_empdoc.doc_data);
    this.datasearchService.GetDynEmpDocManage(this.postdata_empdoc).subscribe(
      (res) => {
        // debugger;
        this.UploadDocs(empusername);
        console.log(res);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  fileUploader(event, item) {
    
    let files = event.files;
    let alreadyUploadCnt = 0;
    if (item.doc_path_temp.trim() != '' && item.doc_path_temp != null) {
      alreadyUploadCnt = item.doc_path_temp.split(',').length;
    }

    //need to set files object
    item.fileobject = files;
    let filescommseprated = item.fileobject
      .map(
        (x, index) =>
          // `${item.doc_name}_${index + 1 + alreadyUploadCnt}.${
          //   x.name.split('.')[1]
          // }`
          x.name
      )
      .join(',');
    if (item.doc_path_temp != '') {
      item.doc_path = item.doc_path_temp + ',' + filescommseprated;
    } else {
      item.doc_path = filescommseprated;
    }

    //mark for as dirty
    this.markFormChanged$.next(true);
  }

  UploadDocs(empusername) {
    // check any form upload exists
    
    let IsExistsFiles = this.documentsDtls.find(
      (x) => x.hasOwnProperty('fileobject') == true
    );
    if (IsExistsFiles) {
      this.documentsDtls.forEach((item) => {
        if (item.doc_path != '' && item.doc_path != null) {
          let alreadyuploadedcnt = 0;
          if (item.doc_path_temp.trim() != '' && item.doc_path_temp != null) {
            alreadyuploadedcnt = item.doc_path_temp.split(',').length;
          }
          let formdata = new FormData();
          //If file object exists
          if (item.hasOwnProperty('fileobject') == true) {
            if (
              item.fileobject != null &&
              item.fileobject != undefined &&
              item.fileobject.length > 0
            ) {
              item.fileobject.forEach((file, i) => {
                let ext = file.name.split('.')[1].trim();
                formdata.append(
                  'myfile[]',
                  file,
                  //`${item.doc_name}_${alreadyuploadedcnt + i + 1}.${ext}`
                  file.name
                );
              });
              let newpath = './empdocs/' + empusername + '/' + item.doc_name;
              console.log(newpath);
              formdata.append('topath', newpath);
              formdata.append('empusername', empusername);
              this.datasearchService.uploadFiles(formdata).subscribe(
                (res) => {
                  console.log(res);
                  this.refreshTableSubject$.next(true); //refresh the table
                  this.toastserviceService.showSuccess(
                    'Sccess!',
                    `Record ${this.dataEditMode == 'ADD' ? 'Saved' : ''}${
                      this.dataEditMode == 'EDIT' ? 'Updated' : ''
                    }${this.dataEditMode == 'DELETE' ? 'Deleted' : ''}`
                  );
                },
                (error) => {
                  this.toastserviceService.showError(
                    'Error!',
                    'Something went wrong. Please refresh the page and try again!'
                  );
                },
                () => {
                  this.isShowAddEditForm = false;
                }
              );
            }
          }
        }
      });
    } else {
      this.refreshTableSubject$.next(true); //refresh the table
      this.toastserviceService.showSuccess(
        'Sccess!',
        `Record ${this.dataEditMode == 'ADD' ? 'Saved' : ''}${
          this.dataEditMode == 'EDIT' ? 'Updated' : ''
        }${this.dataEditMode == 'DELETE' ? 'Deleted' : ''}.Please approve changes in approval section.`
      );
      this.isShowAddEditForm = false;
    }
  }

  //#endregion ENS OF OCUMNTES FUNCTIONALITIES /////

  /////// PAGE LEVEL HELPER METHODS //////
  showForm(mode, form_data) {
    this.dataEditMode = mode;
    this.isShowAddEditForm = true;
    this.fieldDtls = form_data.form_fielddata;
    this.fieldData = form_data.row_data;
    this.postdata_empdoc.form_mode = mode;
    this.postdata_empdoc.empusername = this.fieldData.user_username;
    this.postdata_empdoc.is_need_approval = 0;

    if (this.fieldData.hasOwnProperty('need_approval')) {
      this.postdata_empdoc.is_need_approval = this.fieldData.need_approval;
    }
    this.getDefaultDocs();
  }

  saveRecord(form_data) {
    this.postdata.form_action = this.dataEditMode;
    this.postdata.form_data = form_data;
    this.datasearchService.GetDynTableData(this.postdata).subscribe(
      (res) => {
        this.getTotalPendingApproval();

        //console.log(res);
        if (this.dataEditMode == 'ADD') {
          let emailPostData = {
            to: this.postdata.form_data.user_personal_email,
            subject: 'ChemRobotics - User/Employee Registration Completed',
            html: `<p><span class="text-big" style="color:hsl(150, 75%, 60%);"><strong>Chemrobotics User/Employee Registration Completed</strong></span></p> <hr> <p>Please find the Username and Password for your Account.</p> <p><strong>Username</strong>: <i>${this.postdata.form_data.user_username}</i></p> <p><strong>Password</strong>: <i>${this.temp_password}</i></p> <p><i>Note: don't share your credentials with anyone. Keep them safe;&nbsp;</i></p> <hr> <p><i>Thanks &amp; Regards,</i></p> <p><i>ChemRobtics team</i></p>`,
          };
          this.datasearchService.sendMail(emailPostData).subscribe((res) => {
            console.log('User Registration Email Sent.');
          });
        }

        //Save the employee documents for approvals
        this.SaveEmpDocs(res[0][0].user_username);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  approveRecord(row_data) {
    this.postdata.mode = 'FORM_APPROVE';
    this.fieldData = row_data;
    this.postdata.approveid = row_data.id;
    this.datasearchService.GetDynTableData(this.postdata).subscribe(
      (res) => {
        
        //console.log(res);
        //Save the employee documents for approvals
        this.SaveEmpDocs(res[0][0].user_username);

        this.refreshTableSubject$.next(true); //refresh the table
        this.toastserviceService.showSuccess('Success!', `Record Approved.`);
        this.getTotalPendingApproval();
      },
      (error) => {
        this.toastserviceService.showError(
          'Error!',
          'Something went wrong. Please refresh the page and try again!'
        );
      },
      () => {
        this.isShowAddEditForm = false;
      }
    );
  }

  getTotalPendingApproval() {
    this.postdata.mode = 'GET_TOTAL_PENDING';
    this.datasearchService.GetDynTableData(this.postdata).subscribe((res) => {
      if (res[0].length == 1) {
        this.pendingApprovalCnt = res[0][0].count;
      } else {
        this.pendingApprovalCnt = 0;
      }

      this.postdata.mode = 'FORM_SUBMIT';
    });
  }
}
