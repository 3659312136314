import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { dyndataobject_model } from '../_model/dyndataobject_model';
import { AuthenticationService, CommonService, ConstantsService } from '../_services';
import { DatasearchService } from '../_services/datasearch.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error: string;
  isLoggedOut:boolean;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private datasearchService: DatasearchService,
    private constantService: ConstantsService,
    private commonService:CommonService
  ) {
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    this.setCurrentAppName(this.returnUrl);

    this.constantService.isLoggedOut.subscribe(x=>this.isLoggedOut=x);
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService
      .login(this.f.username.value, this.f.password.value)
      .subscribe(
        (data) => {
          if(this.isLoggedOut==false){
            // MENU DETAILS
            let postdata: dyndataobject_model = {
              mode: 'GET_MENUS',
              userid:data.userid
            };
            this.datasearchService
              .GetDynTableData(postdata)
              .subscribe((res) => {
                let menudtls = res[0];
                //console.log(menudtls);
                localStorage.setItem('menudtls', JSON.stringify(menudtls));
                this.constantService.menuDetails.next(menudtls);
              });
        }
          this.router.navigate([this.returnUrl]);
        },
        (error) => {
          this.error = error;
          this.loading = false;
        }
      );
  }

  setCurrentAppName(returnurl){
    let path = decodeURIComponent(returnurl) ;
    if (path.match(/\/vendor_registration\//g) != null || path == '/vendor_registration') {
      this.commonService.setCurrentAppStatus('app_vendor_registration');
    }  
    else if (path.match(/\/table_data\//g) != null || path == '/table_data') {
      this.commonService.setCurrentAppStatus('app_table_data');

    }
    else if (path.match(/\/table_data\//g) != null || path.indexOf('/table_data_public')>-1) {
      this.commonService.setCurrentAppStatus('app_table_data_public');
    }
    else {
      this.commonService.setCurrentAppStatus('app_adminpanel');
    }
  }
}
