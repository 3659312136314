import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DragulaService } from 'ng2-dragula';
import { MessageService } from 'primeng/api';
import { ToastserviceService } from 'src/app/_services/toastservice.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatasearchService } from 'src/app/_services/datasearch.service';
import { CommonService } from 'src/app/_services';

@Component({
  selector: 'app-database-column-manage',
  templateUrl: './database-column-manage.component.html',
  styleUrls: ['./database-column-manage.component.scss'],
})
export class DatabaseColumnManageComponent implements OnInit {
  userid: number = 0;
  current_user_role_id: number = 0;
  pageTitle: string = '';
  currentMenuDtls: any;
  // selectedTable
  tables = {   
      "AGROPHARM_XMIM": {color: false, dropdown: false, popup: false},
      "AGRO_JARVIS": {color: false, dropdown: false, popup: false},
      "combination": {color: false, dropdown: false, popup: false},
      "INDIAN_PESTICIDE_DATABASE": {color: false, dropdown: false, popup: false},
      "PHARM_JARVIS": {color: false, dropdown: false, popup: false},
      "SPC_DATABASE": {color: false, dropdown: false, popup: false},
      "GLOBAL_PATENT_WATCH": {color: false, dropdown: false, popup: false},
      "GLOBAL_PHARMACEUTICAL_PRODUCT_DIRECTORY": {color: false, dropdown: false, popup: false},
      "agropharm_xmim_mongos": {color: true, dropdown: true, popup: false},
      "QUIK_PATENT_MONGO": {color: false, dropdown: true, popup: false},
      "chemi_tracker_mongos": {color: true, dropdown: true, popup: false},
      "CHEMICAL_DIRECTORY_MONGO": {color: false, dropdown: true, popup: false},
      "impurity_mongos": {color: false, dropdown: false, popup: false},
      "TECHNICAL_ROUTE_MONGO": {color: false, dropdown: false, popup: false},
      'agropharm_imp_patents_mongos': {color: true, dropdown: true, popup: true},
      'agropharm_litigation_mongos': {color: true, dropdown: true, popup: true},
      'agropharm_nonpatent_mongos': {color: true, dropdown: true, popup: true},
      'gapd_mongos': {color: true, dropdown: true, popup: true},
      'mrl_mongos': {color: true, dropdown: true, popup: true},
      'scientific_docs_mongos': {color: true, dropdown: true, popup: true},
      'spc_mongos': {color: true, dropdown: true, popup: true}};
  templateList = [
    // "AGROPHARM_XMIM",
    // "AGRO_JARVIS",
    // "combination",
    // "INDIAN_PESTICIDE_DATABASE",
    // "PHARM_JARVIS",
    // "SPC_DATABASE",
    // "GLOBAL_PATENT_WATCH",
    // "AGROPHARM_XMIM_MONGO",
    "TEMPLATE_QUIK_PATENT_MONGO",
    "template_chemi_tracker_mongos",
    "TEMPLATE_QUIK_PATENT_MONGO_ADMIN",
    "template_chemi_tracker_mongo_admins",
    'template_impurity_mongos',
    'TEMPLATE_CHEMICAL_DIRECTORY_MONGO',
    'TEMPLATE_TECHNICAL_ROUTE_MONGO',
    'template_agropharm_imp_patents_mongos',
    'template_agropharm_litigation_mongos',
    'template_agropharm_nonpatent_mongos',
    'template_gapd_mongos',
    'template_mrl_mongos',
    'template_scientific_docs_mongos',
    'template_spc_mongos'
  ];
  dropdownManage: boolean = false;
  cols: any[] = [
      { field: 'display_order', header: 'Display Order', type: 'number' },
      { field: 'ExcelColumnName', header: 'Excel Column Name', type: 'text' },
      { field: 'DisplayColumnName', header: 'Display Column Name', type: 'text' },
      { field: 'ui_width_temp', header: 'UI Width', type: 'number' },
      { field: 'Is_display', header: 'Is Display', type: 'boolean' },
      { field: 'view_in_dropdown', header: 'Dropdown Field', type: 'boolean' },
      { field: 'show_popup', header: 'Show Popup', type: 'boolean' },
      { field: 'column_color', header: 'Column Colour', type: 'color' }
    ];
  color1: string = '#ffffff';

  // RxJS Subscription is an excellent API for managing many unsubscribe calls.
  // See note below about unsubscribing.
  subs = new Subscription();

  constructor(
    private formBuilder: FormBuilder,
    private dragulaService: DragulaService,
    private toastService: ToastserviceService,
    private datasearchService: DatasearchService,
    private commonService: CommonService
  ) {
    // some events have lots of properties, just pick the ones you need
    this.subs.add(      
      this.dragulaService
        .dropModel('columns')
        // WHOA
        // .subscribe(({ name, el, target, source, sibling, sourceModel, targetModel, item }) => {
        .subscribe(({ sourceModel, targetModel, item }) => {
          this.tblColumnsDtls = targetModel;
          this.tblColumnsDtls.forEach((item, i) => {
            item.display_order = i + 1;
          });
        })
    ); 

    this.subs.add(
      this.dragulaService
        .drag('columns')
        // WHOA
        // .subscribe(({ name, el, target, source, sibling, sourceModel, targetModel, item }) => {
        .subscribe(({ name, el, source }) => {
          document.onmousemove = (e) => {
            let event = e || window.event;
            let mouseY = event['pageY'];
            let scrollTop =
              document.getElementById('columnform__box').scrollTop ||
              document.body.scrollTop;
            let scrollBottom =
                scrollTop +
                document.getElementById('columnform__box').clientHeight;
            
            let elementHeight = el.clientHeight;

            // and check if the event is on top or bottom of the page
            if (mouseY - elementHeight / 2 < scrollTop) {
              this.startScrollingUp();
            } else if (mouseY + elementHeight / 2 > scrollBottom) {
              this.startScrollingDown();
            } else {
              this.stopScrolling();
            }
          };
        })
    );

    this.subs.add(
      this.dragulaService
        .dragend('columns')
        // WHOA
        // .subscribe(({ name, el, target, source, sibling, sourceModel, targetModel, item }) => {
        .subscribe((value) => {
          document.onmousemove = null;
          this.stopScrolling();
        })
    );
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.currentMenuDtls = JSON.parse(
        sessionStorage.getItem('currentMenuDtls')
      );
      this.pageTitle = this.currentMenuDtls.menu_title;
    }, 1000);

    this.userid = JSON.parse(localStorage.getItem('currentUser')).userid;
    this.current_user_role_id = JSON.parse(
      localStorage.getItem('currentUser')
    ).role_id;

    //Get the column detail when initialize
    this.getTables();
  }

  tblList: any[] = [];
  selectedTable: any;
  filteredTables: any[];
  tblColumnsDtls: any[] = [];
  tblColumnsDtls_Temp: any[] = [];
  scrollInterval: any;
  IsDisplayNewExists: boolean = true;

  Default_Grid_RowsCount:number;
  Default_DownloadRec_Limit:number;
  Default_Grid_RowsCount_Temp:number;
  Default_DownloadRec_Limit_Temp:number;



  //Get Table Detail
  getTables() {
    let tempList = [
      "AGROPHARM_XMIM",
      "AGRO_JARVIS",
      "combination",
      "INDIAN_PESTICIDE_DATABASE",
      // "INDIAN_PESTICIDE_DATABASE_MONGO",
      "PHARM_JARVIS",
      "SPC_DATABASE",
      "GLOBAL_PATENT_WATCH",
      "GLOBAL_PHARMACEUTICAL_PRODUCT_DIRECTORY",
      "agropharm_xmim_mongos",
      "QUIK_PATENT_MONGO",
      "chemi_tracker_mongos",
      "CHEMICAL_DIRECTORY_MONGO",
      "impurity_mongos",
      "TECHNICAL_ROUTE_MONGO",
      'agropharm_imp_patents_mongos',
      'agropharm_litigation_mongos',
      'agropharm_nonpatent_mongos',
      'gapd_mongos',
      'mrl_mongos',
      'scientific_docs_mongos',
      'spc_mongos'
    ];
    this.datasearchService.Get_Template_Tables().subscribe(
      (res) => {
        this.tblList =[];
        let data: any = res;
        data.forEach(x=>{
          if(tempList.includes(x.TableName)){
            if(x.TableName=="AGROPHARM_XMIM"){
             x['TableDisplayName']="AGROPHARM_XMIM (1.0 & 2.0)";
            }
            else{
              x['TableDisplayName']=x.TableName;
            }
            this.tblList.push(x);
          }
        });

        //Get MongoDB Server Tables
        this.datasearchService.Get_Template_Tables_mongo().subscribe(
          (res)=>{
            let data: any = res;
            data.forEach(x=>{
              if(tempList.includes(x.TableName)){
                if(x.TableName=="agropharm_xmim_mongos"){
                x['TableDisplayName']="AGROPHARM_XMIM (1.0 & 2.0) (MongoDB)";
                }
                else{
                  if(x.AdminTemplateName){
                    let tableDetail = JSON.parse(JSON.stringify(x));
                    tableDetail.TemplateName = tableDetail.AdminTemplateName;
                    tableDetail['TableDisplayName']=`${tableDetail.TableName}_ADMIN (MongoDB)`;
                    tableDetail['TableName']=`${tableDetail.TableName}_ADMIN`;
                    delete tableDetail.AdminTemplateName;
                    delete x.AdminTemplateName;
                    this.tblList.push(tableDetail);
                  }
                  x['TableDisplayName']=`${x.TableName} (MongoDB)`;
                }                
                this.tblList.push(x);
              }              
            });
          }
        );
      },
      (error) => {
        console.log(error);
      },
      () => {}
    );    
  }

  filterTable(event) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < this.tblList.length; i++) {
      let table = this.tblList[i];
      if (table.TableName.toLowerCase().includes(query.toLowerCase())) {
        filtered.push(table);
      }
    }
    this.filteredTables = filtered;
  }

  onSelectTable(event) {
    // debugger;
    this.dropdownManage = false;
    this.tblColumnsDtls = null;
    this.IsDisplayNewExists = false;
    this.Default_DownloadRec_Limit=this.selectedTable.Default_DownloadRec_Limit;
    this.Default_Grid_RowsCount=this.selectedTable.Default_Grid_RowsCount;
    this.Default_DownloadRec_Limit_Temp=this.selectedTable.Default_DownloadRec_Limit;
    this.Default_Grid_RowsCount_Temp=this.selectedTable.Default_Grid_RowsCount;
    this.getColumnDetails();
    for(let i=0; i<this.templateList.length; i++){
      if(this.selectedTable && this.templateList[i] === this.selectedTable.TemplateName){
        this.dropdownManage = true;        
        break;
      }
    }
    console.log(this.selectedTable);
  }

  //Get the column detail
  getColumnDetails() {
    let postdata = {
      mode: 'GET_COLUMNS',
      table_name: this.selectedTable.TableName,
      template_name: this.selectedTable.TemplateName
    };
    let isMongoDbTable=this.selectedTable.TableDisplayName.includes("(MongoDB)");
    let manageTemplateColumn = isMongoDbTable==true ? this.datasearchService.manageTemplateColumn_mongo(postdata) : this.datasearchService.manageTemplateColumn(postdata);
    manageTemplateColumn.subscribe(
      (res:any[]) => {        
        let data = res.length==2?res[0]:res;
        data.forEach((x) => {
          if (!x.hasOwnProperty('Is_display_new')) {
            this.IsDisplayNewExists = false;
            x['Is_display_new'] = x.Is_display;
          } else {
            this.IsDisplayNewExists = true;
          }

          x.ui_width_temp=x.ui_width.replace("px","").trim();
        });
        this.tblColumnsDtls = data;
        this.tblColumnsDtls_Temp = JSON.parse(JSON.stringify(data));
      },
      (error) => {
        console.log(error);
      },
      () => {}
    );
  }

  onBlurUiWidth(tblcol){
    tblcol.ui_width=tblcol.ui_width_temp.toString()+"px";
  }

  changeBooltoint(tblcol) {
    if (this.IsDisplayNewExists) {
      tblcol.Is_display_new = tblcol.Is_display_new == true ? 1 : 0;
    } else {
      tblcol.Is_display = tblcol.Is_display_new == true ? 1 : 0;
    }
  }

  dropdownField(tblcol){
    console.log(tblcol);
  }

  resetColumdetail() {
    this.tblColumnsDtls = JSON.parse(JSON.stringify(this.tblColumnsDtls_Temp));
  }

  //Update Table Details
  updateTableDtls(){
    // debugger;
    let postdata={
      tablename:this.selectedTable.TableName,
      Default_Grid_RowsCount: this.Default_Grid_RowsCount,
      Default_DownloadRec_Limit: this.Default_DownloadRec_Limit
    };
    let isMongoDbTable=this.selectedTable.TableDisplayName.includes("(MongoDB)");

    let Update_TableDtls= isMongoDbTable==true ? this.datasearchService.Update_TableDtls_mongo(postdata) : this.datasearchService.Update_TableDtls(postdata);
    Update_TableDtls.subscribe(
      (res) => {
        // debugger;
        this.getTables();
      },
      (error) => {
        console.log(error);
      },
      () => {}
    );
    
  }

  //submit the updated details
  updateColumnDetail() {
    this.tblColumnsDtls.forEach((item, i) => {
      item.display_order = i + 1;
      item.Is_display = item.Is_display===true || item.Is_display===1?1:0;
      item.Is_display_new = item.Is_display;
      item.ui_width = item.ui_width_temp+'px';
      if(this.selectedTable.Tabledetails==='agropharm_xmim_mongos'){
        // item.column_color = this.color1;
        console.log(item.column_color);
      }
    });
    
    let IsModificationHappen =
      ((JSON.stringify(this.tblColumnsDtls) == JSON.stringify(this.tblColumnsDtls_Temp)) == true && 
       (this.Default_DownloadRec_Limit==this.Default_DownloadRec_Limit_Temp) == true &&
       (this.Default_Grid_RowsCount==this.Default_Grid_RowsCount_Temp) ==true
      )
        ? false
        : true;

    if (IsModificationHappen == true) {

      //Update Table Details
      this.updateTableDtls();

      //for slash escape
      let regex_formesc = /\\/gm;
      let formescapestring = JSON.stringify(this.tblColumnsDtls);
      formescapestring = formescapestring.replace(regex_formesc, '\\\\');

      //for single quote esacpe
      regex_formesc = /'/gm;
      formescapestring = formescapestring.replace(regex_formesc, "''");

      let uploaddata = JSON.parse(formescapestring);
      uploaddata.forEach((row)=>{
        row.ExcelColumnName = row.DisplayColumnName;
      })

      let postdata = {
        mode: 'UPDATE_COLUMNS_DETAILS',
        table_name: this.selectedTable.TableName,
        table_data: uploaddata,
        template_name: this.selectedTable.TemplateName
      }; 

      let isMongoDbTable=this.selectedTable.TableDisplayName.includes("(MongoDB)");

      let manageTemplateColumn= isMongoDbTable==true ? this.datasearchService.manageTemplateColumn_mongo(postdata) : this.datasearchService.manageTemplateColumn(postdata);

      manageTemplateColumn.subscribe(
        (res) => {
          this.getColumnDetails();
          // debugger;
          this.toastService.showSuccess(
            'Success',
            `Column Changes Saved Successfully.`
          );
        },
        (error) => {
          console.log(error);
        },
        () => {}
      );
    } else {
      this.toastService.showWarning(
        'No Changes Made!',
        `Please edit the data to continue.`
      );
    }
  }

  private startScrollingUp() {
    if (this.scrollInterval) return; // don't set it multiple times
    this.scrollInterval = window.setInterval(
      () => document.getElementById('columnform__box').scrollBy({ top: -10 }),
      100
    );
    // if(this.dropdownManage){
    //   this.scrollInterval = window.setInterval(
    //     () => document.getElementById('columnform__box-new').scrollBy({ top: -10 }),
    //     100
    //   );
    // }else{
    //   this.scrollInterval = window.setInterval(
    //     () => document.getElementById('columnform__box').scrollBy({ top: -10 }),
    //     100
    //   );
    // }    
  }

  private startScrollingDown() {
    if (this.scrollInterval) return;
    this.scrollInterval = window.setInterval(
      () => document.getElementById('columnform__box').scrollBy({ top: 10 }),
      100
    );
    // if(this.dropdownManage){
    //   this.scrollInterval = window.setInterval(
    //     () => document.getElementById('columnform__box-new').scrollBy({ top: 10 }),
    //     100
    //   );
    // }else{
    //   this.scrollInterval = window.setInterval(
    //     () => document.getElementById('columnform__box').scrollBy({ top: 10 }),
    //     100
    //   );
    // }    
  }

  private stopScrolling() {
    window.clearInterval(this.scrollInterval);
    this.scrollInterval = null;
  }
}
