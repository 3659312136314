import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { DatasearchService } from 'src/app/_services/datasearch.service';
import {IAngularMyDpOptions, IMyDateModel} from 'angular-mydatepicker';
import * as moment from 'moment';
import * as fs from 'file-saver';
import { ConstantsService } from 'src/app/_services/constants.service';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
})
export class DynamicFormComponent implements OnInit {
  myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd-mmm-yyyy',
    showFooterToday:true,
    todayTxt: 'Today',
    firstDayOfWeek: 'mo',
    sunHighlight: true,
    inline: false,
    
  };
  
  @Input() menu_uniq_name: string;
  @Input() field_details: any[];
  @Input() field_data: any;
  @Input() formEditMode: string; //ADD,EDIT,DELETE,VIEW
  @Output() onFormSubmitEvent = new EventEmitter<any>();
  @Output() onFormCancelEvent = new EventEmitter<any>();
  @Output() onApprove = new EventEmitter<any>();
  @Output() onDeleteApprove = new EventEmitter<any>();
  
  @Input() markFormChanged: Subject<boolean>=new Subject<boolean>();
  isShowApprove:boolean=false;

  constructor(private formBuilder: FormBuilder,private datasearchService: DatasearchService,private constants:ConstantsService) {}

  ngOnInit(): void {

    //SHOW APPROVE BUTTON
    this.isShowApprove=this.field_data['need_approval']==1 ? true : false;
    // CREATE DYNAMIC FORM
    let fb_builder = {};
    this.field_details.forEach((item) => {

      //JSON VALUE PARSE
      if(item.field_json_dropdownvalues!='' && item.field_json_dropdownvalues!=null)
      {
        item.field_sql_data=JSON.parse(item.field_json_dropdownvalues);
      }
      
      if(this.formEditMode!="ADD" && item.field_ui_type=='date'){
        //this.field_data[item.field_db_col_name]
        let datevalue=moment(this.field_data[item.field_db_col_name],"DD-MMM-YYYY");
        let model: IMyDateModel = {isRange: false, singleDate: {date: { 
          year: datevalue.year(), 
          month: datevalue.month()+1, 
          day: datevalue.date() 
        }}, dateRange: null};
        this.field_data[item.field_db_col_name]=model;
      }
      
      // If image field add locally image edit modes
      if(item.field_ui_type=='fileupload'){
        item.image_edit_mode=this.formEditMode;
        item.temp_old_imagename="";
      }

      let temp_validator=[];
      if(item.field_ui_mandatory == true){
        temp_validator.push(Validators.required);
      }
      if(item.field_ui_regex !='' && item.field_ui_regex !=null){
        temp_validator.push(Validators.pattern(`${item.field_ui_regex}`));
      }
      if(item.field_db_size!='' && item.field_db_size !=null){
        temp_validator.push(Validators.maxLength(item.field_db_size));
      }

      fb_builder[item.field_db_col_name] = [
        //Assign Actual/Default value
        this.formEditMode=="ADD" ? (item.field_ui_defalutvalue=='' ? null : item.field_ui_defalutvalue) : this.field_data[item.field_db_col_name],
        //Assigning Validation
        Validators.compose(temp_validator),
      ];
    });    
    this.dynForm = this.formBuilder.group(fb_builder);

    //Disable the form in View mode
    if(this.formEditMode=="VIEW"){
      this.dynForm.disable();
    }

    this.markFormChanged.subscribe(response=>{
      if(response){
        this.dynForm.markAsDirty();
      }
    })

    
  }

  //#region  FORM
  dynForm: FormGroup;
  ondynFormSubmit() {
    this.dynForm.markAllAsTouched();
    this.field_details.forEach((item) => {
      
      let val=this.dynForm.controls[item.field_db_col_name].value;

      // Date object value to string DD-MMM-YYYY
      if(item.field_ui_type=='date'){
        //if not in this format DD-MMM-YYYY
        if(typeof val == "object"){
          let curredatevalue=val.singleDate.date;
          let newdate=new Date(curredatevalue.year,curredatevalue.month-1,curredatevalue.day);
          this.dynForm.controls[item.field_db_col_name].setValue(moment(newdate).format("DD-MMM-YYYY"));
        }
      }
      //For File Upload
      else if(item.field_ui_type=='fileupload' && item.image_edit_mode=='ADD'){
        // debugger;
        if(item.hasOwnProperty('fileobject')){
          let fileobject=item.fileobject;
          if(fileobject){
            let filename=fileobject.name;
            //Upload file to server
                let formdata=new FormData();
                formdata.append("myfile[]",fileobject,filename);
                formdata.append("topath",item.field_frm_fileuploadpath);
                this.datasearchService.uploadFile(formdata).subscribe(res=>{
                  // debugger;
                  console.log(res);
                });
          }
          
        }
        
      }

    });
    // Here return the form as output
    this.onFormSubmitEvent.emit(this.dynForm);
  }
  //On Approve Record
  onApproveEvent(row_data) {
    this.onApprove.emit(row_data);
  }
  //On Delete Approve Record
  onDeleteApproveEvent(row_data) {
    this.onDeleteApprove.emit(row_data);
  }
  ondynCancel() {
    this.onFormCancelEvent.emit(this.dynForm);
  }
  ondynFileUploadCancel(item) {
    // debugger;
    item.image_edit_mode='EDIT';
    this.dynForm.controls[item.field_db_col_name].setValue(item.temp_old_imagename);
    this.dynForm.controls[item.field_db_col_name].markAsPristine();
  }
  fileUploader(event,fielddtls){
    // debugger;
    console.log(event);
    let fieldname=fielddtls.field_db_col_name;
    let fileobject:File=event.files[0];
    let filename=fileobject.name;

    if(this.formEditMode=='EDIT'){
      fielddtls.temp_old_imagename=this.dynForm.controls[fieldname].value;
    }

    //Set to Form Field value
    this.dynForm.controls[fieldname].setValue(filename);
    this.dynForm.controls[fieldname].markAsDirty();
    
    //Set set file properties to get while save the form
    let index_field= this.field_details.findIndex(x=>x.field_db_col_name==fieldname);
    this.field_details[index_field].fileobject=fileobject;

    
  }

  //#endregion

  //#endregion TABLE

  //#endregion


  getJson(jsonstring):any{
    return JSON.parse(jsonstring);
  }


}
