<!-- LOGIN -->
<ng-container>

  <section class="section-login">
    <div class="bg-video">
      <video class="bg-video__content" autoplay muted loop>
        <source src="../assets/img/logincover.mp4">
        Your browser not supported!
      </video>
    </div>

    <!-- //About -->
    <!-- // Signin panel -->
    <main class="section-login__main">
      <div class="section-login__about">
        <h1 class="section-login__about-heading">about chemrobotics</h1>
        <p class="section-login__about-details">
          ChemRobotics (AgroPat® / PharmVetPat®) provides information on new or existing active ingredient of Agro,
          Pharma and other allied sectors including Ayurvedic and Homeopathic area. It also comprises product
          information including formulation, combination, developer, innovator, existing intellectual property,
          regulatory requirement, biology data including spectrum, Mechanism of Action (MoA), direction of Use (DFU),
          toxicity profile and safety, which play major role in decision or strategy making for research at academic,
          industry, individual level. Provides more than 350 technical data fields from Chemistry, Biology, Formulation,
          Pharmacology, Regulatory, IP Scenario and in the last not least Safety and Hazards.
        </p>
      </div>
      <div class="section-login__signin">
        <div class="section-login__signin-logobox">
          <img src="assets/img/logo.png" alt="logo" class="section-login__signin-logo">
          <h3 class="heading-primary">Idea2Reality Admin Panel</h3>
        </div>
        <form class="form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <h2 class="heading-secondary u-margin-bottom-small">
            Signin
          </h2>
          <div class="form__group">
            <input type="text" class="form__input" autocomplete="on" placeholder="User name" id="username" required
              formControlName="username">
            <label for="username" class="form__label">User name</label>
          </div>
          <div class="form__group">
            <input type="password" class="form__input" autocomplete="off" placeholder="Password" id="password" required
              formControlName="password">
            <label for="password" class="form__label">Password</label>
          </div>
          <div class="form__group">
            <button type="submit" class="btn btn--green section-login__btn u-margin-bottom-small" [disabled]="loading">
              <svg class="btn__icon" [ngClass]="{'btn__icon--loading' : loading,'' : loading==false }">
                <use *ngIf="loading==false" xlink:href="./assets/img/sprite.svg#icon-log-in"></use>
                <use *ngIf="loading" xlink:href="./assets/img/sprite.svg#icon-loader"></use>
              </svg>&nbsp;
              Login
            </button>
          </div>
        </form>

        <div *ngIf="error" class="alert alert-danger">
          <svg class="ui-icon-default">
            <use xlink:href="./assets/img/sprite.svg#icon-info"></use>
          </svg>&nbsp;
          Login failed!
        </div>
      </div>
    </main>



  </section>

</ng-container>