import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dynamic-page',
  templateUrl: './dynamic-page.component.html',
  styleUrls: ['./dynamic-page.component.scss']
})
export class DynamicPageComponent implements OnInit {

  menu_uniq_name:string="";

  constructor(private route:ActivatedRoute) { }

  ngOnInit(): void {
    
    this.route.url.subscribe(val=>{
      this.menu_uniq_name=val[1].path;
    })
    
  }

}
