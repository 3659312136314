import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { CommonModule,DatePipe } from '@angular/common';
import { PathLocationStrategy, LocationStrategy } from '@angular/common';

//Helper
import { ErrorInterceptor,JwtInterceptor ,appInitializer,LoadingInterceptor} from "./_helpers/index";


//Services
import { ConstantsService,AuthenticationService,CommonService } from './_services/index';

// primeng components
import {TableModule } from 'primeng/table';
import { InputNumberModule } from 'primeng/inputnumber';

//FONTAWSOME
import { FontAwesomeModule, FaIconLibrary  } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

//Components
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { MainComponent } from './main/main.component';

//Components
import { FileuploadComponent } from './fileupload/fileupload.component';
import { FileuploadinterfaceComponent } from './fileuploadinterface/fileuploadinterface.component';
import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import { DynamicTableComponent } from './components/dynamic-table/dynamic-table.component';
import { DynamicApprovalComponent } from './components/dynamic-approval/dynamic-approval.component';
import { DynamicPageComponent } from './components/dynamic-page/dynamic-page.component';



//Import for file upload
import { NgxFileDropModule } from 'ngx-file-drop';
import {FileUploadModule} from 'primeng/fileupload';

//Import For Datatable
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
//spinner
import { NgxSpinnerModule } from 'ngx-spinner';
import { EmployeeMasterComponent } from './pages/employee-master/employee-master.component';
// ng dropdown
import { NgSelectModule } from '@ng-select/ng-select';
// Angular My datepicker
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
//Moment
import { MomentModule } from 'ngx-moment';
import { ClientMasterComponent } from './pages/client-master/client-master.component';
import { VendorRegistrationComponent } from './pages/vendor-registration/vendor-registration.component';
import { VendorFormComponent } from './components/vendor-form/vendor-form.component';
import { FooterComponent } from './components/footer/footer.component';

//primeng
import {ConfirmationService, MessageService} from 'primeng/api';
import {ButtonModule } from 'primeng/button';
import {CarouselModule} from 'primeng/carousel';
import {DialogModule} from 'primeng/dialog';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {TooltipModule} from 'primeng/tooltip';
import { MessagesModule } from 'primeng/messages';


// Virtual Scroller
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
//For Drag and Drop
import { DragulaModule } from 'ng2-dragula';

//Pages
import { RoleMasterComponent } from './pages/role-master/role-master.component';
import { PageMaintainComponent } from './pages/page-maintain/page-maintain.component';
import { DyntableMgmtComponent } from './pages/dyntable-mgmt/dyntable-mgmt.component';
import { DyntableDataComponent } from './pages/dyntable-data/dyntable-data.component';
import { DyntableDataPublicComponent } from './pages/dyntable-data-public/dyntable-data-public.component';
import { InvoiceGenerationComponent } from './pages/invoice-generation/invoice-generation.component';
import { DatabaseColumnManageComponent } from './pages/database-column-manage/database-column-manage.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { DatabaseManageComponent } from './pages/database-manage/database-manage.component';
import { DatabaseTableComponent } from './components/database-table/database-table.component';
import { TechRouteGbrnstatsComponent } from './pages/tech-route-gbrnstats/tech-route-gbrnstats.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import {ToastModule} from 'primeng/toast';
import {ColorPickerModule} from 'primeng/colorpicker';
import { PlatformUrlsComponent } from './pages/platform-urls/platform-urls.component';
import { ManageTablesDataComponent } from './pages/manage-tables-data/manage-tables-data.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    MainComponent,
    FileuploadComponent,
    FileuploadinterfaceComponent,
    DynamicFormComponent,
    DynamicTableComponent,
    DynamicApprovalComponent,
    DynamicPageComponent,
    RoleMasterComponent,
    EmployeeMasterComponent,
    ClientMasterComponent,
    VendorRegistrationComponent,
    VendorFormComponent,
    FooterComponent,
    PageMaintainComponent,
    DyntableMgmtComponent,
    DyntableDataComponent,
    DyntableDataPublicComponent,
    InvoiceGenerationComponent,
    DatabaseColumnManageComponent,
    UserProfileComponent,
    DatabaseManageComponent,
    DatabaseTableComponent,
    TechRouteGbrnstatsComponent,
    PlatformUrlsComponent,
    ManageTablesDataComponent,   
  ],
  imports: [
    CommonModule ,
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    FontAwesomeModule,
    NgxFileDropModule,
    BrowserAnimationsModule, 
    NgxDatatableModule,
    TableModule,
    NgxSpinnerModule,
    NgSelectModule,
    FormsModule,
    AngularMyDatePickerModule,
    MomentModule,
    FileUploadModule,
    ButtonModule,
    CarouselModule,
    DialogModule,
    VirtualScrollerModule,
    DragulaModule.forRoot(),
    AutoCompleteModule,
    TooltipModule,
    InputNumberModule,
    MessagesModule,
    ConfirmDialogModule,
    ToastModule,
    ColorPickerModule
  ],
  providers: [
    DatePipe,
    ConstantsService,
    CommonService,
    AuthenticationService,
    MessageService,
    ConfirmationService,
    {provide:APP_INITIALIZER, useFactory:appInitializer,multi:true , deps:[AuthenticationService]},
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: LocationStrategy, useClass: PathLocationStrategy }
    
  ],
  bootstrap: [AppComponent] ,
  exports:[
    TableModule 
  ]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas,far,fab);
  }
 }

