import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { MainComponent } from './main/main.component';
import { FileuploadComponent } from './fileupload/fileupload.component';
import { FileuploadinterfaceComponent } from './fileuploadinterface/fileuploadinterface.component';

import { AuthGuard } from './_helpers';
import { DynamicPageComponent } from './components/dynamic-page/dynamic-page.component';
import { RoleMasterComponent } from './pages/role-master/role-master.component';
import { EmployeeMasterComponent } from './pages/employee-master/employee-master.component';
import { ClientMasterComponent } from './pages/client-master/client-master.component';
import { VendorRegistrationComponent } from './pages/vendor-registration/vendor-registration.component';
import { PageMaintainComponent } from './pages/page-maintain/page-maintain.component';
import { DyntableMgmtComponent } from './pages/dyntable-mgmt/dyntable-mgmt.component';
import { DyntableDataComponent } from './pages/dyntable-data/dyntable-data.component';
import { DyntableDataPublicComponent } from './pages/dyntable-data-public/dyntable-data-public.component';
import { InvoiceGenerationComponent } from './pages/invoice-generation/invoice-generation.component';
import { DatabaseColumnManageComponent } from './pages/database-column-manage/database-column-manage.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { DatabaseManageComponent } from './pages/database-manage/database-manage.component';
import { TechRouteGbrnstatsComponent } from './pages/tech-route-gbrnstats/tech-route-gbrnstats.component';
import { PlatformUrlsComponent } from './pages/platform-urls/platform-urls.component';
import { ManageTablesDataComponent } from './pages/manage-tables-data/manage-tables-data.component';


const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'vendor_registration', component: VendorRegistrationComponent },
  { path: 'table_data_public/:id', component: DyntableDataPublicComponent },
  //{ path: '',component: FileuploadinterfaceComponent,canActivate: [AuthGuard]},
  { path: '',component: HomeComponent,canActivate: [AuthGuard]},
  {
    path:'',
    runGuardsAndResolvers:'always',
    canActivate:[AuthGuard],
    children:[
      { path: 'login', component: LoginComponent },
      { path: 'master/file_upload', component: FileuploadinterfaceComponent},
      { path: 'master/role', component: RoleMasterComponent},
      { path: 'master/employee', component: EmployeeMasterComponent},
      { path: 'master/client', component: ClientMasterComponent},
      { path: 'master/pagemaintain', component: PageMaintainComponent},
      { path: 'master/dynamic_table', component: DyntableMgmtComponent},
      { path: 'table_data/:id', component: DyntableDataComponent },
      { path: 'master/invoice_generation', component: InvoiceGenerationComponent},
      { path: 'master/database_column_manage', component: DatabaseColumnManageComponent},
      { path: 'master/database_management', component: DatabaseManageComponent},
      { path: 'user_profile', component: UserProfileComponent},
      { path: 'master/tech-route-gbrnstats', component: TechRouteGbrnstatsComponent},
      { path: 'master/manage_tables', component: ManageTablesDataComponent},
      { path: 'master/platform_urls', component: PlatformUrlsComponent},
    ]
  },
  { path: 'main', component: MainComponent, canActivate: [AuthGuard] },
  // otherwise redirect to home
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
