import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService, ConstantsService } from '../_services';
import { CryptoService } from '../_services/crypto.service';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService,private cryptoService:CryptoService,private constantService: ConstantsService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let bodydata=request.body;
        // console.log(bodydata);
        //let encrypdatafrombody={ data:this.cryptoService.encrtyptData(bodydata)};
        let encrypdatafrombody=request.url.includes("uploadfiles")==true ? bodydata: { data:this.cryptoService.encrtyptData(bodydata)};
        //debugger;
        //request.url.includes("uploadfiles")
        let currentUser = this.authenticationService.currentUserValue;
        const isLoggedIn = currentUser && currentUser.token;
        let currentApp=this.constantService.currentAppStatus;
        if (isLoggedIn && (currentApp=='app_adminpanel' || currentApp=='app_table_data')) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser.token}`,
                    appname:"adminpanel"
                },
                body:encrypdatafrombody
            });
        }
        else if(currentApp!='app_adminpanel'){
            request = request.clone({
                setHeaders: {
                    appname:"adminpanel"
                },
                body:encrypdatafrombody
            });
        }

        //Encrypt the request body before send
       
        //request.body(this.cryptoService.encrtyptData(bodydata));

        return next.handle(request);
    }
}