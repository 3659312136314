<div class="master">
  <h1 class="master__pageheading">{{ this.pageTitle }}</h1>
  <div class="master__content">
    <!-- Dynamic Table -->
    <app-dynamic-table
      *ngIf="IsShowUpload == false"
      [TableID]="this.table_id"
      [tableSettingsSubject]="tableSettingsSubject$.asObservable()"
      TableReportTitle="Technical Route GBRN Count Statistics"
      (onAddNew)="onAddNew($event)"
      (onView)="viewForm($event)"
      (onEdit)="editForm($event)"
      (onDelete)="deleteRecord($event)"
      (onApprove)="approveRecord($event)"
      (onDeleteApprove)="deleteApproveRecord($event)"
      (onUpload)="showUpload()"
      [refreshTableSubject]="refreshTableSubject$.asObservable()"
      [approvalcount]="pendingApprovalCnt"
    ></app-dynamic-table>

    <div class="techroutegbrnstats__upload u-margin-top-small" *ngIf="IsShowUpload == true">
      <button
        class="btn btn--white"
        type="button"
        (click)="IsShowUpload = false"
      >
        <svg class="btn__icon">
          <use xlink:href="./assets/img/sprite.svg#icon-x"></use>
        </svg>
        &nbsp;Cancel
      </button>
      <div class="statusmsg statusmsg--info u-margin-top-small" *ngIf="ExtractProcess=='INPROGRESS'">
        <svg class="btn__icon">
            <use xlink:href="./assets/img/sprite.svg#icon-info"></use>
          </svg>
          <span>Technical Route GBRN Count Extraction Process Inprogress...!</span>
      </div>
      <div class="statusmsg statusmsg--success u-margin-top-small" *ngIf="ExtractProcess=='COMPLETED'">
        <svg class="btn__icon">
            <use xlink:href="./assets/img/sprite.svg#icon-check-circle"></use>
          </svg>
          <span>Technical Route GBRN Count Extraction Completed for last upload!</span>
      </div>
      <div class="statusmsg statusmsg--error u-margin-top-small" *ngIf="IsShowProcessError">
        <svg class="btn__icon">
            <use xlink:href="./assets/img/sprite.svg#icon-x-circle"></use>
          </svg>
          <span>Technical Route GBRN Count Extraction Failed!</span>
      </div>
      <app-fileupload
        class="fileuploadbox"
        [IsTruncateBeforeUpload]="true"
        [Title]="'Technical Route Indicator'"
        [Templatetable]="'TEMPLATE_TBL_MSTR_TECHNICALROUTES_COUNT'"
        [Tablename]="'TBL_MSTR_TECHNICALROUTES_COUNT'"
        [StoredProcedure]="''"
        (onUploadCompleted)="uploadCompleted()"
      ></app-fileupload>
    </div>
  </div>
</div>
