import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { LazyLoadEvent } from 'primeng/api';
import { dyndataobject_model } from 'src/app/_model/dyndataobject_model';
import { ConstantsService } from 'src/app/_services';
import { DatasearchService } from 'src/app/_services/datasearch.service';
import { ExportDataService } from 'src/app/_services/export-data.service';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-database-table',
  templateUrl: './database-table.component.html',
  styleUrls: ['./database-table.component.scss']
})
export class DatabaseTableComponent implements OnInit {
  @Input() TableID: number;
  @Input() TableReportTitle: string;
  @Input() tableSettingsSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>({
      menu_is_mstr_glbsearch: false,
      menu_is_mstr_downloadcsv: false,
      menu_is_mstr_downloadexcel: false,
      menu_is_mstr_downloadpdf: false,
      roleacc_is_view: false,
      roleacc_is_edit: false,
      roleacc_is_delete: false,
    });
  @Output() onAddNew = new EventEmitter<any>();
  @Output() onEdit = new EventEmitter<any>();
  @Output() onView = new EventEmitter<any>();
  @Output() onDelete = new EventEmitter<any>();
  @Output() onApprove = new EventEmitter<any>();
  @Output() onDeleteApprove = new EventEmitter<any>();
  @Input() refreshTableSubject: Subject<boolean> = new Subject<boolean>();
  @Input() approvalcount: number = 0;

  postdata: dyndataobject_model;
  TableSettings: any;
  showApproveView: boolean = false;

  constructor(
    private _constant: ConstantsService,
    private exportdataService: ExportDataService,
    private datasearchService: DatasearchService
  ) {}

  ngOnInit(): void {
    this.tableSettingsSubject.subscribe((x) => {
      this.TableSettings = x;
      this.TableSettings.menu_is_mstr_glbsearch =
        x.menu_is_mstr_glbsearch == 1 || x.menu_is_mstr_glbsearch == true
          ? true
          : false;
      this.TableSettings.menu_is_mstr_downloadcsv =
        x.menu_is_mstr_downloadcsv == 1 || x.menu_is_mstr_downloadcsv == true
          ? true
          : false;
      this.TableSettings.menu_is_mstr_downloadexcel =
        x.menu_is_mstr_downloadexcel == 1 ||
        x.menu_is_mstr_downloadexcel == true
          ? true
          : false;
      this.TableSettings.menu_is_mstr_downloadpdf =
        x.menu_is_mstr_downloadpdf == 1 || x.menu_is_mstr_downloadpdf == true
          ? true
          : false;
      this.TableSettings.roleacc_is_view =
        x.roleacc_is_view == 1 || x.roleacc_is_view == true ? true : false;
      this.TableSettings.roleacc_is_edit =
        x.roleacc_is_edit == 1 || x.roleacc_is_edit == true ? true : false;
      this.TableSettings.roleacc_is_delete =
        x.roleacc_is_delete == 1 || x.roleacc_is_delete == true ? true : false;
    });

    // Get Details From Database Based on table ID
    this.postdata = {
      mode: 'GET_TABLE_DYNDATA',
      userid: 1,
      table_id: this.TableID,
      filter_condition: '',
      sortby: '',
      rowscount: 15,
      pagenumber: 1,
      table_action: '',
    };
    this.getData(this.postdata);

    this.refreshTableSubject.subscribe((response) => {
      if (response) {
        this.getData(this.postdata);
      }
    });
  }

  //Table Variables
  TableName: string = '';
  rows: number = 15;
  totalRecords: number;
  loading_table: boolean;
  products_current_datasource: any[] = [];
  cols: any[];
  cols_form: any[];
  current_pagenumber: number = 1;
  IsShowFullPageData: boolean = false;
  IsNoRecordsFound: boolean = true;
  IsFilterAplied: boolean = false;
  globalfilterFields: string[] = [];
  currentFilterCondition: string = '';
  globalFilterCondition: string = '';
  currentSortBy: string = '';
  rptDataForExcel = [];
  tablefilter: any = {};
  first = 0;
  @ViewChild('dt') dataTable: Table;
  isLastPage_var: boolean = false;
  isFirstPage_var: boolean = false;

  next() {
    //this.first = this.first + this.rows + 1;
    this.setCurrentPage(this.current_pagenumber + 1);
  }
  prev() {
    //this.first = this.first + this.rows + 1;
    this.setCurrentPage(this.current_pagenumber - 1);
  }
  isLastPage() {
    this.isLastPage_var = this.products_current_datasource
      ? this.first ===
        (this.totalRecords - this.rows < 0 ? 0 : this.totalRecords - this.rows)
      : true;
  }
  isFirstPage() {
    this.isFirstPage_var = this.products_current_datasource
      ? this.first === 0
      : true;
  }

  setCurrentPage(n: number) {
    let paging = {
      first: (n - 1) * this.dataTable.rows,
      rows: this.dataTable.rows,
    };
    this.dataTable.onPageChange(paging);
  }
  paginate(event) {
    //event.first: Index of first record being displayed
    //event.rows: Number of rows to display in new page
    //event.page: Index of the new page
    //event.pageCount: Total number of pages
    let pageIndex = event.first / event.rows + 1; // Index of the new page if event.page not defined.
  }

  // LAZY LOAD EVENT TABLE
  loadLazyData(event: LazyLoadEvent) {
    this.loading_table = true;
    this.current_pagenumber = event.first / event.rows + 1;
    this.tablefilter = event.filters;
    let tblConditions = this._constant.getTablesqlFilterCondition(
      this.tablefilter
    );
    if (this.globalFilterCondition != '' || tblConditions != '') {
      let firstcondition =
        ' WHERE ' +
        this.globalFilterCondition +
        ((tblConditions != '' && this.globalFilterCondition != '') == true
          ? ' AND '
          : ' ');
      this.currentFilterCondition = firstcondition + tblConditions;
    } else {
      this.currentFilterCondition = '';
    }

    //SORT BY CONDITION
    if (event.sortField) {
      this.currentSortBy = ` ORDER BY ${event.sortField} ${
        event.sortOrder == 1 ? ' ASC' : ' DESC'
      }`;
    } else {
      this.currentSortBy = '';
    }
    setTimeout(() => {
      this.postdata.rowscount = event.rows;
      this.postdata.pagenumber = this.current_pagenumber;
      this.postdata.filter_condition = this.currentFilterCondition;
      this.postdata.sortby = this.currentSortBy;

      this.getData(this.postdata);
    }, 1000);
  }

  //On Add New Record
  onAddNewEvent() {
    let form_data = { row_data: [], form_fielddata: this.cols_form };
    this.onAddNew.emit(form_data);
  }

  //On View Record
  onViewEvent(row_data) {
    let form_data = { row_data: row_data, form_fielddata: this.cols_form };
    this.onView.emit(form_data);
  }

  //On Edit Record
  onEditEvent(row_data) {
    let form_data = { row_data: row_data, form_fielddata: this.cols_form };
    this.onEdit.emit(form_data);
  }

  //On Delete Record
  onDeleteEvent(row_data) {
    this.onDelete.emit(row_data);
  }

  //On Approve Record
  onApproveEvent(row_data) {
    this.onApprove.emit(row_data);
  }

  //On Delete Approve Record
  onDeleteApproveEvent(row_data) {
    this.onDeleteApprove.emit(row_data);
  }

  onApproveViewTblEvent(apview) {
    this.showApproveView = apview;
    this.postdata.table_action =
      this.showApproveView == true ? 'FOR APPROVAL' : '';
    this.first = 0;
    this.products_current_datasource = [];
    this.totalRecords = 0;
    this.getData(this.postdata);
  }

  onGlobalSearch(val) {
    this.globalFilterCondition = '';
    let searchvalue = val.trim();
    let gConditions = [];
    this.cols.forEach((item) => {
      let makcondition = `${item.field_db_col_name} LIKE ''%${searchvalue}%''`;
      gConditions.push(makcondition);
    });
    this.globalFilterCondition = gConditions.join(' OR ');
    let tblConditions = this._constant.getTablesqlFilterCondition(
      this.tablefilter
    );
    if (this.globalFilterCondition != '' || tblConditions != '') {
      let firstcondition =
        ' WHERE ' +
        this.globalFilterCondition +
        ((tblConditions != '' && this.globalFilterCondition != '') == true
          ? ' AND '
          : ' ');
      this.currentFilterCondition = firstcondition + tblConditions;
    } else {
      this.currentFilterCondition = '';
    }

    this.postdata.pagenumber = 1;
    this.postdata.filter_condition = this.currentFilterCondition;

    this.getData(this.postdata);
  }

  //Export Data Excel/Csv
  exportExcel(extention) {
    // if (this.currentFilterCondition == '') {
    //   return;
    // }

    this.postdata.rowscount = this.totalRecords;
    this.datasearchService.GetDynTableData(this.postdata).subscribe(
      (response) => {
        //console.log(response);

        let DownloadData = response[0];
        this.rptDataForExcel = [];
        DownloadData.forEach((row: any) => {
          this.rptDataForExcel.push(Object.values(row));
        });
        let header_temp = [];
        //Add id field as ref no
        header_temp.push('Ref.No');
        // debugger;
        this.cols.map((col) => {
          header_temp.push(col.field_ui_display_name);
        });
        let reportData = {
          title: `${this.TableReportTitle} Data Report`,
          data: this.rptDataForExcel,
          headers: header_temp,
          sheetname: 'data',
          file_extention: extention,
        };

        this.exportdataService.exportExcel(reportData);
      },
      (error) => {
        console.log(error);
      },
      () => {
        this.loading_table = false;
      }
    );
  }

  //Export Pdf
  exportPdf() {
    let filterconditions_data = this.currentFilterCondition;
    // if (filterconditions_data == '') {
    //   return;
    // }

    this.postdata.rowscount = this.totalRecords;
    //this.postdata.rowscount=10;

    this.datasearchService.GetDynTableData(this.postdata).subscribe(
      (response) => {
        //console.log(response);
        let DownloadData = response[0];
        this.rptDataForExcel = [];
        DownloadData.forEach((row: any) => {
          this.rptDataForExcel.push(Object.values(row));
        });

        let columns = this.cols.map((col) => ({
          title: col.field_ui_display_name,
          dataKey: col.field_db_col_name,
        }));
        columns.splice(0, 0, { title: 'Ref.No', dataKey: 'id' });
        let reportData = {
          title: `${this.TableReportTitle} Data Report`,
          data: this.rptDataForExcel,
          headers: columns,
          sheetname: 'data',
        };

        this.exportdataService.exportPdf(reportData);
      },
      (error) => {
        console.log(error);
      },
      () => {
        this.loading_table = false;
      }
    );
  }

  ////////// PAGE LEVEL METHODS /////
  getData(postdata: dyndataobject_model) {
    this.datasearchService.GetDynTableData(this.postdata).subscribe((res) => {
      // debugger;
      console.log(res);
      let dyndata: any = res;
      //Table Raw data
      this.products_current_datasource = dyndata[0];
      //Column Details
      this.cols = dyndata[1];
      this.cols.forEach((x) => {
        if (
          x.field_json_dropdownvalues != '' &&
          x.field_json_dropdownvalues != null
        ) {
          x.field_sql_data = JSON.parse(x.field_json_dropdownvalues);
        }
      });
      this.cols_form = dyndata[2];
      this.totalRecords = dyndata[3][0].countval;
      this.globalfilterFields = this.cols.map((item) => item.field);
      if (this.totalRecords == 0) {
        this.IsNoRecordsFound = true;
        //this.toastr.info('No record(s) found for current filter criteria!');
      } else {
        this.IsNoRecordsFound = false;
      }
      this.isFirstPage();
      this.isLastPage();
      this.loading_table = false;
    });
  }

  findNamefrmID(rowData, field_db_col_name) {
    let data = this.cols.find(
      (x) => x.field_db_col_name == field_db_col_name
    ).field_sql_data;
    let finddata = data.find((x) => x.id == rowData[field_db_col_name]);
    if (finddata) {
      return data.find((x) => x.id == rowData[field_db_col_name]).name;
    }
    return rowData[field_db_col_name];
  }
}
