<div class="vendor_form">
  <div class="vendor_form__head">
    <img src="../assets/img/logo.png" alt="ChemRobotics" />
    <h1>Vendor Registration</h1>

    <button
      (click)="ondynFormSubmit()"
      class="bn632-hover bn23"
      type="submit"
      title="Click here to submit the details"
      
      *ngIf="this.formEditMode != 'VIEW'"
    >
      <svg class="btn__icon">
        <use xlink:href="./assets/img/sprite.svg#icon-check-square"></use>
      </svg>
      &nbsp;Submit
    </button>
  </div>

  <div class="vendor_form__carousel">
    <p-carousel
        [showNavigators]="false"
        [value]="carouselImgs"
        [numVisible]="1"
        [numScroll]="1"
        [autoplayInterval]="3000"
        [circular]="true"
      >
        <ng-template let-item pTemplate="item">
          <img
            [attr.src]="'../../../assets/img/carousel_imgs/' + item.imgname"
            [alt]="item.imgname"
          />
        </ng-template>
      </p-carousel>
  </div>

  <div class="vendor_form__notes">
    <div class="vendor_form__notes__section">
      <svg>
        <use xlink:href="./assets/img/sprite.svg#icon-info"></use>
      </svg>
      <p>
        ChemRobotics invites application from reputed Manufacturers/ suppliers/
        Contractors for Vendor enlistment. Kindly fill below details.
      </p>
      <svg>
        <use xlink:href="./assets/img/sprite.svg#icon-corner-right-down"></use>
      </svg>
    </div>
    <a href="https://companydirectory.chemrobotics.com" target="_blank"> Company Directory Website <i class='bx bx-link-external'></i> </a>
  </div>

  <!-- DYNAMIC FORM -->
  <form
    class="form master__form"
    [formGroup]="dynForm"
    (ngSubmit)="ondynFormSubmit()"
  >
    <ng-container *ngFor="let item of field_details">
      <div
        class="form__group"
        *ngIf="item.field_db_col_name != 'id' && item.field_ui_is_hide != 1"
      >
        <label
          [for]="item.field_ui_display_name"
          class="form__label form__label--master"
          >{{ item.field_ui_display_name
          }}<span class="form__group__mandatory">{{
            item.field_ui_mandatory == 1 ? "&lowast;" : ""
          }}</span></label
        >
        <!-- textbox -->
        <input
          *ngIf="item.field_ui_type == 'textbox'"
          [type]="
            item.field_ui_display_name == 'Password' ? 'password' : 'text'
          "
          class="form__input--master"
          [ngClass]="{
            'form__input--master--invalid':
              (dynForm.controls[item.field_db_col_name].invalid &&
                (dynForm.controls[item.field_db_col_name].dirty ||
                  dynForm.controls[item.field_db_col_name].touched)) === true
          }"
          autocomplete="off"
          [placeholder]="'Enter ' + item.field_ui_display_name"
          [formControlName]="item.field_db_col_name"
          [id]="item.field_ui_display_name"
          [readonly]="item.field_frm_is_readonly == 1 ? true : false"
        />
        <!-- radio  -->
        <ng-container *ngIf="item.field_ui_type == 'radio'">
          <div class="form__radio-box">
            <div
              class="form__radio-group"
              *ngFor="let ritem of item.field_sql_data"
            >
              <input
                type="radio"
                [name]="item.field_db_col_name"
                [value]="ritem.value"
                [id]="ritem.value"
                [readonly]="item.field_frm_is_readonly == 1 ? true : false"
                class="form__radio-input"
                [formControlName]="item.field_db_col_name"
              />
              <label
                class="form__label-radio"
                [for]="ritem.value"
                class="form__radio-label"
              >
                <span class="form__radio-button vendor_form__radio"></span>
                {{ ritem.name }}
              </label>
            </div>
          </div>
        </ng-container>

        <!-- dropdown  -->
        <ng-container *ngIf="item.field_ui_type == 'dropdown'">
          <ng-select
            [items]="item.field_sql_data"
            bindLabel="name"
            bindValue="id"
            placeholder="Select {{ item.field_ui_display_name }}"
            [readonly]="item.field_frm_is_readonly == 1 ? true : false"
            [formControlName]="item.field_db_col_name"
          >
          </ng-select>
        </ng-container>

        <!-- date -->
        <ng-container *ngIf="item.field_ui_type == 'date'">
          <div class="form__date__group">
            <input
              class="form__date__field"
              [disabled]="this.formEditMode == 'VIEW'"
              autocomplete="off"
              placeholder="Click to select a date"
              [readonly]="item.field_frm_is_readonly == 1 ? true : false"
              angular-mydatepicker
              name="mydate"
              (click)="dp.toggleCalendar()"
              [options]="myDpOptions"
              [formControlName]="item.field_db_col_name"
              #dp="angular-mydatepicker"
            />
            <svg class="form__date__icon">
              <use xlink:href="./assets/img/sprite.svg#icon-calendar"></use>
            </svg>
          </div>
        </ng-container>

        <!-- textarea -->
        <ng-container *ngIf="item.field_ui_type == 'textarea'">
          <textarea
            type="text"
            class="form__input--master"
            [ngClass]="{
              'form__input--master--invalid':
                (dynForm.controls[item.field_db_col_name].invalid &&
                  (dynForm.controls[item.field_db_col_name].dirty ||
                    dynForm.controls[item.field_db_col_name].touched)) === true
            }"
            autocomplete="off"
            [placeholder]="'Enter ' + item.field_ui_display_name"
            [formControlName]="item.field_db_col_name"
            [id]="item.field_ui_display_name"
            [readonly]="item.field_frm_is_readonly == 1 ? true : false"
          ></textarea>
        </ng-container>

        <ng-container *ngIf="item.field_ui_type == 'fileupload'">
          <div
            class="form__fileupload"
            *ngIf="item.image_edit_mode == 'ADD' && this.formEditMode == 'EDIT'"
          >
            <a title="Click to cancel" (click)="ondynFileUploadCancel(item)">
              <svg>
                <use xlink:href="./assets/img/sprite.svg#icon-x-circle"></use>
              </svg>
            </a>
          </div>
          <p-fileUpload
            *ngIf="item.image_edit_mode == 'ADD'"
            name="myfile[]"
            [accept]="item.field_frm_filetype"
            [multiple]="item.field_frm_fileIsmultiple == 1 ? true : false"
            [maxFileSize]="item.field_frm_filesize"
            [fileLimit]="item.field_frm_filemultiplecount"
            [customUpload]="true"
            [showUploadButton]="false"
            [auto]="true"
            (uploadHandler)="fileUploader($event, item)"
          >
          </p-fileUpload>

          <!-- in Edit or view mode -->
          <div class="form__fileupload" *ngIf="item.image_edit_mode != 'ADD'">
            <img
              [attr.src]="
                item.field_frm_fileurl + this.field_data[item.field_db_col_name]
              "
              [alt]="item.field_ui_display_name"
            />
            <a
              [title]="'Click to edit ' + item.field_ui_display_name"
              *ngIf="item.image_edit_mode == 'EDIT'"
              (click)="item.image_edit_mode = 'ADD'"
            >
              <svg>
                <use xlink:href="./assets/img/sprite.svg#icon-edit"></use>
              </svg>
            </a>
          </div>
        </ng-container>
        <div
          *ngIf="
            dynForm.controls[item.field_db_col_name].invalid &&
            (dynForm.controls[item.field_db_col_name].dirty ||
              dynForm.controls[item.field_db_col_name].touched)
          "
          class="alert alert-danger"
        >
          <div *ngIf="dynForm.controls[item.field_db_col_name].errors.required">
            {{ item.field_ui_display_name }} is required.
          </div>
          <div *ngIf="dynForm.controls[item.field_db_col_name].errors.pattern">
            {{ item.field_ui_display_name }} must be a valid.
          </div>
          <div
            *ngIf="dynForm.controls[item.field_db_col_name].errors.maxlength"
          >
            {{ item.field_ui_display_name }} must be less than
            {{ item.field_db_size }} characters.
          </div>
        </div>
      </div>
    </ng-container>
  </form>
</div>
